import { Directive, HostListener, Inject } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { DOCUMENT } from '@angular/common';

/**
 * Scroll to the top after changing step in a mat-stepper.
 * @see https://github.com/angular/components/issues/8881
 */
@Directive({
  selector: '[padspinScrollToTopOfStepper]',
})
export class ScrollToTopOfStepperDirective {
  constructor(
    private readonly stepper: MatStepper,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @HostListener('animationDone')
  selectionChanged(): void {
    const stepId: string = this.stepper._getStepLabelId(
      this.stepper.selectedIndex
    );
    const stepEl: HTMLElement | null = this.document.getElementById(stepId);
    if (stepEl) {
      stepEl.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }
}
