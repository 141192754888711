<button
  class="admin-phone"
  padspin-fab-button
  [iconWidth]="24"
  [matMenuTriggerFor]="menu"
>
  <img src="/assets/web/icons/support_agent_white_24dp.svg" alt="Phone" />
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="status">Status</button>
  <button mat-menu-item disabled>Call</button>
  <button mat-menu-item disabled>Answer</button>
  <button mat-menu-item disabled>Mute</button>
  <button mat-menu-item disabled>Un-mute</button>
  <button mat-menu-item disabled>Hang up</button>
</mat-menu>

<mat-menu #status="matMenu">
  <button mat-menu-item disabled>Online</button>
  <button mat-menu-item disabled>Offline</button>
  <button mat-menu-item disabled>Busy</button>
</mat-menu>
