/** Map neighbourhood database id's to area names */
export const neighbourhoods = {
  52: 'Manhattan',
  24: 'Upper Manhattan',
  62: 'Inwood',
  117: 'Washington Heights',
  40: 'Harlem',
  34: 'East Harlem',
  115: 'South Harlem',
  77: 'Central Harlem',
  103: 'West Harlem',
  87: 'Hamilton Heights',
  107: 'Manhattanville',
  97: 'Morningside Heights',
  99: 'Manhattan Valley',
  21: 'Upper West Side',
  11: 'Lincoln Square',
  111: 'Riverside Park',
  10: 'Upper East Side',
  90: 'Upper Carnegie Hill',
  16: 'Carnegie Hill',
  18: 'Yorkville',
  86: 'Lenox Hill',
  85: 'Sutton Place',
  2: 'Midtown',
  67: 'Midtown West',
  25: 'Theater District',
  3: `Hell's Kitchen`,
  102: 'Midtown South',
  15: 'Midtown East',
  69: 'Kips Bay',
  74: 'Turtle Bay',
  80: 'Murray Hill',
  75: 'Chelsea',
  83: 'West Chelsea',
  84: 'Flatiron',
  106: 'Gramercy Park',
  1: 'Lower Manhattan',
  5: 'East Village',
  12: 'Alphabet City',
  78: 'West Village',
  79: 'Greenwich Village',
  104: 'Noho',
  36: 'Lower East Side',
  82: 'Chinatown',
  17: 'Bowery',
  105: 'Nolita',
  42: 'SoHo',
  70: 'Soho',
  88: 'Tribeca',
  81: 'Financial District',
  71: 'Battery Park City',
  68: 'Fulton/Seaport',
  6: 'Long Island City',
  7: 'Williamsburg',
  8: 'Bushwick',
  9: 'Park Slope',
  14: 'Astoria',
  19: 'Roosevelt Island',
  20: 'Brooklyn Heights',
  13: 'Downtown Brooklyn',
  22: 'Crown Heights',
  23: 'Bedford-Stuyvesant',
  27: 'Greenpoint',
  28: 'Jackson Heights',
  29: 'Bay Ridge',
  30: 'Colgate Center',
  31: 'Dumbo',
  32: 'The Waterfront',
  33: 'North Side',
  35: 'Columbia Street Waterfront District',
  37: 'Hudson Heights',
  38: 'Van Vorst Park',
  39: 'Fort Greene',
  41: 'Boerum Hill',
  43: 'Gowanus',
  44: 'East Williamsburg',
  45: 'Prospect Heights',
  46: 'South Fort Lauderdale',
  47: 'Uptown',
  48: 'Central',
  49: 'North Hollywood',
  50: 'Westfield',
  51: 'South Slope',
  53: 'Brooklyn',
  54: 'Queens',
  55: 'Lents',
  56: 'Fulton Ferry District',
  57: 'Carroll Gardens',
  58: 'Charlestown',
  59: 'Windsor Terrace',
  60: 'Forest Hills',
  61: 'Greenwood',
  63: 'Brighton',
  64: 'Dyker Heights',
  65: 'Southern Views',
  66: 'Dave Brown Bell Road',
  4: 'Kensington',
  76: 'Hunters Point',
  26: 'Clinton Hill',
  112: 'The Loop',
  89: 'Sunnyside',
  92: 'Old Town',
  93: 'Weeksville',
  94: 'Stuyvesant Heights',
  95: 'Paulus Hook',
  96: 'Pavonia/Newport',
  98: 'Fort Hamilton',
  100: 'DUMBO',
  101: 'Columbia St Waterfront District',
  108: 'Cobble Hill',
  109: 'Beekman',
  110: 'Two Bridges',
  113: 'Hoboken',
  114: 'Maryland',
  116: 'Skokie',
  118: 'Civic Center',
  119: 'East Flatbush',
  120: 'Flushing',
  121: 'Maspeth',
  122: 'West Bronx',
  123: 'Prospect Lefferts Gardens',
  124: 'Briarwood',
  125: 'Sheepshead Bay',
  126: 'Rego Park',
  127: 'Brownsville',
  128: 'Flatbush - Ditmas Park',
  129: 'Flatlands',
  130: 'Roxbury',
  131: 'Ridgewood',
} as const;
