import { Injectable } from '@angular/core';
import {
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
  Timestamp,
  query,
  collection,
  where,
} from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import {
  BehaviorSubject,
  firstValueFrom,
  from,
  Observable,
  of,
  OperatorFunction,
} from 'rxjs';
import { User } from '@angular/fire/auth';
import { doc as doc$ } from 'rxfire/firestore';
import {
  ListingsModel,
  NeighborhoodImpl,
  NeighborhoodModel,
  neighborhoodModelName,
  TenantCriteriaImpl,
  TenantCriteriaModel,
  tenantCriteriaName,
  Viewing,
  WithId,
} from '@padspin/models';
import { DbService } from '@padspin/db-ng';
import { Public } from '@padspin/models';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  updateMatchCriteriaName,
  UpdateMatchCriteriaRequestData,
  UpdateMatchCriteriaResponse,
} from '@padspin/function-types';
import { LatLngLiteral } from './search-page/lat-lng-literal';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { PlaceService } from './place.service';

export type TenantCriteriaID = WithId<Public<TenantCriteriaModel>>;

@Injectable({
  providedIn: 'root',
})
export class TenantCriteriaService {
  /** TODO hasSeenTutorial */
  criteriaChanges$: Observable<TenantCriteriaModel | null> =
    this.authService.authState$.pipe(
      switchMap((maybeUser) => {
        if (!maybeUser) {
          throw new Error('Must be logged in to find criteria');
        }
        return doc$(
          doc(getFirestore(), `${tenantCriteriaName}/${maybeUser.uid}`)
        );
      }),
      map((snap) => snap.data() as TenantCriteriaModel),
      catchError(() => of(null))
    );
  isUserCriteriaComplete$: Observable<boolean> =
    this.authService.authState$.pipe(
      filter((maybeUser: User | null) => !!maybeUser) as OperatorFunction<
        User | null,
        User
      >,
      switchMap((user: User) => this.criteriaByUid$(user)),
      map((maybeCriteria: TenantCriteriaModel | null) =>
        this.validateCriteria(maybeCriteria)
      )
    );

  /** Criteria for users before they register, it is not yet saved to the db */
  unregisteredCriteria$ = new BehaviorSubject<Partial<TenantCriteriaID>>({
    active: true,
  });

  constructor(
    private readonly authService: AuthService,
    private readonly dbService: DbService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly functions: AngularFireFunctions,
    private readonly placeService: PlaceService
  ) {}

  criteria$: Observable<TenantCriteriaModel | null> =
    this.authService.authState$.pipe(
      switchMap((maybeUser: User | null) =>
        maybeUser ? this.criteriaByUid$(maybeUser) : of(null)
      )
    );

  updateAllUserCriteria = async (
    criteria: Required<UpdateMatchCriteriaRequestData>
  ): Promise<void> => {
    const c = { ...criteria, max_rent: Number(criteria.max_rent) };
    const isValid: boolean = this.validateCriteria(c);
    if (!isValid) {
      throw new Error('Invalid criteria');
    }
    return this.updatePartialUserCriteria(c);
  };

  updatePartialUserCriteria = async (
    criteria: UpdateMatchCriteriaRequestData
  ): Promise<void> => {
    const callable$ = this.functions.httpsCallable<
      UpdateMatchCriteriaRequestData,
      UpdateMatchCriteriaResponse
    >(updateMatchCriteriaName);
    await firstValueFrom(callable$(criteria));
  };

  criteriaByUid$ = (
    user: Pick<User, 'uid'>
  ): Observable<TenantCriteriaID | null> =>
    from(getDoc(this.dbService.db.tenantCriteria(user.uid))).pipe(
      map((cs) =>
        cs.exists()
          ? { id: cs.id, ...(cs.data() as TenantCriteriaModel) }
          : null
      )
    );

  criteriaByUidChanges$ = (uid: string): Observable<TenantCriteriaID | null> =>
    doc$(doc(getFirestore(), tenantCriteriaName, uid)).pipe(
      map((snap) =>
        !snap.exists()
          ? null
          : { ...(snap.data() as TenantCriteriaModel), id: snap.id }
      )
    );

  averageLocationFromCriteria(
    criteria: TenantCriteriaModel
  ): null | LatLngLiteral {
    if (!criteria.locations) {
      return null;
    }
    const bounds: google.maps.LatLngBoundsLiteral[] = criteria.locations.map(
      (loc) => ({
        north: loc.bounds_ne_lat,
        south: loc.bounds_sw_lat,
        east: loc.bounds_ne_lng,
        west: loc.bounds_sw_lng,
      })
    );
    return this.averageCoordFromBounds(bounds);
  }

  averageCoordFromBounds(
    bounds: google.maps.LatLngBoundsLiteral[]
  ): LatLngLiteral | null {
    if (bounds.length === 0) {
      return null;
    }
    const sumLat = bounds
      .map((bound) => bound.north + bound.south)
      .reduce((prev, cur) => prev + cur);
    const sumLng = bounds
      .map((bound) => bound.east + bound.west)
      .reduce((prev, cur) => prev + cur);

    const lat = sumLat / bounds.length / 2;
    const lng = sumLng / bounds.length / 2;
    return { lat, lng };
  }

  private validateCriteria = (
    maybeCriteria: Omit<TenantCriteriaModel, 'locations'> | null
  ): boolean => {
    if (!maybeCriteria) {
      return false;
    }
    // Only check if properties exist
    // TODO: Refactor this, it is a super ugly way to check
    if (typeof maybeCriteria.credit_score !== 'number') {
      console.warn(
        `credit score type should be number, got ${typeof maybeCriteria.credit_score} instead, value: ${
          maybeCriteria.credit_score
        }.`
      );
      return false;
    }
    if (typeof maybeCriteria.annual_income !== 'number') {
      console.warn('annual_income type');
      return false;
    }
    if (!maybeCriteria.earliest_move_in_date) {
      console.warn('earliest_move_in_date missing');
      return false;
    }
    if (typeof maybeCriteria.guarantor !== 'boolean') {
      console.warn('guarantor type');
      return false;
    }
    if (typeof maybeCriteria.funds_available !== 'number') {
      console.warn('funds_available type');
      return false;
    }
    if (!Array.isArray(maybeCriteria.bedrooms)) {
      console.warn('bedrooms type');
      return false;
    }
    if (maybeCriteria.bedrooms.length < 0) {
      console.warn('not enough bedrooms selected');
      return false;
    }
    if (typeof maybeCriteria.max_rent !== 'number') {
      console.warn('max_rent type');
      return false;
    }
    return true;
  };

  activateCriteria = async (uid: string): Promise<void> => {
    const activate: Pick<TenantCriteriaModel, 'active'> = { active: true };
    await updateDoc(doc(getFirestore(), tenantCriteriaName, uid), activate);
  };

  deactivateCriteria = async (uid: string): Promise<void> => {
    const deactivate: Pick<TenantCriteriaModel, 'active'> = { active: false };
    await updateDoc(doc(getFirestore(), tenantCriteriaName, uid), deactivate);
  };

  findNeighborhoodByName = async (
    neighborhoodName: string,
    cityName?: string
  ): Promise<NeighborhoodImpl | null> => {
    // Search neighborhoods collection for the neighborhood
    const snaps = await getDocs(
      query(
        collection(getFirestore(), neighborhoodModelName),
        where('main_text', '==', neighborhoodName)
      )
    );
    if (snaps.docs.length > 0) {
      // There exists a cached version of the neighborhood in the database that we can use
      const model = snaps.docs[0].data() as NeighborhoodModel;
      return new NeighborhoodImpl(model);
    }
    // Find the neighborhood by name
    const q: string = cityName
      ? `${neighborhoodName} ${cityName}`
      : neighborhoodName;
    const neighborhoods = await firstValueFrom(
      this.placeService.searchAutoCompleteNeighborhoods(q)
    );
    if (neighborhoods.length > 0) {
      return neighborhoods[0];
    }
    // No neighborhood exists...
    return null;
  };

  createCriteriaIfNecessary = async (
    viewing: Viewing,
    listing: Pick<
      ListingsModel,
      | 'bedrooms'
      | 'rent_amount'
      | 'neighborhood'
      | 'city'
      | 'latitude'
      | 'longitude'
    >,
    db = getFirestore()
  ): Promise<TenantCriteriaModel> => {
    const criteriaSnap = await getDoc(
      doc(db, `${tenantCriteriaName}/${viewing.user_id}`)
    );
    if (criteriaSnap.exists()) {
      return criteriaSnap.data() as TenantCriteriaModel;
    }
    // Create the criteria from the viewing and listing
    const criteria = await this.convertViewingToCriteria(viewing, listing);

    // Save the criteria to the database
    await setDoc(criteriaSnap.ref, criteria);

    return criteria;
  };

  convertViewingToCriteria = async (
    viewing: Viewing,
    listing: Pick<
      ListingsModel,
      | 'bedrooms'
      | 'rent_amount'
      | 'neighborhood'
      | 'city'
      | 'latitude'
      | 'longitude'
    >
  ): Promise<TenantCriteriaModel> => {
    const q: string = listing.neighborhood
      ? `${listing.neighborhood} ${listing.city}`
      : listing.city;
    const locations: NeighborhoodImpl[] = await firstValueFrom(
      this.placeService.searchAutoCompleteNeighborhoods(q, {
        lat: listing.latitude,
        lng: listing.longitude,
      })
    );
    const criteria: TenantCriteriaImpl = new TenantCriteriaImpl({
      active: true,
      bedrooms: [listing.bedrooms],
      max_rent: listing.rent_amount,
      annual_income: viewing.annual_income,
      credit_score: viewing.credit_score,
      funds_available: viewing.funds_available,
      guarantor: coerceBooleanProperty(viewing.guarantor),
      earliest_move_in_date: Timestamp.fromDate(
        new Date(
          Date.parse(viewing.earliest_move_in_date || new Date().toString())
        )
      ),
      locations,
    });
    return criteria.toJSON();
  };
}
