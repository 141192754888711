<div [ngTemplateOutlet]="selected ? selected.content : null"></div>
<nav>
  <button
    padspin-fab-button
    *ngFor="let step of steps; let i = index"
    (click)="selectByIndex(i)"
  >
    <img
      [src]="
        type === 'tenant'
          ? '/assets/web/icons/ellipse-green-filled.svg'
          : '/assets/web/icons/ellipse-blue-filled.svg'
      "
      [class.active]="selectedIndex === i"
      alt="selected"
    />
  </button>
</nav>
