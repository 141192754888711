import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadToStorageButtonComponent } from './upload-to-storage-button.component';
import { ButtonModule } from '../button/button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';

@NgModule({
  declarations: [UploadToStorageButtonComponent],
  imports: [CommonModule, ButtonModule, ReactiveFormsModule, FileUploadModule],
  exports: [UploadToStorageButtonComponent],
})
export class UploadToStorageButtonModule {}
