<p-fileUpload
  #file
  class="file"
  mode="basic"
  accept="image/png,image/jpg,image/jpeg,application/pdf"
  [customUpload]="true"
  (uploadHandler)="upload($event.files)"
  [auto]="true"
  [chooseLabel]="label"
  [uploadLabel]="label"
  [cancelLabel]="label"
  [chooseIcon]="
    (isLoading$ | async)
      ? 'pi pi-spin pi-spinner'
      : val.length > 0
      ? 'pi pi-check'
      : 'pi pi-plus'
  "
  [disabled]="isDisabled"
  [multiple]="multiple"
></p-fileUpload>
<p-button
  *ngIf="val.length > 1"
  icon="pi pi-times"
  (onClick)="reset()"
  label="Reset {{ val.length }}"
  class="p-button-warning"
></p-button>
<canvas #canvas></canvas>
<img #img alt="" />
