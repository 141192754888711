<form [formGroup]="loginForm">
  <label>
    <span>Email</span>
    <input
      type="email"
      formControlName="email"
      tabindex="1"
      padspinAutofocus=""
    />
  </label>

  <label>
    <span>Password</span>
    <input type="password" formControlName="password" tabindex="2" />
  </label>

  <p class="forgot-password">
    <a padspin-tertiary-button [color]="accountType" routerLink="/forgot"
      >Forgot Password?</a
    >
  </p>

  <span class="validation" *ngIf="error">{{ error }}</span>

  <button
    padspin-button
    [attr.color]="accountType"
    [color]="accountType"
    class="login"
    (click)="login()"
    [disabled]="loginForm.invalid"
  >
    Log in
  </button>
</form>
