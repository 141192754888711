import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendApplicationModalComponent } from './send-application-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { CalendarModule } from 'primeng/calendar';
import { MatDialogModule } from '@angular/material/dialog';
import { AngularFireModule } from '@angular/fire/compat';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InputTextModule } from 'primeng/inputtext';
import { EditorModule } from 'primeng/editor';

@NgModule({
  declarations: [SendApplicationModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ListboxModule,
    CalendarModule,
    MatDialogModule,
    AngularFireModule,
    MatSnackBarModule,
    InputTextModule,
    EditorModule,
  ],
  exports: [SendApplicationModalComponent],
})
export class SendApplicationModalModule {}
