import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { WithId } from './types/with-id';

export const leadsModelName = 'leads';

export type LeadsModelID = WithId<LeadsModel>;

/**
 * Leads are people who may or may not have an account. The basic building
 * block of a CRM.
 */
export type LeadsModel = {
  /** Person / Company's name */
  name?: string;
  email?: string;
  phone?: string;
  /**
   * Listing id or slug that they are primarily affiliated with
   * @todo: Rename to listing_id_or_slug
   */
  listing_id?: string;
  /**
   * Link to another website
   * @example "https://www.renthop.com/listings/barclay-ave/505/61752219"
   */
  acquisition?: string;
  notes?: string;
  automation_started_at?: Timestamp | FieldValue;
  created_at: Timestamp | FieldValue;
  /** If the lead converts into a booking, link the booking id. */
  booking_id?: string;
  /** If the lead converts into a booking, link the user ID */
  uid?: string;
};
