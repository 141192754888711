/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'padspin-range-slider',
  template: `<input
    type="range"
    [min]="min"
    [max]="max"
    [step]="step"
    [disabled]="isDisabled"
    [value]="internalValue"
    (change)="handleOutgoingValue($any($event.target).value)"
    (input)="handleOutgoingValue($any($event.target).value)"
  />`,
  styleUrls: ['./range-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeSliderComponent),
      multi: true,
    },
  ],
})
export class RangeSliderComponent implements ControlValueAccessor {
  @Input() min = 0;
  @Input() max = 100;
  @Input() step = 1;

  internalValue!: number;

  /** You can bind to this in your template as needed. */
  isDisabled = false;

  /** Call this to emit a new value when it changes. */
  emitOutgoingValue: (value: number) => void = () => void 0;

  /** Call this to "commit" a change, traditionally done e.g. on blur. */
  onTouched: VoidFunction = () => {};

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  /** Handle a new value coming from the component */
  handleOutgoingValue(value: number): void {
    this.emitOutgoingValue(value);
    this.onTouched();
  }

  /** Handle a new value coming in from outside. */
  handleIncomingValue(value: number): void {
    this.internalValue = value;
  }

  /** Called as angular propagates value changes to this `ControlValueAccessor`. You normally do not need to use it. */
  writeValue(value: number): void {
    this.handleIncomingValue(value);
    this.changeDetectorRef.markForCheck();
  }

  /** Called as angular sets up the binding to this `ControlValueAccessor`. You normally do not need to use it. */
  registerOnChange(fn: (value: number) => void): void {
    this.emitOutgoingValue = fn;
  }

  /** Called as angular sets up the binding to this `ControlValueAccessor`. You normally do not need to use it. */
  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  /** Called as angular propagates disabled changes to this `ControlValueAccessor`. You normally do not need to use it. */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
}
