import { Component, OnInit } from '@angular/core';
import { asyncScheduler, observeOn } from 'rxjs';
import { FooterService } from '../footer.service';
import { LoadingService } from '../loading.service';
import { VersionService } from '../version.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'padspin-frontend',
  templateUrl: './frontend.component.html',
  styleUrls: ['./frontend.component.scss'],
})
export class FrontendComponent implements OnInit {
  isFooterVisible$ = this.footerService.visible$.pipe(
    observeOn(asyncScheduler)
  );
  isLoading$ = this.loadingService.isLoading$;

  constructor(
    private readonly footerService: FooterService,
    private readonly loadingService: LoadingService,
    private readonly versionService: VersionService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly analytics: GoogleAnalyticsService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const navigationEvent = event as NavigationEnd;
        // This is an experiment validating how it impacts the audiences in Google Ads
        this.analytics.gtag('event', 'page_view', {
          page_path: navigationEvent.urlAfterRedirects,
        });
        this.analytics.gtagConfig('AW-10987733817', {
          page_path: navigationEvent.urlAfterRedirects,
        });
      });
  }

  async ngOnInit(): Promise<void> {
    await this.versionService.checkMinVersion();
  }
}
