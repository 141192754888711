/** This form is filled out by landlords when they add a property to Padspin */
export const landlord_exclusive_form_name = 'landlord_exclusive_form' as const;

export type LandlordExclusiveFormModel = {
  id?: string;
  uid: string;
  account_manager_uid: string | null;
  created_at: Date;
  email: string;
  first_name: string;
  last_name: string;
  bedrooms: string;
  unit_address: string;
  unit_number: string | null;
  unit_city: string;
  unit_state: string;
  unit_postal_code: string;
  type: 'owner' | 'lease_owner' | 'property_manager';
  rent: number;
  available_by_date: Date;
  phone: string;
  agree_cancellation: boolean;
  agree_exclusivity: boolean;
  agree_accuracy: boolean;
  agree_approval: boolean;
  agree_compensation: boolean | null;
  agree_renewal: boolean | null;
  agree_signature: boolean | null;
  landlord_signature: string;
  date_of_acceptance: Date;
  agree_renewal_tenant: boolean | null;
  agree_sublease_tenant: boolean | null;
  agree_disparage_tenant: boolean | null;
  agree_signature_tenant: boolean | null;
};

export class LandlordExclusiveFormImpl implements LandlordExclusiveFormModel {
  id?: string;
  uid: string;
  account_manager_uid: string | null;
  created_at: Date = new Date();
  email: string;
  first_name: string;
  last_name: string;
  bedrooms: string;
  unit_address: string;
  unit_number: string | null;
  unit_city: string;
  unit_state: string;
  unit_postal_code: string;
  type: 'owner' | 'lease_owner' | 'property_manager';
  rent: number;
  available_by_date: Date;
  phone: string;
  agree_cancellation: boolean;
  agree_exclusivity: boolean;
  agree_accuracy: boolean;
  agree_approval: boolean;
  agree_compensation: boolean | null;
  agree_renewal: boolean | null;
  agree_signature: boolean | null;
  landlord_signature: string;
  date_of_acceptance: Date;
  agree_renewal_tenant: boolean | null;
  agree_sublease_tenant: boolean | null;
  agree_disparage_tenant: boolean | null;
  agree_signature_tenant: boolean | null;

  constructor(model: Partial<LandlordExclusiveFormModel>) {
    this.id = model.id || undefined;
    this.uid = model.uid || '';
    this.account_manager_uid = model.account_manager_uid || null;
    this.created_at = model.created_at || new Date();
    this.email = model.email || '';
    this.first_name = model.first_name || '';
    this.last_name = model.last_name || '';
    this.bedrooms = model.bedrooms || '';
    this.unit_address = model.unit_address || '';
    this.unit_number = model.unit_number || null;
    this.unit_city = model.unit_city || '';
    this.unit_state = model.unit_state || '';
    this.unit_postal_code = model.unit_postal_code || '';
    this.type = model.type || 'owner';
    this.rent = model.rent || 0;
    this.available_by_date = model.available_by_date || new Date();
    this.phone = model.phone || '';
    this.agree_cancellation = model.agree_cancellation || false;
    this.agree_exclusivity = model.agree_exclusivity || false;
    this.agree_accuracy = model.agree_accuracy || false;
    this.agree_approval = model.agree_approval || false;
    this.agree_compensation =
      model.agree_compensation !== undefined ? model.agree_compensation : null;
    this.agree_renewal =
      model.agree_renewal !== undefined ? model.agree_renewal : null;
    this.agree_signature =
      model.agree_signature !== undefined ? model.agree_signature : null;
    this.landlord_signature = model.landlord_signature || '';
    this.date_of_acceptance = model.date_of_acceptance || new Date();
    this.agree_renewal_tenant =
      model.agree_renewal_tenant !== undefined
        ? model.agree_renewal_tenant
        : null;
    this.agree_sublease_tenant =
      model.agree_sublease_tenant !== undefined
        ? model.agree_sublease_tenant
        : null;
    this.agree_disparage_tenant =
      model.agree_disparage_tenant !== undefined
        ? model.agree_disparage_tenant
        : null;
    this.agree_signature_tenant =
      model.agree_signature_tenant !== undefined
        ? model.agree_signature_tenant
        : null;
  }
}
