import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToTopOfStepperDirective } from './scroll-to-top-of-stepper.directive';

@NgModule({
  declarations: [ScrollToTopOfStepperDirective],
  imports: [CommonModule],
  exports: [ScrollToTopOfStepperDirective],
})
export class ScrollToTopOfStepperModule {}
