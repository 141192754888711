import { Injectable } from '@angular/core';
import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  documentId,
  endAt,
  FieldValue,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  startAt,
  updateDoc,
  where,
  QueryDocumentSnapshot,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import {
  ListingEditModalComponent,
  ListingEditModalData,
} from './listing-edit-modal/listing-edit-modal.component';
import {
  lastValueFrom,
  Observable,
  combineLatest,
  map,
  switchMap,
  of,
  EMPTY,
  from,
  OperatorFunction,
} from 'rxjs';
import {
  listings_name,
  ListingsAdminModel,
  listingsAdminName,
  ListingsModel,
  ListingsModelID,
  messageAutomationName,
} from '@padspin/models';
import { doc as doc$, collection as collection$ } from 'rxfire/firestore';
import { DocumentData, DocumentSnapshot } from 'rxfire/firestore/interfaces';
import { ListingImageService } from './listing-image.service';
import { UuidService } from './uuid.service';
import { filter } from 'rxjs/operators';
import { ListingsService } from './search-page/listings.service';
import { google } from 'google-maps';
import { distanceBetween, geohashQueryBounds } from 'geofire-common';
import { MapService } from './search-page/map.service';
import {
  angularListingsAdminConverter,
  angularListingsConverter,
} from '@padspin/db-ng';
import { Router } from '@angular/router';
import { angularSMSQueueConverter } from '@padspin/db-ng';

@Injectable({
  providedIn: 'root',
})
export class Listings2Service {
  constructor(
    private readonly dialog: MatDialog,
    private readonly imageService: ListingImageService,
    private readonly uuidService: UuidService,
    private readonly legacyListingsService: ListingsService,
    private readonly mapService: MapService,
    private readonly router: Router
  ) {}

  openEditListingDialog = async (listingId: string) => {
    const data: ListingEditModalData = { listingIdOrSlug: listingId };
    const dialogRef = this.dialog.open(ListingEditModalComponent, {
      data,
      backdropClass: 'modal_backdrop',
      panelClass: 'modal_panel',
      hasBackdrop: true,
    });
    return lastValueFrom(dialogRef.afterClosed());
  };

  enableListing = async (listingId: string, firestore = getFirestore()) =>
    updateDoc(doc(firestore, listings_name, listingId), {
      is_active: true,
    });

  /**
   * When a listing is disabled, the backend will send emails to all the
   * leads interested in the listing, and notify them it is unavailable.
   *
   * When a listing is disabled, and a lead has queued SMS's waiting to
   * be sent, the backend will dequeue them.
   */
  disableListing = async (listingId: string, firestore = getFirestore()) =>
    updateDoc(doc(firestore, listings_name, listingId), {
      is_active: false,
    });

  /**
   * De-queues all pending SMS messages to be sent to leads for a listing.
   * @deprecated Should be done on the backend
   * @returns Number of messages removed.
   */
  dequeueListingSMS = async (
    listingId: string,
    listingSlug: string,
    firestore = getFirestore()
  ): Promise<number> => {
    const snaps = await getDocs(
      query(
        collection(firestore, messageAutomationName).withConverter(
          angularSMSQueueConverter
        ),
        where('data.listing_url', '==', `https://padspin.com/l/${listingSlug}`)
      )
    );
    // Stop sending the messages reminding the leads to view apartments
    for (const snap of snaps.docs) {
      await deleteDoc(snap.ref);
    }
    return snaps.size;
  };

  private getListingDocAndTypeBySlug$ = (
    slug: string,
    firestore = getFirestore(),
    slugFriendly?: string
  ): Observable<[DocumentSnapshot<DocumentData>, 'active' | 'inactive']> =>
    of(
      getDocs(
        query(
          collection(firestore, listings_name).withConverter(
            angularListingsConverter
          ),
          where('slug', '==', slug),
          limit(1)
        )
      )
    ).pipe(
      switchMap((snaps) => snaps),
      map((snaps) => snaps.docs[0]),
      filter((snaps) => {
        const slugFriendlyGotInRequest = slugFriendly;
        // const { bedrooms, cross_street, city, state, neighborhood } =
        //   snaps.data();
        const slugFriendlyStored = snaps.data().slugFriendly;
        // console.log(
        //   `defaultSlugFriendly: https://padspin-dev-2e3aa.firebaseapp.com/no-fee-apartments-for-rent/${slug}/${defaultSlugFriendly}`
        // );
        if (
          slugFriendlyGotInRequest !== undefined &&
          slugFriendlyGotInRequest !== slugFriendlyStored
        ) {
          this.router.navigate(['/not-found']);
          return false;
        }
        return snaps.exists();
      }),
      map((snap) => [snap, snap.data().is_active ? 'active' : 'inactive'])
    );

  private getListingDocAndTypeByID$ = (
    listingId: string,
    firestore = getFirestore()
  ): Observable<
    [DocumentSnapshot<ListingsModel>, 'active' | 'inactive' | null]
  > =>
    of(
      getDoc(
        doc(firestore, `${listings_name}/${listingId}`).withConverter(
          angularListingsConverter
        )
      )
    ).pipe(
      switchMap((snap) => snap),
      map((snap) => {
        const data: ListingsModel | undefined = snap.data();
        return [snap, data ? (data.is_active ? 'active' : 'inactive') : null];
      })
    );

  getListing$ = (
    listingIdOrSlug: string | undefined
  ): Observable<ListingsModelID> => {
    if (!listingIdOrSlug) {
      return EMPTY;
    }
    const isSlug = this.uuidService.isSlug(listingIdOrSlug);
    console.log(`getListing$`);
    const r = this.getListingBySlug$(listingIdOrSlug);
    console.log(r);
    return isSlug ? r : this.getListingByID$(listingIdOrSlug);
  };

  getListingBySlug$ = (
    slug: string,
    slugFriendly?: string
  ): Observable<ListingsModelID> =>
    this.#getListingDocBySlug$(slug, slugFriendly).pipe(
      map((listingDoc) => ({
        id: listingDoc.id,
        ...(listingDoc.data() as ListingsModel),
      }))
    );

  getListingByID$ = (listingId: string): Observable<ListingsModelID> => {
    if (this.isLegacyListingId(listingId)) {
      return this.legacyListingsService.getListingByIdAsListing$(listingId);
    }
    return this.#getListingDocByID$(listingId).pipe(
      map(
        (listingDoc) =>
          ({ id: listingDoc.id, ...listingDoc.data() } as ListingsModelID)
      )
    );
  };

  #getListingDocBySlug$ = (
    slug: string,
    slugFriendly?: string
  ): Observable<DocumentSnapshot<DocumentData>> =>
    this.getListingDocAndTypeBySlug$(slug, undefined, slugFriendly).pipe(
      map(([listingDoc, _type]) => listingDoc)
    );

  #getListingDocByID$ = (
    listingId: string
  ): Observable<DocumentSnapshot<ListingsModel>> =>
    this.getListingDocAndTypeByID$(listingId).pipe(
      map(([listingDoc, _type]) => listingDoc)
    );

  getListingTypeBySlug$ = (slug: string): Observable<'active' | 'inactive'> =>
    this.getListingDocAndTypeBySlug$(slug).pipe(map(([_doc, type]) => type));

  getListingTypeByID$ = (
    listingId: string
  ): Observable<'active' | 'inactive' | null> =>
    this.getListingDocAndTypeByID$(listingId).pipe(map(([_doc, type]) => type));

  getListingChangesBySlug$ = (slug: string): Observable<ListingsModelID> =>
    this.#getListingDocBySlug$(slug).pipe(
      switchMap((snap) => doc$(snap.ref)),
      map((snap) => ({ ...(snap.data() as ListingsModel), id: snap.id }))
    );

  getListingChangesByID$ = (
    listingId: string,
    firestore = getFirestore()
  ): Observable<ListingsModelID> =>
    doc$(
      doc(firestore, `${listings_name}/${listingId}`).withConverter(
        angularListingsConverter
      )
    ).pipe(
      map((listingDoc) => ({ id: listingDoc.id, ...listingDoc.data() })),
      filter((l): l is ListingsModelID => !!l)
    );

  getListingsChangesByUid$ = (uid: string): Observable<ListingsModelID[]> =>
    collection$(
      query(
        collection(getFirestore(), listings_name).withConverter(
          angularListingsConverter
        ),
        where('user_id', '==', uid),
        orderBy('created', 'desc')
      ).withConverter(angularListingsConverter)
    ).pipe(
      map((snaps) =>
        snaps.map((snap) => ({
          ...snap.data(),
          id: snap.id,
        }))
      )
    );

  updateListingBySlug$ = (
    slug: string,
    partialListing: Partial<ListingsModel>
  ) =>
    this.getListingDocAndTypeBySlug$(slug).pipe(
      switchMap(([snapshot, _status]) =>
        setDoc(snapshot.ref, partialListing, { merge: true })
      )
    );

  updateListingByID$ = (
    listingId: string,
    partialListing: Partial<ListingsModel>
  ) =>
    this.getListingDocAndTypeByID$(listingId).pipe(
      switchMap(([snapshot, _status]) =>
        setDoc(snapshot.ref, partialListing, { merge: true })
      )
    );

  uploadImageAndAddToListingsBySlug$ = (
    image: File,
    slug: string
  ): Observable<void> =>
    this.#getListingDocBySlug$(slug).pipe(
      switchMap((listingSnap) =>
        this.uploadImageAndAddToListingByID$(image, listingSnap.id)
      )
    );

  uploadImageAndAddToListingByID$ = (
    image: File,
    listingId: string
  ): Observable<void> =>
    this.#getListingDocByID$(listingId).pipe(
      map((listingSnap) => [listingSnap, this.uuidService.v6()] as const),
      switchMap(([listingSnap, imageId]) =>
        combineLatest([
          this.imageService.uploadAndGetDownloadURL(image, listingId, imageId),
          of(imageId),
          of(listingSnap),
        ])
      ),
      switchMap(([{ downloadURL }, imageId, listingSnap]) => {
        const listingImages: Record<
          | 'images'
          | 'image_urls_200'
          | 'image_urls_600'
          | 'image_urls_1200'
          | 'image_urls_original',
          FieldValue
        > = {
          images: arrayUnion(imageId),
          image_urls_200: arrayUnion(`${downloadURL}_200x200`),
          image_urls_600: arrayUnion(`${downloadURL}_600x600`),
          image_urls_1200: arrayUnion(`${downloadURL}_1200x1200`),
          image_urls_original: arrayUnion(downloadURL),
        };
        return updateDoc(listingSnap.ref, listingImages);
      })
    );

  deleteImage$ = (imageId: string, listingId: string) =>
    this.#getListingDocByID$(listingId).pipe(
      filter((listingSnap) => !!listingSnap.exists()),
      switchMap(async (listingSnap) => {
        const data: ListingsModel = (await listingSnap.data()) as ListingsModel;
        const remove_original = data.image_urls_original.find((orig) =>
          orig.includes(imageId)
        );
        const remove_200 = data.image_urls_200.find((orig) =>
          orig.includes(imageId)
        );
        const remove_600 = data.image_urls_600.find((orig) =>
          orig.includes(imageId)
        );
        const remove_1200 = data.image_urls_1200.find((orig) =>
          orig.includes(imageId)
        );
        const listingImages: Record<
          | 'images'
          | 'image_urls_200'
          | 'image_urls_600'
          | 'image_urls_1200'
          | 'image_urls_original',
          FieldValue
        > = {
          images: arrayRemove(imageId),
          image_urls_200: arrayRemove(remove_200),
          image_urls_600: arrayRemove(remove_600),
          image_urls_1200: arrayRemove(remove_1200),
          image_urls_original: arrayRemove(remove_original),
        };
        return updateDoc(listingSnap.ref, listingImages);
      })
    );

  getListingAdminByID$ = (
    listingId: string,
    firestore = getFirestore()
  ): Observable<ListingsAdminModel> =>
    from(
      getDocs(
        query(
          collection(firestore, listingsAdminName).withConverter(
            angularListingsConverter
          ),
          where(documentId(), '==', listingId)
        )
      )
    ).pipe(
      map((qs) =>
        qs.docs.length
          ? angularListingsAdminConverter.fromFirestore(qs.docs[0])
          : null
      ),
      filter((listingsAdmin) => !!listingsAdmin) as OperatorFunction<
        ListingsAdminModel | null,
        ListingsAdminModel
      >
    );

  getListingAdminChangesByID$ = (
    listingId: string,
    firestore = getFirestore()
  ): Observable<ListingsAdminModel> =>
    from(
      getDocs(
        query(
          collection(firestore, listingsAdminName).withConverter(
            angularListingsAdminConverter
          ),
          where(documentId(), '==', listingId),
          limit(1)
        )
      )
    ).pipe(
      map((qs) => (qs.docs.length ? qs.docs[0] : null)),
      filter((qds) => !!qds) as OperatorFunction<
        QueryDocumentSnapshot<ListingsAdminModel> | null,
        QueryDocumentSnapshot<ListingsAdminModel>
      >,
      switchMap((qds) => doc$(qds.ref)),
      map((snap) => snap.data() || null),
      filter((maybeLAM) => !!maybeLAM) as OperatorFunction<
        ListingsAdminModel | null,
        ListingsAdminModel
      >
    );

  async createListing(
    listing: ListingsModel,
    listingId = this.uuidService.v6()
  ) {
    const firestore = getFirestore();
    const docRef = doc(
      firestore,
      `${listings_name}/${listingId}`
    ).withConverter(angularListingsConverter);
    await setDoc(docRef, { ...listing }, { merge: true });
  }

  async getListingsByLocation(
    center: google.maps.LatLngLiteral,
    bounds: google.maps.LatLngBounds
  ): Promise<ListingsModelID[]> {
    const firestore = getFirestore();
    const circumferenceInKM = distanceBetween(
      this.mapService.convertCoordToLatLngArray(bounds.getNorthEast()),
      this.mapService.convertCoordToLatLngArray(bounds.getSouthWest())
    );
    const radiusInKM = circumferenceInKM / 2;
    const radiusInMeters = 1000 * radiusInKM;
    const queryBounds = geohashQueryBounds(
      [center.lat, center.lng],
      radiusInMeters
    );
    const promises = [];
    for (const b of queryBounds) {
      const q = query(
        collection(firestore, listings_name),
        where('is_active', '==', true),
        orderBy('geohash'),
        startAt(b[0]),
        endAt(b[1])
      ).withConverter(angularListingsConverter);
      promises.push(getDocs(q));
    }
    return Promise.all(promises).then((snapshots) => {
      const matchingDocs: ListingsModelID[] = [];
      for (const snap of snapshots) {
        for (const d of snap.docs) {
          const lat = d.data()['latitude'];
          const lng = d.data()['longitude'];
          const distanceInKm = distanceBetween(
            [lat, lng],
            [center.lat, center.lng]
          );
          const distanceInM = 1000 * distanceInKm;
          if (distanceInM <= radiusInMeters) {
            const listing: ListingsModelID = {
              ...d.data(),
              id: d.id,
            } as ListingsModelID;
            matchingDocs.push(listing);
          }
        }
      }
      return matchingDocs;
    });
  }

  updateListingAdminByID = (
    listingId: string,
    partialListingAdmin: Partial<ListingsAdminModel>,
    firestore = getFirestore()
  ) =>
    setDoc(
      doc(firestore, `${listingsAdminName}/${listingId}`),
      partialListingAdmin,
      { merge: true }
    );

  isListingId = (listingIdOrSlug: string): boolean =>
    this.uuidService.isUuidV6(listingIdOrSlug);

  compareListingsByDistance<T extends ListingsModel | ListingsModelID>(
    a: T,
    b: T
  ): number {
    const centerOfMap: google.maps.LatLng =
      this.mapService.convertCoordToLatLng(this.mapService.centerOfMap);
    const distanceA = google.maps.geometry.spherical.computeDistanceBetween(
      { lat: a.latitude, lng: a.longitude },
      centerOfMap
    );
    const distanceB = google.maps.geometry.spherical.computeDistanceBetween(
      { lat: b.latitude, lng: b.longitude },
      centerOfMap
    );
    return distanceA - distanceB;
  }

  /** Legacy listings are strings like "1234" that can be converted to ints */
  private readonly isLegacyListingId = (listingId: string) => {
    return !isNaN(Number(listingId));
  };

  private toSlug(text: string): string {
    return text
      .toLowerCase() // convert to lowercase
      .replace('undefined', '')
      .replace('null', '')
      .replace(/[^a-z0-9]+/g, '-') // replace non-alphanumeric characters with hyphens
      .replace(/^-|-$/g, ''); // remove leading and trailing hyphens
  }
}
