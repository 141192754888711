import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangeSliderModule } from './range-slider/range-slider.module';
import { ButtonModule } from './button/button.module';
import { InputTextModule } from './input-text/input-text.module';
import { PaginatorModule } from './paginator/paginator.module';
import { UserPreviewModule } from './user-preview/user-preview.module';
import { UserAvatarModule } from './user-avatar/user-avatar.module';
import { RelativeDatePipeModule } from './pipes/relative-date/relative-date.pipe.module';
import { TimestampToDatePipeModule } from './pipes/timestamp-to-date/timestamp-to-date-pipe.module';
import { SanitizeHtmlModule } from './pipes/sanitize-html/sanitize-html.module';
import { OrdinalSuffixPipeModule } from './pipes/ordinal-suffix/ordinal-suffix.pipe.module';
import { UploadToStorageButtonModule } from './upload-to-storage-button/upload-to-storage-button.module';
import { ScrollToTopOfStepperModule } from './scroll-to-top-of-stepper/scroll-to-top-of-stepper.module';
import { RedactTextPipeModule } from './pipes/redact-text/redact-text.pipe.module';

@NgModule({
  imports: [
    CommonModule,
    RangeSliderModule,
    ButtonModule,
    InputTextModule,
    PaginatorModule,
    RelativeDatePipeModule,
    TimestampToDatePipeModule,
    SanitizeHtmlModule,
    OrdinalSuffixPipeModule,
    UploadToStorageButtonModule,
    ScrollToTopOfStepperModule,
    RedactTextPipeModule,
  ],
  exports: [
    RangeSliderModule,
    ButtonModule,
    InputTextModule,
    PaginatorModule,
    UserPreviewModule,
    UserAvatarModule,
    RelativeDatePipeModule,
    TimestampToDatePipeModule,
    SanitizeHtmlModule,
    OrdinalSuffixPipeModule,
    UploadToStorageButtonModule,
    ScrollToTopOfStepperModule,
    RedactTextPipeModule,
  ],
})
export class UiModule {}
