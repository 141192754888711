import { WithOptionalId } from './types/with-id';

export const rentalApplicationName = 'application' as const;

export type RentalApplicationPersonModel = {
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
};

export type RentalApplicationModel = {
  created: Date;
  /** Parent Tenant or Roommate */
  parent?: string;
  /** Relationship with parent */
  relationship: string | null;
  type: 'tenant' | 'roommate' | 'guarantor';
  /** slug of the listing */
  slug: string;
  /**
   * The guarantors when rental application was completed.
   * Child guarantors added later are not part of this array.
   */
  guarantors: RentalApplicationPersonModel[];
  /**
   * The roommates when rental application was completed.
   * Child roommates added later are not part of this array.
   */
  roommates: RentalApplicationPersonModel[];
  /** True after sending invites to the applicant's guarantors and roommates */
  sent_invites: boolean;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  /**
   * Social security number
   * TODO: Must be encrypted
   */
  ssn: string;
  /**
   * @example "2022-06-16T05:00:00.000Z"
   */
  date_of_birth: Date;
  id_type: string;
  id_number: string;
  id_issuing_state: string;
  apt_street_1: string;
  /** Unit number */
  apt_street_2: string | null;
  apt_city: string;
  apt_state: string;
  apt_zip_code: string;
  /** Monthly rent */
  rent_price: number;
  /**
   * @example "2022-06-16T05:00:00.000Z"
   */
  start_date: Date;
  /** Present street address */
  curr_street_1: string;
  curr_street_2: string | null;
  curr_city: string;
  curr_state: string;
  curr_zip_code: string;
  curr_rent_or_own: string;
  curr_monthly_rent: number;
  /**
   * @example "2022-06-16T05:00:00.000Z"
   */
  curr_lease_start_date: Date;
  /**
   * @example "2022-06-16T05:00:00.000Z"
   */
  curr_lease_end_date: Date;
  /** Landlord first name */
  curr_ll_first_name: string;
  curr_ll_last_name: string;
  curr_ll_street_1: string;
  curr_ll_street_2: string | null;
  curr_ll_city: string;
  curr_ll_state: string;
  curr_ll_zip_code: string;
  curr_ll_phone: string;
  curr_ll_email: string;
  prev_street_1: string | null;
  prev_street_2: string | null;
  prev_city: string | null;
  prev_state: string | null;
  prev_zip_code: string | null;
  prev_rent_or_own: string | null;
  prev_monthly_rent: number | null;
  prev_lease_start_date: Date | null;
  prev_lease_end_date: Date | null;
  prev_ll_first_name: string | null;
  prev_ll_last_name: string | null;
  prev_ll_street_1: string | null;
  prev_ll_street_2: string | null;
  prev_ll_city: string | null;
  prev_ll_state: string | null;
  prev_ll_zip_code: string | null;
  prev_ll_phone: string | null;
  prev_ll_email: string | null;
  curr_employ_name: string;
  curr_employ_street_1: string;
  curr_employ_street_2: string | null;
  curr_employ_city: string;
  curr_employ_state: string;
  curr_employ_zip_code: string;
  curr_job_title: string;
  curr_employ_start_date: Date;
  curr_salary: number;
  /** Current supervisor first name */
  curr_supe_first_name: string;
  curr_supe_last_name: string;
  curr_supe_phone: string;
  curr_supe_email: string;
  prev_employ_name: string | null;
  prev_employ_street_1: string | null;
  prev_employ_street_2: string | null;
  prev_employ_city: string | null;
  prev_employ_state: string | null;
  prev_employ_zip_code: string | null;
  prev_job_title: string | null;
  prev_employ_start_date: Date | null;
  prev_employ_end_date: Date | null;
  prev_salary: number | null;
  prev_supe_first_name: string | null;
  prev_supe_last_name: string | null;
  prev_supe_phone: string | null;
  prev_supe_email: string | null;
  /** Other types of income */
  misc_income: string;
  misc_income_supp_doc: string;
  /** Emergency contact first name */
  emerge_first_name: string;
  emerge_last_name: string;
  /** Emergency contact relationship */
  emerge_relat: string;
  emerge_street_1: string;
  emerge_street_2: string | null;
  emerge_city: string;
  emerge_state: string;
  emerge_zip_code: string;
  emerge_phone: string;
  emerge_email: string;
  credit_score: number;
  credit_report: string;
  photo_id: string;
  gross_pay_amount_1: number;
  gross_pay_amount_2: number;
  pay_stub_1: string;
  pay_stub_2: string;
  /** @example "monthly" */
  pay_period: string;
  /** Storage file path */
  recent_bank_state: string;
  /** Storage file path */
  prev_bank_state: string;
  recent_statement_amount: number;
  prev_statement_amount: number;
  /** Yearly income */
  adjusted_gross_income: number;
  /** Tax return form or W-2(s) */
  tax_return: string;
  /** Storage file path  */
  employ_letter: string | null;
  /** How much an employee makes at the job (the document in employ_letter) */
  annual_compensation_listed_in_employ_letter: number;
  agree_incorrect_submission_fee: boolean;
  /**
   * Anything to add? You can use this space to expand upon anything that is not might be unclear in either your application or these documents.
   */
  misc_state: string;
  agree_provide_correct_info: boolean;
  agree_consumer_report: boolean;
  agree_electronic_signature: boolean;
  /** What was typed into the electronic signature input */
  electronic_signature: string;
  electronic_signature_date: Date;
};

export class RentalApplicationImpl implements RentalApplicationModel {
  id?: string;
  adjusted_gross_income!: number;
  agree_consumer_report!: boolean;
  agree_electronic_signature!: boolean;
  agree_incorrect_submission_fee!: boolean;
  agree_provide_correct_info!: boolean;
  annual_compensation_listed_in_employ_letter!: number;
  apt_city!: string;
  apt_state!: string;
  apt_street_1!: string;
  apt_street_2!: string | null;
  apt_zip_code!: string;
  created!: Date;
  credit_report!: string;
  credit_score!: number;
  curr_city!: string;
  curr_employ_city!: string;
  curr_employ_name!: string;
  curr_employ_start_date!: Date;
  curr_employ_state!: string;
  curr_employ_street_1!: string;
  curr_employ_street_2!: string | null;
  curr_employ_zip_code!: string;
  curr_job_title!: string;
  curr_lease_end_date!: Date;
  curr_lease_start_date!: Date;
  curr_ll_city!: string;
  curr_ll_email!: string;
  curr_ll_first_name!: string;
  curr_ll_last_name!: string;
  curr_ll_phone!: string;
  curr_ll_state!: string;
  curr_ll_street_1!: string;
  curr_ll_street_2!: string | null;
  curr_ll_zip_code!: string;
  curr_monthly_rent!: number;
  curr_rent_or_own!: string;
  curr_salary!: number;
  curr_state!: string;
  curr_street_1!: string;
  curr_street_2!: string | null;
  curr_supe_email!: string;
  curr_supe_first_name!: string;
  curr_supe_last_name!: string;
  curr_supe_phone!: string;
  curr_zip_code!: string;
  date_of_birth!: Date;
  electronic_signature!: string;
  electronic_signature_date!: Date;
  email!: string;
  emerge_city!: string;
  emerge_email!: string;
  emerge_first_name!: string;
  emerge_last_name!: string;
  emerge_phone!: string;
  emerge_relat!: string;
  emerge_state!: string;
  emerge_street_1!: string;
  emerge_street_2!: string | null;
  emerge_zip_code!: string;
  employ_letter!: string | null;
  first_name!: string;
  gross_pay_amount_1!: number;
  gross_pay_amount_2!: number;
  guarantors!: RentalApplicationPersonModel[];
  id_issuing_state!: string;
  id_number!: string;
  id_type!: string;
  last_name!: string;
  misc_income!: string;
  misc_income_supp_doc!: string;
  misc_state!: string;
  parent?: string;
  pay_period!: string;
  pay_stub_1!: string;
  pay_stub_2!: string;
  phone!: string;
  photo_id!: string;
  prev_bank_state!: string;
  prev_city!: string | null;
  prev_employ_city!: string | null;
  prev_employ_end_date!: Date | null;
  prev_employ_name!: string | null;
  prev_employ_start_date!: Date | null;
  prev_employ_state!: string | null;
  prev_employ_street_1!: string | null;
  prev_employ_street_2!: string | null;
  prev_employ_zip_code!: string | null;
  prev_job_title!: string | null;
  prev_lease_end_date!: Date | null;
  prev_lease_start_date!: Date | null;
  prev_ll_city!: string | null;
  prev_ll_email!: string | null;
  prev_ll_first_name!: string | null;
  prev_ll_last_name!: string | null;
  prev_ll_phone!: string | null;
  prev_ll_state!: string | null;
  prev_ll_street_1!: string | null;
  prev_ll_street_2!: string | null;
  prev_ll_zip_code!: string | null;
  prev_monthly_rent!: number | null;
  prev_rent_or_own!: string | null;
  prev_salary!: number | null;
  prev_state!: string | null;
  prev_statement_amount!: number;
  prev_street_1!: string | null;
  prev_street_2!: string | null;
  prev_supe_email!: string | null;
  prev_supe_first_name!: string | null;
  prev_supe_last_name!: string | null;
  prev_supe_phone!: string | null;
  prev_zip_code!: string | null;
  recent_bank_state!: string;
  recent_statement_amount!: number;
  relationship!: string | null;
  rent_price!: number;
  roommates!: RentalApplicationPersonModel[];
  sent_invites!: boolean;
  slug!: string;
  ssn!: string;
  start_date!: Date;
  type!: RentalApplicationModel['type'];
  tax_return!: string;

  constructor(
    modelWithNullableKeys:
      | RentalApplicationModel
      | Partial<RentalApplicationModel>
      | {
          [K in keyof RentalApplicationModel]:
            | RentalApplicationModel[K]
            | null
            | undefined;
        }
  ) {
    const model = RentalApplicationImpl.convertNullableApplicationToModel(
      modelWithNullableKeys
    );
    Object.assign(this, model);
  }

  static convertNullableApplicationToModel(
    model:
      | Partial<WithOptionalId<RentalApplicationModel>>
      | {
          [K in keyof WithOptionalId<RentalApplicationModel>]:
            | WithOptionalId<RentalApplicationModel>[K]
            | null
            | undefined;
        }
  ): WithOptionalId<RentalApplicationModel> {
    return {
      adjusted_gross_income:
        typeof model.adjusted_gross_income === 'number'
          ? model.adjusted_gross_income
          : 0,
      agree_consumer_report:
        typeof model.agree_consumer_report !== 'boolean'
          ? false
          : model.agree_consumer_report,
      agree_electronic_signature:
        typeof model.agree_electronic_signature !== 'boolean'
          ? false
          : model.agree_electronic_signature,
      agree_incorrect_submission_fee:
        typeof model.agree_incorrect_submission_fee !== 'boolean'
          ? false
          : model.agree_incorrect_submission_fee,
      agree_provide_correct_info:
        typeof model.agree_provide_correct_info !== 'boolean'
          ? false
          : model.agree_provide_correct_info,
      annual_compensation_listed_in_employ_letter:
        typeof model.annual_compensation_listed_in_employ_letter === 'number'
          ? model.annual_compensation_listed_in_employ_letter
          : 0,
      apt_city: typeof model.apt_city === 'string' ? model.apt_city : '',
      apt_state: typeof model.apt_state === 'string' ? model.apt_state : '',
      apt_street_1:
        typeof model.apt_street_1 === 'string' ? model.apt_street_1 : '',
      apt_street_2:
        typeof model.apt_street_2 === 'string' ? model.apt_street_2 : null,
      apt_zip_code:
        typeof model.apt_zip_code === 'string' ? model.apt_zip_code : '',
      created: model.created instanceof Date ? model.created : new Date(),
      credit_report:
        typeof model.credit_report === 'string' ? model.credit_report : '',
      credit_score:
        typeof model.credit_score === 'number' ? model.credit_score : 0,
      curr_city: typeof model.curr_city === 'string' ? model.curr_city : '',
      curr_employ_city:
        typeof model.curr_employ_city === 'string'
          ? model.curr_employ_city
          : '',
      curr_employ_name:
        typeof model.curr_employ_name === 'string'
          ? model.curr_employ_name
          : '',
      curr_employ_start_date:
        model.curr_employ_start_date instanceof Date
          ? model.curr_employ_start_date
          : new Date(),
      curr_employ_state:
        typeof model.curr_employ_state === 'string'
          ? model.curr_employ_state
          : '',
      curr_employ_street_1:
        typeof model.curr_employ_street_1 === 'string'
          ? model.curr_employ_street_1
          : '',
      curr_employ_street_2:
        typeof model.curr_employ_street_2 === 'string'
          ? model.curr_employ_street_2
          : null,
      curr_employ_zip_code:
        typeof model.curr_employ_zip_code === 'string'
          ? model.curr_employ_zip_code
          : '',
      curr_job_title:
        typeof model.curr_job_title === 'string' ? model.curr_job_title : '',
      curr_lease_end_date:
        model.curr_lease_end_date instanceof Date
          ? model.curr_lease_end_date
          : new Date(),
      curr_lease_start_date:
        model.curr_lease_start_date instanceof Date
          ? model.curr_lease_start_date
          : new Date(),
      curr_ll_city:
        typeof model.curr_ll_city === 'string' ? model.curr_ll_city : '',
      curr_ll_email:
        typeof model.curr_ll_email === 'string' ? model.curr_ll_email : '',
      curr_ll_first_name:
        typeof model.curr_ll_first_name === 'string'
          ? model.curr_ll_first_name
          : '',
      curr_ll_last_name:
        typeof model.curr_ll_last_name === 'string'
          ? model.curr_ll_last_name
          : '',
      curr_ll_phone:
        typeof model.curr_ll_phone === 'string' ? model.curr_ll_phone : '',
      curr_ll_state:
        typeof model.curr_ll_state === 'string' ? model.curr_ll_state : '',
      curr_ll_street_1:
        typeof model.curr_ll_street_1 === 'string'
          ? model.curr_ll_street_1
          : '',
      curr_ll_street_2:
        typeof model.curr_ll_street_2 === 'string'
          ? model.curr_ll_street_2
          : null,
      curr_ll_zip_code:
        typeof model.curr_ll_zip_code === 'string'
          ? model.curr_ll_zip_code
          : '',
      curr_monthly_rent:
        typeof model.curr_monthly_rent === 'number'
          ? model.curr_monthly_rent
          : 0,
      curr_rent_or_own:
        typeof model.curr_rent_or_own === 'string'
          ? model.curr_rent_or_own
          : '',
      curr_salary:
        typeof model.curr_salary === 'number' ? model.curr_salary : 0,
      curr_state: typeof model.curr_state === 'string' ? model.curr_state : '',
      curr_street_1:
        typeof model.curr_street_1 === 'string' ? model.curr_street_1 : '',
      curr_street_2:
        typeof model.curr_street_2 === 'string' ? model.curr_street_2 : null,
      curr_supe_email:
        typeof model.curr_supe_email === 'string' ? model.curr_supe_email : '',
      curr_supe_first_name:
        typeof model.curr_supe_first_name === 'string'
          ? model.curr_supe_first_name
          : '',
      curr_supe_last_name:
        typeof model.curr_supe_last_name === 'string'
          ? model.curr_supe_last_name
          : '',
      curr_supe_phone:
        typeof model.curr_supe_phone === 'string' ? model.curr_supe_phone : '',
      curr_zip_code:
        typeof model.curr_zip_code === 'string' ? model.curr_zip_code : '',
      date_of_birth:
        model.date_of_birth instanceof Date ? model.date_of_birth : new Date(),
      electronic_signature:
        typeof model.electronic_signature === 'string'
          ? model.electronic_signature
          : '',
      electronic_signature_date:
        model.electronic_signature_date instanceof Date
          ? model.electronic_signature_date
          : new Date(),
      email: typeof model.email === 'string' ? model.email : '',
      emerge_city:
        typeof model.emerge_city === 'string' ? model.emerge_city : '',
      emerge_email:
        typeof model.emerge_email === 'string' ? model.emerge_email : '',
      emerge_first_name:
        typeof model.emerge_first_name === 'string'
          ? model.emerge_first_name
          : '',
      emerge_last_name:
        typeof model.emerge_last_name === 'string'
          ? model.emerge_last_name
          : '',
      emerge_phone:
        typeof model.emerge_phone === 'string' ? model.emerge_phone : '',
      emerge_relat:
        typeof model.emerge_relat === 'string' ? model.emerge_relat : '',
      emerge_state:
        typeof model.emerge_state === 'string' ? model.emerge_state : '',
      emerge_street_1:
        typeof model.emerge_street_1 === 'string' ? model.emerge_street_1 : '',
      emerge_street_2:
        typeof model.emerge_street_2 === 'string'
          ? model.emerge_street_2
          : null,
      emerge_zip_code:
        typeof model.emerge_zip_code === 'string' ? model.emerge_zip_code : '',
      employ_letter:
        typeof model.employ_letter === 'string' ? model.employ_letter : null,
      first_name: typeof model.first_name === 'string' ? model.first_name : '',
      gross_pay_amount_1:
        typeof model.gross_pay_amount_1 === 'number'
          ? model.gross_pay_amount_1
          : 0,
      gross_pay_amount_2:
        typeof model.gross_pay_amount_2 === 'number'
          ? model.gross_pay_amount_2
          : 0,
      guarantors:
        typeof model.guarantors === 'object' && Array.isArray(model.guarantors)
          ? model.guarantors.map<RentalApplicationPersonModel>((g) => ({
              first_name: g.first_name,
              last_name: g.last_name,
              phone: g.phone,
              email: g.email,
            }))
          : [],
      id: typeof model.id === 'string' ? model.id : undefined,
      id_issuing_state:
        typeof model.id_issuing_state === 'string'
          ? model.id_issuing_state
          : '',
      id_number: typeof model.id_number === 'string' ? model.id_number : '',
      id_type: typeof model.id_type === 'string' ? model.id_type : '',
      last_name: typeof model.last_name === 'string' ? model.last_name : '',
      misc_income:
        typeof model.misc_income === 'string' ? model.misc_income : '',
      misc_income_supp_doc:
        typeof model.misc_income_supp_doc === 'string'
          ? model.misc_income_supp_doc
          : '',
      misc_state: typeof model.misc_state === 'string' ? model.misc_state : '',
      pay_period: typeof model.pay_period === 'string' ? model.pay_period : '',
      pay_stub_1: typeof model.pay_stub_1 === 'string' ? model.pay_stub_1 : '',
      pay_stub_2: typeof model.pay_stub_2 === 'string' ? model.pay_stub_2 : '',
      parent: model.parent || undefined,
      phone: typeof model.phone === 'string' ? model.phone : '',
      photo_id: typeof model.photo_id === 'string' ? model.photo_id : '',
      prev_bank_state:
        typeof model.prev_bank_state === 'string' ? model.prev_bank_state : '',
      prev_city:
        typeof model.prev_city !== 'undefined' ? model.prev_city : null,
      prev_employ_city:
        typeof model.prev_employ_city !== 'undefined'
          ? model.prev_employ_city
          : null,
      prev_employ_end_date:
        model.prev_employ_end_date instanceof Date
          ? model.prev_employ_end_date
          : null,
      prev_employ_name:
        typeof model.prev_employ_name !== 'undefined'
          ? model.prev_employ_name
          : null,
      prev_employ_start_date:
        model.prev_employ_start_date instanceof Date
          ? model.prev_employ_start_date
          : null,
      prev_employ_state:
        typeof model.prev_employ_state !== 'undefined'
          ? model.prev_employ_state
          : null,
      prev_employ_street_1:
        typeof model.prev_employ_street_1 !== 'undefined'
          ? model.prev_employ_street_1
          : null,
      prev_employ_street_2:
        typeof model.prev_employ_street_2 !== 'undefined'
          ? model.prev_employ_street_2
          : null,
      prev_employ_zip_code:
        typeof model.prev_employ_zip_code !== 'undefined'
          ? model.prev_employ_zip_code
          : null,
      prev_job_title:
        typeof model.prev_job_title !== 'undefined'
          ? model.prev_job_title
          : null,
      prev_lease_end_date:
        model.prev_lease_end_date instanceof Date
          ? model.prev_lease_end_date
          : null,
      prev_lease_start_date:
        model.prev_lease_start_date instanceof Date
          ? model.prev_lease_start_date
          : null,
      prev_ll_city:
        typeof model.prev_ll_city !== 'undefined' ? model.prev_ll_city : null,
      prev_ll_email:
        typeof model.prev_ll_email !== 'undefined' ? model.prev_ll_email : null,
      prev_ll_first_name:
        typeof model.prev_ll_first_name !== 'undefined'
          ? model.prev_ll_first_name
          : null,
      prev_ll_last_name:
        typeof model.prev_ll_last_name !== 'undefined'
          ? model.prev_ll_last_name
          : null,
      prev_ll_phone:
        typeof model.prev_ll_phone !== 'undefined' ? model.prev_ll_phone : null,
      prev_ll_state:
        typeof model.prev_ll_state !== 'undefined' ? model.prev_ll_state : null,
      prev_ll_street_1:
        typeof model.prev_ll_street_1 !== 'undefined'
          ? model.prev_ll_street_1
          : null,
      prev_ll_street_2:
        typeof model.prev_ll_street_2 !== 'undefined'
          ? model.prev_ll_street_2
          : null,
      prev_ll_zip_code:
        typeof model.prev_ll_zip_code !== 'undefined'
          ? model.prev_ll_zip_code
          : '',
      prev_monthly_rent:
        typeof model.prev_monthly_rent !== 'undefined'
          ? model.prev_monthly_rent
          : null,
      prev_rent_or_own:
        typeof model.prev_rent_or_own !== 'undefined'
          ? model.prev_rent_or_own
          : null,
      prev_salary:
        typeof model.prev_salary !== 'undefined' ? model.prev_salary : null,
      prev_state:
        typeof model.prev_state !== 'undefined' ? model.prev_state : null,
      prev_statement_amount:
        typeof model.prev_statement_amount === 'number'
          ? model.prev_statement_amount
          : 0,
      prev_street_1:
        typeof model.prev_street_1 !== 'undefined' ? model.prev_street_1 : null,
      prev_street_2:
        typeof model.prev_street_2 !== 'undefined' ? model.prev_street_2 : null,
      prev_supe_email:
        typeof model.prev_supe_email !== 'undefined'
          ? model.prev_supe_email
          : null,
      prev_supe_first_name:
        typeof model.prev_supe_first_name !== 'undefined'
          ? model.prev_supe_first_name
          : null,
      prev_supe_last_name:
        typeof model.prev_supe_last_name !== 'undefined'
          ? model.prev_supe_last_name
          : null,
      prev_supe_phone:
        typeof model.prev_supe_phone !== 'undefined'
          ? model.prev_supe_phone
          : null,
      prev_zip_code:
        typeof model.prev_zip_code !== 'undefined' ? model.prev_zip_code : null,
      relationship:
        typeof model.relationship === 'string' ? model.relationship : null,
      recent_bank_state:
        typeof model.recent_bank_state === 'string'
          ? model.recent_bank_state
          : '',
      recent_statement_amount:
        typeof model.recent_statement_amount === 'number'
          ? model.recent_statement_amount
          : 0,
      rent_price: typeof model.rent_price === 'number' ? model.rent_price : 0,
      roommates:
        typeof model.roommates === 'object' && Array.isArray(model.roommates)
          ? model.roommates.map<RentalApplicationPersonModel>((g) => ({
              first_name: g.first_name,
              last_name: g.last_name,
              phone: g.phone,
              email: g.email,
            }))
          : [],
      sent_invites:
        typeof model.sent_invites !== 'boolean' ? false : model.sent_invites,
      slug: typeof model.slug === 'string' ? model.slug : '',
      ssn: typeof model.ssn === 'string' ? model.ssn : '',
      start_date:
        model.start_date instanceof Date ? model.start_date : new Date(),
      tax_return: typeof model.tax_return === 'string' ? model.tax_return : '',
      type: typeof model.type === 'string' ? model.type : 'tenant',
    };
  }
}
