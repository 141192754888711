<h1 mat-dialog-title>Download Images</h1>
<mat-dialog-content>
  <p-message severity="info" text="Download takes a few seconds"></p-message>
  <canvas #canvas style="display: none"></canvas>
  <img #img style="display: none" />
  <form *ngIf="dynamicForm" [formGroup]="dynamicForm">
    <div
      *ngFor="let img of data.listing.image_urls_1200; let ind = index"
      class="row"
    >
      <input
        id="watermark"
        placeholder="Watermark"
        type="text"
        [formControlName]="ind"
        pInputText
      />
      <img
        #image_urls_1200
        [src]="img"
        height="40"
        alt="Image {{ ind }} of the listing"
      />
      <canvas #image_urls_1200_canvas_1 style="display: none"></canvas>
      <canvas #image_urls_1200_canvas_2 style="display: none"></canvas>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <p-button
    label="Download"
    (onClick)="downloadImagesLoadingIndicator()"
    [icon]="
      (loading$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-cloud-download'
    "
  ></p-button>
  <button
    pButton
    label="Close"
    class="p-button-outlined"
    (click)="close()"
  ></button>
</mat-dialog-actions>
