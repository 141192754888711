/* eslint-disable @typescript-eslint/no-explicit-any */
import { dbNg, DbService } from './db.service';

export class MockDbService implements DbService {
  db: { [key in keyof typeof dbNg]: any };
  constructor() {
    const db: any = {};
    const keys = Object.keys(dbNg);
    keys.forEach((key) => (db[key] = () => ({})));
    this.db = db;
  }
}
