import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  ViewContainerRef,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  withLatestFrom,
} from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * The main purpose of this component is to wrap the youtube-player and make it
 * responsive. (Out of the box, the width/height must be manually set
 * unfortunately).
 */
@Component({
  selector: 'padspin-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss'],
})
export class YoutubeComponent implements AfterViewInit {
  videoId$ = new ReplaySubject<string>(1);
  #parentDimensions$ = new ReplaySubject<[number, number]>(1);
  #constraints$ = new BehaviorSubject<'width' | 'height'>('height');
  #dimensions$: Observable<[number, number]> = this.#parentDimensions$.pipe(
    withLatestFrom(this.#constraints$),
    map(([[w, h], constrainBy]) =>
      constrainBy === 'width' ? [w, h * 0.5625] : [w, h]
    )
  );
  width$: Observable<number> = this.#dimensions$.pipe(map(([w, _h]) => w));
  height$: Observable<number> = this.#dimensions$.pipe(map(([_w, h]) => h));

  /** video id */
  @Input()
  set video(str: string) {
    try {
      const url = new URL(str);
      const videoId =
        url.host === 'www.youtube.com' ||
        url.host === 'youtube.com' ||
        url.host === 'youtu.be'
          ? url.searchParams.get('v') || ''
          : str;
      if (videoId === '') {
        // https://youtu.be/ctf_KXKuZ4Q
        const arr = str.split('/');
        console.log(arr);
        const id = arr[arr.length - 1];
        this.videoId$.next(id);
        return;
      }
      this.videoId$.next(videoId);
    } catch {
      this.videoId$.next(str);
    }
  }

  @Input() set constrainBy(constrainBy: 'width' | 'height') {
    this.#constraints$.next(constrainBy);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.#parentDimensions$.next(this.parentDimensions);
  }

  constructor(
    private readonly vc: ViewContainerRef,
    private readonly cd: ChangeDetectorRef
  ) {}

  /** @deprecated */
  get parentWidth(): number {
    return this.vc.element.nativeElement.parentElement.offsetWidth;
  }

  get parentDimensions(): [number, number] {
    return [
      this.vc.element.nativeElement.parentElement.offsetWidth,
      this.vc.element.nativeElement.parentElement.offsetHeight,
    ];
  }

  ngAfterViewInit(): void {
    this.#parentDimensions$.next(this.parentDimensions);
    this.cd.detectChanges();
  }
}
