import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PadExpandedCarouselComponent } from './pad-expanded-carousel.component';
import { PadExpandedCarouselDialogModule } from './pad-expanded-carousel-dialog/pad-expanded-carousel-dialog.module';

@NgModule({
  declarations: [PadExpandedCarouselComponent],
  imports: [CommonModule, PadExpandedCarouselDialogModule],
  exports: [PadExpandedCarouselComponent],
})
export class PadExpandedCarouselModule {}
