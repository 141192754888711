<mat-dialog-content>
  <form [formGroup]="form">
    <p>Send Application Email</p>
    <p-listbox
      [options]="options"
      [metaKeySelection]="false"
      [filter]="true"
      [multiple]="false"
      optionLabel="name"
      [listStyle]="{ 'max-height': '250px' }"
      [style]="{ width: '100%' }"
      formControlName="user"
    >
      <ng-template let-user pTemplate="item">
        <div>{{ user.name }}</div>
      </ng-template>
    </p-listbox>
    <p>Lease Start Date</p>
    <p-calendar
      formControlName="lease_start_date"
      appendTo="body"
      [inline]="true"
      [style]="{ width: '100%' }"
    ></p-calendar>
    <input pInputText formControlName="title" placeholder="Title" />
    <p-editor formControlName="body" [style]="{ width: '100%' }" [formats]="[]">
      <ng-template pTemplate="header">
        <span class="ql-formats">
          <button type="button" class="ql-link" aria-label="Link"></button>
        </span>
      </ng-template>
    </p-editor>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <p-button
    [style]="{ marginRight: '4px' }"
    label="Send"
    [disabled]="form.invalid"
    (onClick)="sendApplication()"
    [icon]="(submitting$ | async) ? 'pi pi-spin pi-spinner' : ''"
  ></p-button>
  <p-button label="Close" (onClick)="close()"></p-button>
</mat-dialog-actions>
