<div
  *ngIf="(pad$ | async) === null"
  style="
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    gap: 10px;
  "
>
  <p-skeleton
    width="100vw"
    height="450px"
    shape="rectangle"
    *ngIf="(isActive$ | async) === null"
  ></p-skeleton>
  <div style="display: flex; gap: 10px" *ngIf="(isActive$ | async) === null">
    <p-skeleton width="50px" height="50px" shape="rectangle"></p-skeleton>
    <p-skeleton width="50px" height="50px" shape="rectangle"></p-skeleton>
    <p-skeleton width="50px" height="50px" shape="rectangle"></p-skeleton>
    <p-skeleton width="50px" height="50px" shape="rectangle"></p-skeleton>
  </div>
  <div
    style="
      display: flex;
      justify-content: space-between;
      gap: 10px;
      width: 66vw;
    "
    *ngIf="(isActive$ | async) === null"
  >
    <p-skeleton width="200px" height="32px" shape="rectangle"></p-skeleton>
    <p-skeleton width="120px" height="32px" shape="rectangle"></p-skeleton>
  </div>
  <div style="display: flex; gap: 10px" *ngIf="(isActive$ | async) === null">
    <p-skeleton width="66vw" height="250px" shape="rectangle"></p-skeleton>
  </div>
  <div style="display: flex; gap: 10px" *ngIf="(isActive$ | async) === null">
    <p-skeleton width="120px" height="32px" shape="rectangle"></p-skeleton>
    <p-skeleton width="120px" height="32px" shape="rectangle"></p-skeleton>
  </div>
</div>
<div [hidden]="isVisible$ | async">
  <div
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
    "
  >
    <h1 style="text-align: center">
      Sorry this apartment is no longer available.
    </h1>
    <p style="text-align: center; padding-left: 10px; padding-right: 10px">
      Padspin has the cheapest apartments and they go quick.<br /><br />
      Click the button below to match with other great below market rate
      apartments<br />
    </p>
    <button padspin-button routerLink="/listings" [center]="true">Match</button>
    <button
      *ngIf="isAdmin$ | async"
      padspin-button
      (click)="toEdit()"
      style="margin: 10px"
      [center]="true"
    >
      Edit
    </button>
  </div>
</div>

<div
  *ngIf="isMessageDisplayed$ | async"
  style="display: flex; justify-content: center; padding: 1rem"
>
  <p-message severity="info" text="Inactive Listing"></p-message>
</div>

<section class="carousel" [hidden]="isHidden$ | async">
  <ng-container *ngIf="pad$ | async as pad">
    <padspin-pad-expanded-carousel-two
      *ngIf="pad.images"
      [area]="pad.area"
      [beds]="pad.bedrooms"
      [baths]="pad.bathrooms"
      [price]="pad.rent_amount"
      [images]="pad.image_urls_1200"
      [videos]="pad.video_urls"
      [center]="{
        lat: $any(pad$ | async).street_view_latitude,
        lng: $any(pad$ | async).street_view_longitude
      }"
    ></padspin-pad-expanded-carousel-two>
  </ng-container>
</section>
<section
  class="details"
  [style.visibility]="(isHidden$ | async) ? 'hidden' : 'visible'"
>
  <p class="available">
    <strong>Available:</strong> {{ availablePublic$ | async | date: 'MMM dd' }}
    <br />
    <span class="h2">
      {{
        (pad$ | async)?.neighborhood
          ? (pad$ | async)?.neighborhood
          : (pad$ | async)?.place_names
          ? $any(pad$ | async).place_names[0] || ''
          : ''
      }}
    </span>
    <br />
    <small *ngIf="(pad$ | async)?.cross_street">{{
      (pad$ | async)?.cross_street
    }}</small>
  </p>
  <button padspin-button class="book" (click)="openBookingDialog()">
    <img icon src="/assets/web/icons/calendar-white.svg" alt="" />
    <span>Book a Tour</span>
  </button>
  <p class="description">
    <span [innerHTML]="(pad$ | async)?.description || '' | sanitizeHtml"></span>
  </p>
  <aside class="amenities">
    <ul>
      <li *ngIf="(pad$ | async)?.central_air">Air Conditioner</li>
      <li *ngIf="(pad$ | async)?.pets">Pets</li>
      <li *ngIf="!(pad$ | async)?.pets">No Pets</li>
      <li *ngIf="(pad$ | async)?.washer_dryer">Laundry</li>
      <li *ngIf="!(pad$ | async)?.washer_dryer">No Laundry</li>
      <li *ngIf="(pad$ | async)?.wood_floors">Hardwood Floors</li>
      <li *ngIf="(pad$ | async)?.parking">Parking</li>
      <li *ngIf="!(pad$ | async)?.parking">Street Parking</li>
      <li *ngIf="(pad$ | async)?.outdoor">Outdoor Space</li>
      <li *ngIf="(pad$ | async)?.dishwasher">Dishwasher</li>
      <li *ngIf="!(pad$ | async)?.dishwasher">No Dishwasher</li>
      <li *ngIf="(pad$ | async)?.fireplace">Fireplace</li>
      <li *ngIf="(pad$ | async)?.gym">Gym</li>
      <li *ngIf="(pad$ | async)?.elevator">Elevator</li>
      <li *ngIf="(pad$ | async)?.doorman">Doorman</li>
      <li
        *ngIf="
          !(pad$ | async)?.elevator &&
          (pad$ | async)?.floor &&
          (pad$ | async)?.state === 'NY'
        "
      >
        {{ (pad$ | async)?.floor | ordinalSuffix }} Floor Walk-Up
      </li>
      <li *ngIf="(pad$ | async)?.high_ceilings">High Ceilings</li>
    </ul>
  </aside>
  <aside
    class="map"
    *ngIf="(pad$ | async)?.latitude && (pad$ | async)?.longitude"
  >
    <google-map
      height="450px"
      width="100%"
      [center]="{
        lat: $any(pad$ | async).latitude,
        lng: $any(pad$ | async).longitude
      }"
    >
      <map-marker
        [position]="{
          lat: $any(pad$ | async).latitude,
          lng: $any(pad$ | async).longitude
        }"
        [options]="{ icon: '/assets/web/icons/location-outlined.svg' }"
      ></map-marker>
    </google-map>
  </aside>
  <div class="ctas">
    <button (click)="openBookingDialog()" padspin-button>
      <img icon src="/assets/web/icons/calendar-white.svg" />
      Book a Tour
    </button>
    <button routerLink="/listings" padspin-secondary-button>
      <img icon src="/assets/web/icons/back-1.svg" />
      Back to listings
    </button>
    <button
      (click)="toEdit()"
      padspin-secondary-button
      *ngIf="isAdmin$ | async"
    >
      <img icon src="/assets/web/icons/edit_blue_24dp.svg" />
      Edit
    </button>
  </div>
</section>
