import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HasInitialized, mixinInitialized } from '@angular/material/core';

export type PaginatorEvent = 'first' | 'next' | 'previous';

const _PaginatorMixinBase = mixinInitialized(class {});

@Component({
  selector: 'padspin-paginator',
  template: `<button padspin-secondary-button (click)="page.emit('previous')">
      Previous</button
    ><button padspin-secondary-button (click)="page.emit('next')">
      Next
    </button>`,
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent
  extends _PaginatorMixinBase
  implements OnInit, HasInitialized
{
  @Output()
  readonly page = new EventEmitter<PaginatorEvent>();

  @Input() pageIndex = 0;
  @Input() length = 0;
  @Input() pageSize = 5;

  private _initialized = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._initialized = true;
    this._markInitialized();
  }
}
