import {
  DocumentData,
  FieldValue,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from '@angular/fire/firestore';
import {
  MessageAutomationModel,
  MessageAutomationModelID,
} from '@padspin/models';

/** Angular converter for the message_sms_queue Firestore collection. */
export const angularSMSQueueConverter: FirestoreDataConverter<MessageAutomationModel> =
  {
    toFirestore: function (
      o: WithFieldValue<MessageAutomationModel>
    ): DocumentData {
      return {
        send_at:
          o['send_at'] instanceof Timestamp ||
          o['send_at'] instanceof FieldValue
            ? o['send_at']
            : o['send_at'] instanceof Date
            ? Timestamp.fromDate(o['send_at'])
            : Timestamp.now(),
        send_to: o['send_to'] || '',
        media_url: o['media_url'] || null,
        template: o['template'] || '',
        data: o['data'] || {},
      } as WithFieldValue<MessageAutomationModel>;
    },
    fromFirestore: function (
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options?: SnapshotOptions
    ): MessageAutomationModelID {
      const data: DocumentData = snapshot.data(options);
      return {
        id: snapshot.id,
        send_at:
          data['send_at'] instanceof Timestamp
            ? data['send_at']
            : Timestamp.now(),
        send_to: String(data['send_to']),
        media_url: data['media_url'] ? String(data['media_url']) : null,
        template: String(data['template']),
        data: typeof data['data'] === 'object' ? data['data'] : {},
      };
    },
  };
