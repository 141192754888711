<section class="images">
  <padspin-fab-button class="back" routerLink="/matches">
    <img icon src="/assets/web/icons/back_shadow.svg" />
  </padspin-fab-button>
  <div class="requirements" *ngIf="(selectedIndex$ | async) === 0">
    <p>Be sure you meet the requirements listed below before replying.</p>
  </div>
  <ng-container *ngIf="selection$ | async as selection">
    <div class="media" #media>
      <img
        *ngIf="selection && selection.type === 'image'"
        [src]="selection.value || ''"
        alt="Preview"
        (click)="openFullscreenCarousel(selection)"
      />
      <padspin-youtube
        *ngIf="selection && selection.type === 'video'"
        [video]="$any(selection.value) || ''"
      ></padspin-youtube>
      <aside
        *ngIf="selection && selection.type === 'street_view'"
        class="map"
        (mouseenter)="hide360()"
        (mouseleave)="show360()"
        (touchstart)="hide360()"
      >
        <div class="overlay-360" *ngIf="(isMapDirty$ | async) === false">
          <span>Tour neighborhood</span>
        </div>
        <google-map
          #streetview
          height="100%"
          tabindex="-1"
          [center]="selection.value"
          [width]="streetViewWidth$ | async"
        ></google-map>
      </aside>
    </div>

    <span class="price" *ngIf="selection && selection.type === 'image'">
      <img
        src="/assets/web/icons/dollarsign-white.svg"
        alt="$"
        style="margin-right: -12px"
      />
      {{ price }}
    </span>
    <span class="beds" *ngIf="selection && selection.type === 'image'">
      <img src="/assets/web/icons/bedroom-white.svg" alt="Beds:" />
      {{ beds }}
    </span>
    <span class="baths" *ngIf="selection && selection.type === 'image'">
      <img src="/assets/web/icons/bathroom-white.svg" alt="Baths:" />
      {{ baths }}
    </span>
  </ng-container>
</section>
<section class="thumbnails">
  <ng-container *ngFor="let media of (media$ | async) || []">
    <img
      *ngIf="media && media.type === 'image'"
      [src]="media.value"
      alt="Preview"
      (click)="slideTo(media)"
    />
    <img
      *ngIf="media && media.type === 'video'"
      src="/assets/web/icons/youtube_logo_brick.jpg"
      alt="Preview"
      (click)="slideTo(media)"
    />
    <img
      *ngIf="media && media.type === 'street_view'"
      src="/assets/web/icons/google_street_view_logo.jpg"
      alt="Preview"
      (click)="slideTo(media)"
    />
  </ng-container>
</section>
