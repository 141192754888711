import { Pipe, PipeTransform } from '@angular/core';

/**
 * Append an ordinal to a given number.
 * Optionally when shouldKeepNumber is false, result is only the ordinal.
 * @example {{ 1 | ordinalSuffix }} // 1st
 * @example {{ 2 | ordinalSuffix }} // 2nd
 * @example {{ 3 | ordinalSuffix }} // 3rd
 * @example {{ 4 | ordinalSuffix }} // 4th
 * @example {{ 5 | ordinalSuffix:false }} // th
 */
@Pipe({
  name: 'ordinalSuffix',
})
export class OrdinalSuffixPipe implements PipeTransform {
  transform(n: unknown | number, shouldKeepNumber: boolean = true): string {
    const ordinals: string[] = ['th', 'st', 'nd', 'rd'];
    const v = Number(n) % 100;
    return (
      (shouldKeepNumber ? n : '') +
      (ordinals[(v - 20) % 10] || ordinals[v] || ordinals[0])
    );
  }
}
