import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { Public, WithId } from './models';

export const conversation_model_name = 'conversation' as const;
export const conversation_messages_model_name =
  'conversation_messages' as const;

/** Conversation between Padspin support, and users */
export class ConversationImpl {
  user_uid: string | null = null;
  user_email: string | null = null;
  user_phone: string | null = null;
  user_first_name: string | null = null;
  user_last_name: string | null = null;
  support_uid: string | null = null;
  support_email: string | null = null;
  support_phone: string | null = null;
  support_first_name: string | null = null;
  support_last_name: string | null = null;
  last_message_uid: string | null = null;
  last_message_text: string | null = null;
  last_message_sent_at: Timestamp | FieldValue | null = null;
  last_message_read_at: Timestamp | FieldValue | null = null;
  constructor(conversation: Partial<ConversationImpl>) {
    Object.assign(this, conversation);
  }
}

export type ConversationModel = Public<ConversationImpl>;

/** Sub-collection of ConversationModel */
export type ConversationMessagesModel = {
  /** User's UID */
  sent_by_uid: string | null;
  /** E.164 phone number */
  sent_by_phone: string | null;
  /** User's first name (from their profile) */
  sent_by_first_name: string | null;
  /** The image media _must_ be in jpeg format */
  text: string | null;
  /** The plain-text content of the message */
  jpeg: string | null;
  /** If the sent_at is null, we need to deliver the message still. */
  sent_at: Timestamp | FieldValue | null;
  /** Time when message is read by someone other than the sender */
  read_at: Timestamp | FieldValue | null;
};

export type ConversationMessagesModelID = WithId<ConversationMessagesModel>;

export type ConversationModelID = WithId<ConversationModel>;
