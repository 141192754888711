import { FieldValue, Timestamp } from '@angular/fire/firestore';

export const rentalApplicationTreeName = 'application_tree' as const;

export type RentalApplicationTreeModel = {
  created_at: Timestamp | FieldValue;
  uid: string | null;
  type: 'tenant' | 'roommate' | 'guarantor';
  parent: string | null;
  listing: string;
  roommates: string[];
  guarantors: string[];
  description: string | null;
};
