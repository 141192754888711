<section
  class="notch"
  [class.landlord]="bg === 'landlord'"
  [class.tenant]="bg === 'tenant'"
  [class.transparent]="bg === 'transparent'"
></section>
<!-- <section
  class="announcement-bar"
  *ngIf="isAnnouncementBarVisible"
  (click)="toggleAnnouncementVisibility()"
>
  <div>
    <h4>
      Join our
      <a
        href="https://www.youtube.com/channel/UCmGNkNYa-CbH9kzEpJ5AkiA"
        target="_blank"
        >YouTube</a
      >
      and
      <a href="https://www.tiktok.com/@padspin" target="_blank">TikTok</a>
      channels to stay informed on affordable housing.
    </h4>
  </div>
  <div>
    <button>
      <img src="/assets/web/icons/announcement-bar-close.svg" />
    </button>
  </div>
</section> -->
<header
  [class.landlord]="bg === 'landlord'"
  [class.tenant]="bg === 'tenant'"
  [class.transparent]="bg === 'transparent'"
>
  <a routerLink="/" role="heading">
    <img
      *ngIf="(bg === 'transparent' || bg === 'landlord') && !isLanding"
      src="/assets/web/padspin-logo-white-green-wordmark.svg"
      alt="Padspin"
      (click)="onLogoClick()"
    />
    <img
      *ngIf="bg === ('tenant' || isTenant) && !isLanding"
      src="/assets/web/padspin-logo-white-blue-wordmark.svg"
      alt="Padspin"
    />
    <img
      *ngIf="isLanding"
      src="/assets/web/padspin-logo-green-blue-wordmark.svg"
      alt="Padspin"
    />
  </a>
  <button
    class="leasebreak-button"
    padspin-button
    routerLink="/leasebreak"
    routerLinkActive="hide"
    *ngIf="isLandingVisible$ | async"
  >
    Breaking My Lease
  </button>
  <button
    class="landlord-button"
    padspin-button
    [color]="'green'"
    routerLink="/landlord"
    routerLinkActive="hide"
    *ngIf="isLandingVisible$ | async"
    [hoverColor]="'green'"
  >
    I'm a Landlord
  </button>
  <button
    class="post-pad-button"
    padspin-button
    [color]="contrastColor"
    routerLink="/post-a-pad"
    routerLinkActive="hide"
    *ngIf="isLandlordLandingVisible$ | async"
    (click)="onPostPadClick()"
    [hoverColor]="isTenant ? 'blue' : 'green'"
  >
    Post Pad
  </button>
  <!-- <button
    padspin-button
    [color]="contrastColor"
    *ngIf="isStartMatchingVisible$ | async"
    routerLink="/criteria"
    [hoverColor]="isTenant ? 'blue' : 'green'"
    (click)="onSignUpClick()"
  >
    Start matching
  </button> -->
  <button
    padspin-tertiary-button
    *ngIf="isSavedPadsVisible$ | async"
    [hoverColor]="isTenant ? 'blue' : 'green'"
  >
    Saved Pads
  </button>
  <button
    padspin-tertiary-button
    *ngIf="isViewingsVisible$ | async"
    [hoverColor]="isTenant ? 'blue' : 'green'"
  >
    Viewings
  </button>

  <ng-container>
    <button
      padspin-fab-button
      class="profile-button desktop-only"
      [iconWidth]="24"
      [matMenuTriggerFor]="menu"
    >
      <img
        icon
        src="/assets/web/icons/perm-identity-white.svg"
        alt="Profile Menu"
      />
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngIf="(user$ | async) === null"
        (click)="showLoginDialog()"
      >
        Login / Sign Up
      </button>
      <button
        mat-menu-item
        *ngIf="(user$ | async) === null"
        routerLink="/criteria"
        (click)="onSignUpClick()"
      >
        Start Matching
      </button>
      <button mat-menu-item routerLink="/landlord">Post Pad</button>

      <button mat-menu-item *ngIf="(user$ | async) !== null" (click)="logout()">
        Logout
      </button>
      <button
        mat-menu-item
        routerLink="/dashboard/landlord"
        *ngIf="isLandlordDashboardVisible$ | async"
      >
        Landlord Dashboard
      </button>
      <button
        mat-menu-item
        routerLink="/dashboard/tenant"
        *ngIf="isTenantDashboardVisible$ | async"
      >
        Tenant Dashboard
      </button>
      <button
        mat-menu-item
        routerLink="/dashboard/admin"
        *ngIf="isAdminDashboardVisible$ | async"
      >
        Admin Dashboard
      </button>
    </mat-menu>
  </ng-container>
  <button
    padspin-fab-button
    class="mobile-only"
    [iconWidth]="34"
    (click)="toggleMenu()"
  >
    <img icon src="/assets/web/icons/menu-black.svg" alt="Menu" />
  </button>
</header>
