import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { UserCredential } from '@angular/fire/auth';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { passwordValidator } from '../validators/validators';

@Component({
  selector: 'padspin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Output()
  loginComplete = new EventEmitter<UserCredential>();

  @Input() set type(value: 'tenant' | 'landlord') {
    this.accountType = value;
  }

  @Input() email?: string = undefined;

  loginForm = this.fb.group({
    email: [this.email || '', Validators.required],
    password: ['', passwordValidator],
  });

  accountType: 'tenant' | 'landlord' = 'tenant';

  public error?: string | null;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly analytics: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['email']) {
        this.loginForm.get('email')?.setValue(params['email']);
      }
    });
  }

  async login(): Promise<void> {
    this.error = null;
    const [email, password] = [
      this.loginForm.get('email')?.value,
      this.loginForm.get('password')?.value,
    ];
    const isAnonymous = this.auth.user?.isAnonymous || false;
    try {
      const credential: UserCredential = isAnonymous
        ? await this.linkAnonymousAccountWithEmailAndPassword(email, password)
        : await this.loginWithEmailAndPassword(email, password);
      this.loginComplete.emit(credential);
      this.analytics.log('login').catch();
    } catch (error: unknown) {
      this.error = Object(error)['message'];
    }
  }

  private async linkAnonymousAccountWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    try {
      return this.auth.linkWithCredential(email, password);
    } catch (error) {
      throw new Error('Unable to link account');
    }
  }

  private async loginWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    await this.auth.signOut();
    return this.auth.signInWithEmailAndPassword(email, password);
  }
}
