import { Injectable } from '@angular/core';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  UploadResult,
} from '@angular/fire/storage';
import { UuidService } from './uuid.service';

@Injectable({ providedIn: 'root' })
export class ListingImageService {
  constructor(private readonly uuid: UuidService) {}

  uploadListingImage = async (
    file: File,
    listingId: string,
    imageId: string = this.uuid.v6(),
    storage = getStorage()
  ): Promise<UploadResult> => {
    const fileRef = ref(storage, `listings/${listingId}/${imageId}`);
    return await uploadBytes(fileRef, file);
  };

  uploadAndGetDownloadURL = async (
    file: File,
    listingId: string,
    imageId: string = this.uuid.v6()
  ): Promise<{ uploadResult: UploadResult; downloadURL: string }> => {
    const uploadResult = await this.uploadListingImage(
      file,
      listingId,
      imageId
    );
    const downloadURL = await getDownloadURL(uploadResult.ref);
    return { uploadResult, downloadURL };
  };
}
