import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav.component';
import { ButtonModule } from '@padspin/ui';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SideNavComponent],
  exports: [SideNavComponent],
  imports: [CommonModule, ButtonModule, RouterModule],
})
export class SideNavModule {}
