import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'padspin-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  #id = '';
  @Input() set uid(uid: string) {
    this.#id = uid;
    this.bgColor = this.bgColorFromUID(uid);
  }
  get uid(): string {
    return this.#id;
  }
  @Input() first_name?: string = '';
  @Input() last_name?: string = '';

  @HostBinding('style.backgroundColor')
  bgColor = 'hsl(0, 100%, 39.8%)';

  get initials(): string {
    return `${this.first_name ? this.first_name[0] : ''}${
      this.last_name ? this.last_name[0] : ''
    }`;
  }

  private numberFromText = (text: string) =>
    parseInt(
      text
        .split('')
        .map((c) => c.charCodeAt(0))
        .join(''),
      10
    );
  private hueFromText = (text: string) => this.numberFromText(text) % 255;
  private bgColorFromUID = (uid: string) =>
    `hsl(${this.hueFromText(uid)}, 100%, 39.8%)`;
}
