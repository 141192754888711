import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { ListingContact } from '@padspin/models';

@Injectable({
  providedIn: 'any',
})
export class DefaultContactFormValidator {
  doesDefaultContactSelectionMatchContact(
    formWithDefaultContact: FormGroup
  ): AsyncValidatorFn {
    return async (form: AbstractControl): Promise<ValidationErrors | null> => {
      const defaultContactControl =
        formWithDefaultContact.get('default_contact');
      if (!defaultContactControl) {
        throw new Error(
          'Invalid form provided does not have a default_contact control'
        );
      }
      // dirty hack to ensure we are comparing the current values, not the previous value
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 20));
      const typeControl = form.get('default');
      if (!typeControl) {
        return { typeControlRequired: true };
      }
      const type: string | null = typeControl.value;
      if (!type) {
        return { typeRequired: true };
      }
      const defaultContact: ListingContact | null = defaultContactControl.value;
      if (defaultContact === null) {
        return { defaultContactIsRequired: true };
      }
      if (type !== defaultContact.type) {
        return { contactDoesNotMatchSelectedType: true };
      }
      return null;
    };
  }
}
