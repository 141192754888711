<button padspin-fab-button (click)="close()" *ngIf="!isClosable">
  <img icon src="/assets/web/icons/close.svg" alt="Close." />
</button>
<form [formGroup]="form">
  <label
    class="register"
    [class.selected]="form.get('loginOrRegister')?.value === 'register'"
  >
    <span>Sign Up</span>
    <input
      type="radio"
      formControlName="loginOrRegister"
      value="register"
      required
    />
  </label>

  <label
    class="login"
    [class.selected]="form.get('loginOrRegister')?.value === 'login'"
  >
    <span>Login</span>
    <input
      type="radio"
      formControlName="loginOrRegister"
      value="login"
      required
    />
  </label>
</form>

<padspin-login
  *ngIf="form.get('loginOrRegister')?.value === 'login'"
  [type]="(isTenant$ | async) ? 'tenant' : 'landlord'"
  (loginComplete)="complete($event)"
  [email]="input.email"
></padspin-login>
<padspin-register
  *ngIf="form.get('loginOrRegister')?.value === 'register'"
  [type]="(isTenant$ | async) ? 'tenant' : 'landlord'"
  (registrationComplete)="complete($event)"
  [email]="input.email"
  [phone]="input.phone"
></padspin-register>
