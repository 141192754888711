import { Component, Input, OnDestroy } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'padspin-onboarding-stepper',
  templateUrl: './onboarding-stepper.component.html',
  styleUrls: ['./onboarding-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: OnboardingStepperComponent }],
})
export class OnboardingStepperComponent
  extends CdkStepper
  implements OnDestroy
{
  #reset$ = new BehaviorSubject(1);
  #timer$: Observable<number> = this.#reset$.pipe(
    switchMap(() => timer(0, 2000))
  );
  #destroy$ = new Subject<void>();
  #subscription$: Subscription = this.#timer$
    .pipe(takeUntil(this.#destroy$))
    .subscribe((_times) => {
      // Iterate through the carousel
      if (this.selectedIndex + 1 >= this.steps.length) {
        this.reset();
        this.selectedIndex = 0;
      } else {
        this.next();
      }
    });

  @Input() type: 'landlord' | 'tenant' = 'tenant';

  selectByIndex(index: number): void {
    this.selectedIndex = index;
  }

  override ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
    super.ngOnDestroy();
  }
}
