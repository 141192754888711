import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { getGeohashBounds, LatLngBounds } from '@padspin/mapping';
import { NeighborhoodImpl, NeighborhoodModel } from './neighborhood.model';
import { WithId } from './types/with-id';

export const tenantCriteriaName = 'tenant_criteria';

export type TenantCriteriaModelID = WithId<TenantCriteriaModel>;

export type TenantCriteriaModel = {
  active?: boolean | null;
  credit_score?: number | null;
  annual_income?: number | null;
  earliest_move_in_date?: Timestamp | null;
  funds_available?: number | null;
  guarantor?: boolean | null;
  locations?: Array<NeighborhoodModel>;
  bounds?: Array<string> | null;
  bounding_geohash?: string | null;
  bounding_geohash_2?: string | null;
  max_rent?: number | null;
  bedrooms?: number[];
  updated?: Timestamp | FieldValue | null;
};

export class TenantCriteriaImpl implements TenantCriteriaModel {
  active: boolean | null;
  credit_score: number | null;
  annual_income: number | null;
  earliest_move_in_date: Timestamp | null;
  funds_available: number | null;
  guarantor: boolean | null;
  #locations: NeighborhoodImpl[] = [];
  get locations() {
    return this.#locations;
  }

  set locations(maybeLocations: NeighborhoodImpl[]) {
    this.#locations = maybeLocations
      ? maybeLocations?.map((model) => new NeighborhoodImpl(model))
      : [];
  }

  get bounds() {
    return this.locations
      ? this.locations.map((location) => location.bounding_geohash)
      : [];
  }

  /** Geohash that encompasses all the locations' bounds. */
  get bounding_geohash(): string {
    const locations = this.locations || [];
    if (locations.length === 0) {
      return '';
    }
    const bounds: LatLngBounds = {
      northeast: {
        lat: Math.max(...locations.map((loc) => loc.bounds_ne_lat)),
        lng: Math.max(...locations.map((loc) => loc.bounds_ne_lng)),
      },
      southwest: {
        lat: Math.min(...locations.map((loc) => loc.bounds_sw_lat)),
        lng: Math.min(...locations.map((loc) => loc.bounds_sw_lng)),
      },
    };
    return getGeohashBounds(bounds);
  }

  get bounding_geohash_2(): string {
    return this.bounding_geohash.slice(0, 2);
  }

  max_rent?: number | undefined;
  bedrooms?: number[] = [];
  updated?: Timestamp | FieldValue | undefined;

  toJSON(): TenantCriteriaModel {
    const m: TenantCriteriaModel = {
      active: this.active,
      credit_score: this.credit_score,
      annual_income: this.annual_income,
      earliest_move_in_date: this.earliest_move_in_date,
      funds_available: this.funds_available,
      guarantor: this.guarantor,
      locations: this.locations?.map((loc) => loc.toJson()),
      bounds: this.bounds,
      bounding_geohash: this.bounding_geohash,
      bounding_geohash_2: this.bounding_geohash_2,
      max_rent: this.max_rent,
      bedrooms: this.bedrooms,
      updated: this.updated || null,
    };
    return m;
  }

  constructor(criteria: Partial<TenantCriteriaModel> = {}) {
    this.active = criteria.active || false;
    this.credit_score =
      typeof criteria.credit_score !== 'undefined'
        ? Number(criteria.credit_score) || 0
        : null;
    this.annual_income =
      typeof criteria.annual_income !== 'undefined'
        ? Number(criteria.annual_income) || 0
        : null;
    this.earliest_move_in_date = criteria.earliest_move_in_date || null;
    this.funds_available =
      typeof criteria.funds_available !== 'undefined'
        ? Number(criteria.funds_available) || 0
        : null;
    this.guarantor =
      typeof criteria.guarantor !== 'undefined' ? criteria.guarantor : null;
    this.locations = criteria.locations
      ? criteria.locations?.map((loc) => new NeighborhoodImpl(loc))
      : [];
    this.max_rent = Number(criteria.max_rent);
    this.bedrooms = criteria.bedrooms
      ? criteria.bedrooms?.map((b) => Number(b))
      : [];
    this.updated = criteria.updated || undefined;
  }
}
