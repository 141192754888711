import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminPhoneComponent } from './admin-phone.component';
import { ButtonModule } from '@padspin/ui';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [AdminPhoneComponent],
  exports: [AdminPhoneComponent],
  imports: [CommonModule, ButtonModule, MatMenuModule],
})
export class AdminPhoneModule {}
