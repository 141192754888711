import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AfterBookViewingComponent } from './after-book-viewing.component';
import { ButtonModule } from '@padspin/ui';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [AfterBookViewingComponent],
  imports: [CommonModule, ButtonModule, RouterModule, MatDialogModule],
  exports: [AfterBookViewingComponent],
})
export class AfterBookViewingModule {}
