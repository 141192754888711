import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LatLngLiteral } from './lat-lng-literal';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { LatLngArray } from './lat-lng-array';
import { google } from 'google-maps';
import { GeoPoint } from './geopoint';
import { UserLocationService } from '../user-location.service';
import { isPlatformBrowser } from '@angular/common';
import { MapLoadingService } from '../map-loading.service';
import { map } from 'rxjs/operators';
import { TenantCriteriaService } from '../tenant-criteria.service';
import { TenantCriteriaModel } from '@padspin/models';
import { MapUtilService } from '../map-util.service';

export enum CoordTypes {
  GeoPoint,
  LatLngLiteral,
  LatLng,
  LatLngArray,
}

/** This service is responsible for the search-page's map state */
@Injectable({
  providedIn: 'root',
})
export class MapService {
  /** Center of the map */
  private center = new BehaviorSubject<LatLngLiteral>({
    lat: 40.74375,
    lng: -73.91296,
  });
  center$: Observable<LatLngLiteral> = this.center.asObservable();

  #bounds$ = new ReplaySubject<google.maps.LatLngBounds>(1);
  #previous = new BehaviorSubject<LatLngLiteral | null>(null);
  bounds$ = this.#bounds$.asObservable();

  moved$ = combineLatest([this.center$, this.bounds$]).pipe(
    map(([center, bounds]) => ({ center, bounds }))
  );

  /** How many meters from the center of the map to the corner? */
  radiusInM = new BehaviorSubject<number>(0);

  constructor(
    private readonly userLocationSvc: UserLocationService,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    private readonly mapLoadingSvc: MapLoadingService,
    private readonly criteriaSvc: TenantCriteriaService,
    private readonly mapUtilSvc: MapUtilService
  ) {
    this.centerOfMap$().subscribe();
  }

  /** @deprecated See center$ */
  centerOfMap$(): Observable<LatLngLiteral> {
    return this.center.pipe();
  }

  set centerOfMap(location: LatLngLiteral) {
    if (
      location.lat !== this.center.value.lat &&
      location.lng !== this.center.value.lng
    ) {
      this.center.next(location);
    }
  }

  get centerOfMap(): LatLngLiteral {
    return this.center.value;
  }

  setBounds(bounds: google.maps.LatLngBounds): void {
    this.#bounds$.next(bounds);
  }

  set previous(location: LatLngLiteral | null) {
    this.#previous.next(location);
  }
  get previous() {
    return this.#previous.value;
  }

  async moveMapToInitialLocation(): Promise<void> {
    const maybeCriteria = await firstValueFrom(this.criteriaSvc.criteria$);
    if (maybeCriteria !== null) {
      return this.moveMapBasedOnUserCriteria(maybeCriteria);
    } else {
      return this.moveMapToUserLocation();
    }
  }

  /** Move the center of the map to the user's location if possible */
  async moveMapToUserLocation(): Promise<void> {
    const location = await firstValueFrom(this.userLocationSvc.location);
    if (location) {
      this.centerOfMap = location;
      return;
    }
    if (isPlatformBrowser(this.platformId) && !!navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (p) => {
          this.centerOfMap = {
            lat: p.coords.latitude,
            lng: p.coords.longitude,
          };
        },
        () => console.log('location blocked by user')
      );
    }
  }

  /** Move the map to the place that the user wants to rent */
  async moveMapBasedOnUserCriteria(
    criteria: TenantCriteriaModel | null
  ): Promise<void> {
    if (!criteria) {
      return;
    }
    const coord = this.criteriaSvc.averageLocationFromCriteria(criteria);
    if (!coord) {
      return;
    }
    this.centerOfMap = coord;
  }

  /** @deprecated {@link MapUtilService#isLatLngLiteral} */
  isLatLngLiteral(
    coord: LatLngLiteral | google.maps.LatLng | LatLngArray | GeoPoint
  ): coord is LatLngLiteral {
    return this.mapUtilSvc.isLatLngLiteral(coord);
  }

  /** @deprecated {@link MapUtilService#isLatLng} */
  isLatLng(
    latlng: LatLngLiteral | google.maps.LatLng | LatLngArray | GeoPoint
  ): latlng is google.maps.LatLng {
    return this.mapUtilSvc.isLatLng(latlng);
  }

  /** @deprecated {@link MapUtilService#isLatLngArray} */
  isLatLngArray(
    latlng: LatLngLiteral | google.maps.LatLng | LatLngArray
  ): latlng is LatLngArray {
    return this.mapUtilSvc.isLatLngArray(latlng);
  }

  /** @deprecated {@link MapUtilService#isGeoPoint} */
  isGeoPoint(
    coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray
  ): coord is GeoPoint {
    return this.mapUtilSvc.isGeoPoint(coord);
  }

  /** @deprecated {@link MapUtilService#typeOfCoord} */
  typeOfCoord(
    coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray
  ): CoordTypes {
    return this.mapUtilSvc.typeOfCoord(coord);
  }

  /** @deprecated {@link MapUtilService#convertCoordToLatLngArray} */
  convertCoordToLatLngArray(
    coord: LatLngLiteral | LatLngArray | google.maps.LatLng | GeoPoint
  ): LatLngArray {
    return this.mapUtilSvc.convertCoordToLatLngArray(coord);
  }

  /** @deprecated {@link MapUtilService#convertCoordToLatLng} */
  convertCoordToLatLng(
    coord: LatLngLiteral | LatLngArray | google.maps.LatLng | GeoPoint
  ): google.maps.LatLng {
    return this.mapUtilSvc.convertCoordToLatLng(coord);
  }

  /** @deprecated {@link MapUtilService#convertCoordToLatLngLiteral} */
  convertCoordToLatLngLiteral(
    coord:
      | LatLngLiteral
      | LatLngArray
      | google.maps.LatLng
      | GeoPoint
      | undefined
  ): LatLngLiteral {
    return this.mapUtilSvc.convertCoordToLatLngLiteral(coord);
  }

  /** @deprecated {@link MapUtilService#convertCoordToGeoPoint} */
  convertCoordToGeoPoint(
    coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray
  ): GeoPoint {
    return this.mapUtilSvc.convertCoordToGeoPoint(coord);
  }
}
