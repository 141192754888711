import { Injectable } from '@angular/core';
import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { parseISO } from 'date-fns';

export type TimestampLiterial = Pick<Timestamp, 'seconds' | 'nanoseconds'>;

@Injectable({
  providedIn: 'root',
})
export class TimestampService {
  isTimestampImpl(
    value:
      | object
      | null
      | Timestamp
      | FieldValue
      | Date
      | number
      | string
      | unknown
  ): value is Timestamp {
    const isTimestampLiteral = this.isTimestampLiteral(value);
    const hasToDate = Object.prototype.hasOwnProperty.call(value, 'toDate');
    return isTimestampLiteral && hasToDate;
  }

  isTimestampLiteral(
    value:
      | object
      | null
      | Timestamp
      | FieldValue
      | Date
      | number
      | string
      | unknown
  ): value is TimestampLiterial {
    if (value instanceof Date) {
      return false;
    } else if (typeof value === 'number') {
      return false;
    } else if (typeof value === 'string') {
      return false;
    } else if (value && Object(value)?.seconds && Object(value)?.nanoseconds) {
      return true;
    } else if (!value) {
      return false;
    }
    const hasSeconds = Object.prototype.hasOwnProperty.call(value, 'seconds');
    const hasNanoseconds = Object.prototype.hasOwnProperty.call(
      value,
      'nanoseconds'
    );
    return hasSeconds && hasNanoseconds;
  }

  toTimestamp(
    date: Date | string | number | Timestamp | TimestampLiterial
  ): Timestamp {
    if (date instanceof Date) {
      return Timestamp.fromDate(date);
    } else if (typeof date === 'string') {
      return Timestamp.fromDate(parseISO(date));
    } else if (this.isTimestampLiteral(date)) {
      return Timestamp.fromDate(
        new Date(date.seconds * 1000 + (1 / 1_000_000_000) * date.nanoseconds)
      );
    } else if (this.isTimestampImpl(date)) {
      return date;
    } else {
      return Timestamp.fromDate(new Date(date));
    }
  }

  toDate(
    timestamp:
      | TimestampLiterial
      | Timestamp
      | FieldValue
      | string
      | undefined
      | Pick<Timestamp, 'seconds' | 'nanoseconds'>
  ): Date {
    if (!timestamp) {
      throw new Error(`Cannot convert falsy value to Timestamp`);
    } else if (timestamp instanceof Timestamp) {
      return timestamp.toDate();
    } else if (this.isTimestampImpl(timestamp)) {
      return timestamp.toDate();
    } else if (this.isTimestampLiteral(timestamp)) {
      return this.toTimestamp(timestamp).toDate();
    } else if (typeof timestamp === 'string') {
      return parseISO(timestamp);
    } else if (Object(timestamp)._methodName === 'serverTimestamp') {
      return new Date();
    } else if (timestamp instanceof FieldValue) {
      console.log({ timestamp });
      throw new Error(`Cannot convert FieldValue to Date reliably.`);
    }
    throw new Error('Unable to convert Timestamp to Date');
  }
}
