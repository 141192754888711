import {
  Component,
  ChangeDetectorRef,
  Inject,
  ViewChild,
  ViewChildren,
  ElementRef,
  QueryList,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListingsModel } from '@padspin/models';
import { HttpClient } from '@angular/common/http';
import { FileZipService } from '../file-zip.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'padspin-download-images-modal',
  templateUrl: './download-images-modal.component.html',
  styleUrls: ['./download-images-modal.component.scss'],
})
export class DownloadImagesModalComponent {
  @ViewChild('canvas', { read: ElementRef })
  canvasRef?: ElementRef<HTMLCanvasElement>;
  @ViewChild('svg', { read: ElementRef })
  svgRef?: ElementRef<HTMLElement>;
  @ViewChild('img', { read: ElementRef })
  imgRef?: ElementRef<HTMLImageElement>;
  @ViewChildren('image_urls_1200')
  image_urls_1200!: QueryList<ElementRef<HTMLImageElement>>;
  @ViewChildren('image_urls_1200_canvas_1')
  image_urls_1200_canvases_1!: QueryList<ElementRef<HTMLCanvasElement>>;
  @ViewChildren('image_urls_1200_canvas_2')
  image_urls_1200_canvases_2!: QueryList<ElementRef<HTMLCanvasElement>>;

  loading$ = new BehaviorSubject<boolean>(false);

  private readonly defaultWatermark =
    'Be sure you meet the requirements listed below before replying.' as const;

  /** Indexed by image number */
  dynamicForm: FormGroup = new FormGroup(
    this.data.listing.image_urls_1200.reduce((acc, cur, index) => {
      Object(acc)[index] = new FormControl(
        index === 0 ? this.defaultWatermark : '',
        Validators.required
      );
      return acc;
    }, {})
  );

  constructor(
    private readonly dialogRef: MatDialogRef<DownloadImagesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { listing: ListingsModel },
    private readonly http: HttpClient,
    private readonly fzs: FileZipService,
    private readonly msbs: MatSnackBar,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (!data.listing) {
      throw new Error('Missing listing');
    }
  }

  async downloadImagesLoadingIndicator() {
    this.loading$.next(true);
    this.cdr.detectChanges();
    try {
      await this.downloadImages();
    } finally {
      this.loading$.next(false);
      this.cdr.detectChanges();
      this.dialogRef.close();
    }
  }

  async downloadImages(
    listings: Pick<ListingsModel, 'image_urls_1200' | 'slug'> = this.data
      .listing
  ): Promise<void> {
    const imagesElements: Array<HTMLImageElement> = this.image_urls_1200.map(
      (elementRef: ElementRef<HTMLImageElement>) => elementRef.nativeElement
    );
    const canvasElementsInital: Array<HTMLCanvasElement> =
      this.image_urls_1200_canvases_1.map(
        (elementRef: ElementRef<HTMLCanvasElement>) => elementRef.nativeElement
      );
    const canvasElementsWatermark: Array<HTMLCanvasElement> =
      this.image_urls_1200_canvases_2.map(
        (elementRef: ElementRef<HTMLCanvasElement>) => elementRef.nativeElement
      );
    const canvas = this.canvasRef?.nativeElement;
    if (!canvas) {
      throw new Error('Canvas not found');
    }
    const jpegs: Blob[] = [];
    let i = 0;
    for (const imageElement of imagesElements) {
      const ctx = canvasElementsInital[i].getContext('2d');
      if (!ctx) {
        throw new Error('Rendering Context not found');
      }
      const maxImgWidth = 1200;
      const maxImgHeight = 1200;
      const image = new Image(
        imageElement.naturalWidth,
        imageElement.naturalHeight
      );
      imageElement.crossOrigin = 'anonymous';
      image.crossOrigin = 'anonymous';
      await new Promise<void>((resolve, reject) => {
        image.onload = () => resolve();
        image.onerror = () => reject();
        image.src = imageElement.src;
      });
      if (
        imageElement.naturalWidth > maxImgWidth &&
        imageElement.naturalHeight > maxImgHeight
      ) {
        canvasElementsInital[i].width = maxImgWidth;
        canvasElementsInital[i].height = maxImgHeight;
        ctx.drawImage(image, 0, 0, maxImgWidth, maxImgHeight);
      } else if (imageElement.naturalWidth > maxImgWidth) {
        canvasElementsInital[i].width = maxImgWidth;
        canvasElementsInital[i].height = imageElement.naturalHeight;
        ctx.drawImage(image, 0, 0, maxImgWidth, imageElement.naturalHeight);
      } else if (imageElement.naturalHeight > maxImgHeight) {
        canvasElementsInital[i].height = maxImgHeight;
        canvasElementsInital[i].width = imageElement.naturalWidth;
        ctx.drawImage(image, 0, 0, imageElement.naturalWidth, maxImgHeight);
      } else {
        canvasElementsInital[i].width = imageElement.naturalWidth;
        canvasElementsInital[i].height = imageElement.naturalHeight;
        ctx.drawImage(image, 0, 0);
      }
      const jpeg = await new Promise<Blob>((resolve, reject) => {
        canvasElementsInital[i].toBlob(
          (blob: Blob | null) => (blob ? resolve(blob) : reject()),
          'image/jpeg',
          1.0
        );
      });
      const watermark = this.dynamicForm.get(String(i))?.value || null;
      const watermarkCanvas = canvasElementsWatermark[i];
      const finalBlob = canvasElementsWatermark
        ? await this.overlayTextOnJpeg(jpeg, watermarkCanvas, watermark)
        : jpeg;
      jpegs.push(finalBlob);
      i++;
    }
    const zip: Blob = await this.fzs.zipFilesFromBlobs(
      jpegs.map((blob, i) => ({ blob, name: `${i}.jpeg` }))
    );
    await this.fzs.downloadZipFileToClient(zip, listings.slug);
  }

  async overlayTextOnJpeg(
    jpeg: Blob,
    canvas: HTMLCanvasElement,
    watermark: string = ''
  ): Promise<Blob> {
    // Paint image onto canvas
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return jpeg;
    }
    if (!watermark) {
      return jpeg;
    }
    ctx.fillStyle = 'red';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const bgURL = URL.createObjectURL(jpeg);
    const bgImage: HTMLImageElement = await new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = bgURL;
    });
    canvas.width = bgImage.width;
    canvas.height = bgImage.height;
    ctx.drawImage(bgImage, 0, 0);
    // Overlay SVG on top of image
    const svgSource2 = `<svg width="${bgImage.width}px" height="${bgImage.height}px" xmlns="http://www.w3.org/2000/svg">
      <foreignObject height="100%" width="${bgImage.width}px" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility">
          <div style="display: flex; flex-direction: column; align-items: center;justify-content: center; height: 100%;" xmlns="http://www.w3.org/1999/xhtml">
            <div style="max-width: 80%;" xmlns="http://www.w3.org/1999/xhtml">
              <p style="background: rgba(0,0,0,0.5);border-radius: 6px;padding: 10px; text-align: center;max-width: 99.9%;" xmlns="http://www.w3.org/1999/xhtml">
                  <span style="color: white;font-family: 'Fira Sans', sans-serif; font-size: 26px; line-height: 34px;font-weight: 500;" xmlns="http://www.w3.org/1999/xhtml">
                    <span xmlns="http://www.w3.org/1999/xhtml">${watermark}</span>
                  </span>
              </p>
            </div>
          </div>
        </foreignObject>
    </svg>`;
    const svgBlob = new Blob([svgSource2], { type: 'image/svg+xml' });
    // URL.createObjectURL(svgBlob) causes an error, see: https://stackoverflow.com/a/50857516
    const reader = new FileReader();
    reader.readAsDataURL(svgBlob);
    const svgURL: string = await new Promise<string>((resolve, reject) => {
      reader.onload = (e) => {
        typeof e.target?.result === 'string'
          ? resolve(e.target?.result)
          : reject();
      };
    });
    const svgImg = await new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image(canvas.width, canvas.height);
      img.width = canvas.width;
      img.height = canvas.height;
      img.crossOrigin = '';
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = svgURL;
    });
    svgImg.crossOrigin = 'anonymous';

    let jpegBlob: Blob | undefined;
    if (watermark) {
      ctx.drawImage(svgImg, 0, 0, canvas.width, canvas.height);
      // Save jpeg from canvas as jpeg blob
      jpegBlob = await new Promise<Blob>((resolve, _reject) => {
        canvas.toBlob(
          (blob) => {
            const img = this.imgRef?.nativeElement;
            if (!img || !blob) {
              return;
            }
            const url: string = URL.createObjectURL(blob);
            img.src = url;
            resolve(blob);
          },
          'image/jpeg',
          1.0
        );
      });
    }
    return jpegBlob ? jpegBlob : jpeg;
  }

  async close(): Promise<void> {
    this.dialogRef.close();
  }
}
