import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, Observable, of, shareReplay } from 'rxjs';
import { environment } from '../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MapLoadingService {
  isMapLoaded$: Observable<boolean> = this.http
    .jsonp(
      `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=geometry,places`,
      'callback'
    )
    .pipe(
      tap((_) => 'pipe!'),
      map(() => true),
      catchError(() => of(false)),
      shareReplay({ bufferSize: 1, refCount: false }),
      delay(1) // drain microtasks (so map component can load)
    );
  constructor(private readonly http: HttpClient) {
    this.isMapLoaded$.subscribe();
  }
}
