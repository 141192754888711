import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'padspin-after-book-viewing',
  templateUrl: './after-book-viewing.component.html',
  styleUrls: ['./after-book-viewing.component.scss'],
})
export class AfterBookViewingComponent {
  constructor(
    @Optional()
    private readonly dialogRef: MatDialogRef<AfterBookViewingComponent>,
    private readonly router: Router
  ) {}

  close(): void {
    const url = this.router.url;
    if (!url.includes('move')) {
      this.router.navigate(['/move']);
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
