import { FieldValue, Timestamp } from 'firebase/firestore';
import { ListingsModelID } from './listings.model';
import { TenantCriteriaModelID } from './tenant-criteria.model';

export const matches_name = 'match_criteria_with_listings' as const;

/**
 * Keyed by matching_id, composed of `${uid}-${listing_id}`.
 */
export type MatchCriteriaWithListingsModel = {
  user_id: string;
  user_name: string;
  listing_id: string;
  listing_slug: string;
  listing_title: string;
  landlord_id: string;
  created_at: Timestamp | FieldValue | null;
  updated_at: Timestamp | FieldValue;
  match_notification_sent_at: Timestamp | FieldValue | null;
};

export const get_match_id = (
  criteria: Pick<TenantCriteriaModelID, 'id'>,
  listing: Pick<ListingsModelID, 'id'>
): string => {
  if (!criteria.id) {
    throw new Error('Criteria must have an ID');
  } else if (!listing.id) {
    throw new Error('Listing must have an ID');
  }
  return `${criteria.id}-${listing.id}`;
};
