import { WithId } from './types/with-id';

export const userNoteName = 'user_notes' as const;

export interface UserNoteInterface {
  note: string;
}

export class UserNote implements UserNoteInterface {
  note!: string;
  constructor(userNote: UserNoteInterface) {
    Object.assign(this, userNote);
  }
}

export type UserNoteID = WithId<UserNote>;
