import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { WithId } from './types/with-id';

export const messageAutomationName = 'message_sms_queue';

export type MessageAutomationModelID = WithId<MessageAutomationModel>;

/**
 * Queued Notifications (email & sms) to send to leads. A cron job
 * `cron_notify_leads` periodically checks the `lead_notify_queue`
 * collection and sends any notifications with a date in the past.
 *
 * Deletion:
 * - Queue items are deleted them after being sent successfully.
 * - Items in the collection should be deleted if the messages are repeatedly
 * failing to send (eg: the `send_date_utc` is a few days in the past).
 * - Queue items should still be deleted if one method of notification is
 * successful (eg: email sends, but SMS fails).
 */
export type MessageAutomationModel = {
  /** Date to send the email / SMS notification */
  send_at: Timestamp | FieldValue;
  /** Phone number */
  send_to: string;
  /** Optional Image URL */
  media_url: string | null;
  /** @example "Hello, checkout this pad\n{{href}}\n\nPadspin" */
  template: string;
  /** Data to provide the template. Hint: user `replace_handlebars()` */
  data: Record<string, string | number> | Record<string, never>;
};
