import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Zip from 'jszip';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileZipService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    private readonly http: HttpClient
  ) {}

  async downloadFilesFromURLs(urls: string[] = []): Promise<Blob[]> {
    const promises: Array<Promise<Blob>> = [];
    urls.forEach((url) =>
      promises.push(
        firstValueFrom(this.http.get(url, { responseType: 'blob' }))
      )
    );
    return Promise.all(promises);
  }

  async downloadImagesFromURLs(urls: string[] = []): Promise<Blob[]> {
    const blobs = await this.downloadFilesFromURLs(urls);
    const maybeJpegs = await Promise.all(
      blobs.map(async (b) => this.convertFileToJpeg(b))
    );
    const jpegs: Blob[] = maybeJpegs.filter(
      (maybeJpeg): maybeJpeg is File => !!maybeJpeg
    );
    return jpegs;
  }

  private async convertFileToJpeg(file: Blob | null): Promise<File | null> {
    if (!file) {
      return null;
    }
    // Prepare the canvas
    if (!isPlatformBrowser(this.platformId)) {
      return null;
    }
    const canvas = this.document.createElement('canvas');
    if (!canvas) {
      return null;
    }
    const context = canvas.getContext('2d');
    if (!context) {
      return null;
    }
    const url = URL.createObjectURL(file);
    // Draw the image onto the canvas
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, canvas.width, canvas.height);
    await new Promise<HTMLImageElement>((resolve) => {
      const i = new Image();
      i.onload = () => {
        canvas.width = i.width;
        canvas.height = i.height;
        context.drawImage(i, 0, 0);
        resolve(i);
      };
      i.src = url;
    });
    // Convert the image to jpeg format
    const blob = await new Promise<Blob | null>((resolve) =>
      canvas.toBlob((blob) => resolve(blob || null), 'image/jpeg')
    );
    if (!blob) {
      return null;
    }
    return new File([blob], 'image.jpeg', { type: 'image/jpeg' });
  }

  async downloadImagesAsZip(
    files: Array<{ blob: Blob; name: string }>
  ): Promise<void> {
    const zipBlob = await this.zipFilesFromBlobs(files);
    await this.downloadZipFileToClient(zipBlob);
  }

  async zipFilesFromBlobs(
    files: { blob: Blob; name: string }[] = []
  ): Promise<Blob> {
    const zip = new Zip();
    // Add files to zip
    for (const file of files) {
      zip.file(file.name, file.blob, { compression: 'DEFLATE' });
    }
    return zip.generateAsync({ type: 'blob' });
  }

  async downloadZipFileToClient(
    blobobject: Blob,
    fileName = 'images'
  ): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const window = this.document.defaultView;
    if (!window) {
      return;
    }
    const blob = URL.createObjectURL(blobobject);
    const anchor = this.document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = blob;
    anchor.download = `${fileName}.zip`;
    this.document.body.append(anchor);
    anchor.click();
    URL.revokeObjectURL(blob);
  }
}
