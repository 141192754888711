import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LatLngArray } from './lat-lng-array';
import { ElasticSearchResponseDTO, Hit } from './elastic-search-response-dto';
import { Listing } from './listing';
import { neighbourhoods } from './neighborhoods';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MapService } from './map.service';
import { GeoPoint } from './geopoint';

/** @example https://nyc.tidalforce.org/padspinfast */
@Injectable({
  providedIn: 'root',
})
export class ElasticSearchService {
  constructor(
    private readonly http: HttpClient,
    private readonly mapSvc: MapService
  ) {}

  /**
   * Retrieve listings from elasticsearch
   * @param location Location
   */
  searchByLocation(
    location: LatLngArray
  ): Observable<ElasticSearchResponseDTO> {
    const url = environment.elasticsearch;
    // The elasticsearch server stores latitude and longitude BACKWARDS [lng,lat]
    const geoquery = `${location[1]},${location[0]}`;
    const body = {
      name: geoquery,
      from: 0,
      operator: 'and',
      searchtype: 'geoquery_string',
    };
    const headers = new HttpHeaders({
      accept: 'application/json, text/javascript, */*; q=0.01',
      'content-type': 'application/json; charset=UTF-8',
      'accept-language': 'en-US,en;q=0.9,it-IT;q=0.8,it;q=0.7',
    });
    return this.http
      .post<ElasticSearchResponseDTO>(url, body, { headers })
      .pipe(
        catchError((error: unknown, caught) => {
          return caught;
        })
      );
  }

  searchByListingId(id: string): Observable<ElasticSearchResponseDTO> {
    const url = environment.elasticsearch;
    // The elasticsearch server stores latitude and longitude BACKWARDS [lng,lat]
    const body = {
      name: id,
      from: 0,
      operator: 'and',
      searchtype: 'idquery_string',
    };
    const headers = new HttpHeaders({
      accept: 'application/json, text/javascript, */*; q=0.01',
      'content-type': 'application/json; charset=UTF-8',
      'accept-language': 'en-US,en;q=0.9,it-IT;q=0.8,it;q=0.7',
    });
    return this.http
      .post<ElasticSearchResponseDTO>(url, body, { headers })
      .pipe(
        catchError((error: unknown, caught) => {
          console.warn('ess.service#caught an error...');
          return caught;
        })
      );
  }

  /**
   * Convert the DTO into a Listings array of pads.
   * @param dto ElasticSearch DTO (Data Transfer Object)
   */
  convertESDTOToListings(dto: ElasticSearchResponseDTO): Listing[] {
    const hits: Hit[] = dto?.body?.hits?.hits;
    if (!hits) {
      throw new Error('unexpected elasticsearch dto');
    }
    return hits.map((hit: Hit) => {
      return this.convertHitToListing(hit);
    });
  }

  convertHitToListing(hit: Hit): Listing {
    const geopoint = new GeoPoint(
      Number(hit._source.latitude),
      Number(hit._source.longitude)
    );
    const images = hit._source.image_list; // "['a.jpg', 'b.jpg', 'c.jpg']"
    // See ListingModel in lib/models.ts for possible properties that we can add here
    return {
      geohash: '',
      location: this.mapSvc.convertCoordToLatLngLiteral(geopoint),
      address: hit._source.address,
      area: this.getNeighborhoodNameById(
        Number(hit._source.neighborhood_id) as keyof typeof neighbourhoods
      ),
      price: Number(hit._source.rent_amount),
      beds: Number(hit._source.bedrooms),
      baths: Number(hit._source.bathrooms),
      id: hit._source.listing_id,
      images: this.parseStringifiedSingleQuoteArray(images),
      moveOutDate: new Date(hit._source.move_out_date),
      description: hit._source.description,
      ac: hit._source.central_air === 't',
      laundry: hit._source.washer_dryer === 't',
      dishwasher: hit._source.dishwasher === 't',
      pets: hit._source.pets === 't',
      gym: hit._source.gym === 't',
      fireplace: hit._source.fireplace === 't',
      elevator: hit._source.elevator === 't',
      highCeilings: hit._source.high_ceilings === 't',
      pool: hit._source.pool === 't',
      woodFloors: hit._source.wood_flooring === 't',
      parking: hit._source.parking === 't',
      patio: hit._source.outdoor === 't',
    };
  }

  /** Find the neighbourhood name by id */
  private getNeighborhoodNameById(id: keyof typeof neighbourhoods): string {
    return neighbourhoods[id];
  }

  /**
   * Elasticsearch arrays are invalid JSON (due to their single-quotes).
   * Convert invalid JSON-like string:
   * "['a.jpg', 'b.jpg', 'c.jpg']"
   * ...to array:
   * ["a.jpg", "b.jpg", "c.jpg"]
   * @param ssqa stringified single quote array
   */
  parseStringifiedSingleQuoteArray(ssqa: string): string[] {
    const a = ssqa.replace(/'/g, '"');
    return JSON.parse(a);
  }
}
