import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from '@angular/fire/firestore';
import {
  RentalApplicationLandlordImpl,
  RentalApplicationLandlordModel,
  RentalApplicationMetadataModel,
  RentalApplicationModel,
  UserProfileWithDate,
} from '@padspin/models';
import {
  angularApplicationDataConverter,
  angularRentalApplicationFromDocumentData,
} from './rental-application-converter-js';
import {
  angularRentalApplicationMetadataConverter,
  angularMetadataFromDocumentData,
} from './rental-application-metadata-converter-js';
import {
  angularUserProfileConverter,
  angularUserProfileWithDateModelFromDocumentData,
} from './user-profile-converter-js';

export const angularRentalApplicationLandlordConverter: FirestoreDataConverter<RentalApplicationLandlordModel> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options: SnapshotOptions | undefined
    ): RentalApplicationLandlordModel => {
      const data = snapshot.data(options);
      const model: RentalApplicationLandlordModel = {
        id: snapshot.id,
        landlord: angularUserProfileWithDateModelFromDocumentData(
          data['landlord'],
          data['landlord'].id
        ),
        app: angularRentalApplicationFromDocumentData(
          data['app'],
          data['app'].id
        ),
        account_manager: angularUserProfileWithDateModelFromDocumentData(
          data['account_manager'],
          data['account_manager'].id
        ),
        metadata: angularMetadataFromDocumentData(data['metadata']),
      };
      return new RentalApplicationLandlordImpl(model);
    },
    toFirestore: (
      o: WithFieldValue<RentalApplicationLandlordModel>
    ): DocumentData => {
      const data: {
        [K in keyof typeof o]: RentalApplicationLandlordModel[K] | DocumentData;
      } = {
        app: angularApplicationDataConverter.toFirestore(
          o.app as WithFieldValue<RentalApplicationModel>
        ),
        landlord: o.landlord,
        account_manager: angularUserProfileConverter.toFirestore(
          o.account_manager as WithFieldValue<UserProfileWithDate>
        ),
        metadata: angularRentalApplicationMetadataConverter.toFirestore(
          o.metadata as WithFieldValue<RentalApplicationMetadataModel>
        ),
        id: o.id,
      };
      return data;
    },
  };
