import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from '@angular/fire/firestore';
import {
  RentalApplicationMetadataImpl,
  RentalApplicationMetadataModel,
  RentalApplicationPersonModel,
} from '@padspin/models';
import { hasOwnProperty } from '../has-own-property';

export const angularRentalApplicationMetadataConverter: FirestoreDataConverter<RentalApplicationMetadataModel> =
  {
    fromFirestore(
      snapshot: QueryDocumentSnapshot<DocumentData>,
      _options: SnapshotOptions | undefined
    ): RentalApplicationMetadataImpl {
      const data = snapshot.data() as {
        [K in keyof RentalApplicationMetadataModel]: unknown;
      };
      return angularMetadataFromDocumentData(data, snapshot.id);
    },
    toFirestore(
      o:
        | PartialWithFieldValue<RentalApplicationMetadataModel>
        | WithFieldValue<RentalApplicationMetadataModel>,
      _options?: SetOptions
    ): DocumentData {
      const withDatesConvertedToTimestamps: {
        [K in keyof RentalApplicationMetadataModel]: unknown;
      } = {
        approved: o.approved,
        approved_at:
          o.approved_at instanceof Date
            ? Timestamp.fromDate(o.approved_at)
            : o.approved_at,
        approved_by_uid: o.approved_by_uid,
        created:
          o.created instanceof Date ? Timestamp.fromDate(o.created) : o.created,
        completed_at:
          o.completed_at instanceof Date
            ? Timestamp.fromDate(o.completed_at)
            : null,
        guarantors: o.guarantors,
        id: o.id,
        lease_start_at:
          o.lease_start_at instanceof Date
            ? Timestamp.fromDate(o.lease_start_at)
            : Timestamp.now(),
        roommates: o.roommates,
        slug: o.slug,
        reviewed_at:
          o.reviewed_at instanceof Date
            ? Timestamp.fromDate(o.reviewed_at)
            : o.reviewed_at,
        uid: o.uid || null,
      };
      return withDatesConvertedToTimestamps;
    },
  };

export const isRentalApplicationPersonModelArray = (
  array: unknown
): array is Array<RentalApplicationPersonModel> => {
  return Array.isArray(array)
    ? array.every((model) => isRentalApplicationPersonModel(model))
    : false;
};

export const isRentalApplicationPersonModel = (
  model: unknown
): model is RentalApplicationPersonModel => {
  if (typeof model !== 'object' || model === null) {
    return false;
  }
  if (!hasOwnProperty(model, 'email') || typeof model.email !== 'string') {
    return false;
  }
  if (!hasOwnProperty(model, 'phone') || typeof model.phone !== 'string') {
    return false;
  }
  if (
    !hasOwnProperty(model, 'first_name') ||
    typeof model.first_name !== 'string'
  ) {
    return false;
  }
  if (
    !hasOwnProperty(model, 'last_name') ||
    typeof model.last_name !== 'string'
  ) {
    return false;
  }
  return true;
};

export const angularMetadataFromDocumentData = (
  data: DocumentData,
  id: string = data['id']
): RentalApplicationMetadataImpl => {
  const model: RentalApplicationMetadataModel = {
    approved: typeof data['approved'] === 'boolean' ? data['approved'] : null,
    approved_at:
      data['approved_at'] instanceof Timestamp
        ? data['approved_at'].toDate()
        : null,
    approved_by_uid:
      typeof data['approved_by_uid'] === 'string'
        ? data['approved_by_uid']
        : null,
    created:
      data['created'] instanceof Timestamp
        ? data['created'].toDate()
        : new Date(),
    completed_at:
      data['completed_at'] instanceof Timestamp
        ? data['completed_at'].toDate()
        : null,
    guarantors: isRentalApplicationPersonModelArray(data['guarantors'])
      ? data['guarantors']
      : [],
    id,
    lease_start_at:
      data['lease_start_at'] instanceof Timestamp
        ? data['lease_start_at'].toDate()
        : new Date(),
    roommates: isRentalApplicationPersonModelArray(data['roommates'])
      ? data['roommates']
      : [],
    slug: typeof data['slug'] === 'string' ? data['slug'] : '',
    reviewed_at:
      data['reviewed_at'] instanceof Timestamp
        ? data['reviewed_at'].toDate()
        : null,
    uid: typeof data['uid'] === 'string' ? data['uid'] : null,
  };
  return new RentalApplicationMetadataImpl(model);
};
