import { WithId } from './types/with-id';

export const message_template_name = 'message_template';

export type MessageTemplateID = WithId<MessageTemplate>;

/**
 * Template for the email and SMS messages sent to leads.
 * @see {@link LeadsModel}
 * @see {@link LeadNotifyQueue}
 */
export type MessageTemplate = {
  shared?: boolean;
  /** @example "Your inquiry about {{address}}" */
  email_subject: string;
  /** @example "{{sender}}@padspin.com" */
  email_sender?: string;
  /** @example `"Hello {{name}},<br>"` */
  email_body?: string;
  /** @example `"Hello {{name}},\n"` */
  sms_body?: string;
  /** Must be a jpg for Twilio */
  sms_media_url?: string;
};

/**
 * Function to insert data into a string, between `{{key}}` handlebars.
 * @example `replace_handlebars("Hi {{name}}!", {name:"John"}); // "Hi John!"`
 * @param str String with handlebars for replacement.
 * @param data Data that will be inserted into handlebars.
 * @see {@link LeadNotifyTemplateModel}
 */
export const replace_handlebars = (
  str: string,
  data: { [key: string]: string } = {}
): string => {
  const matches = str.match(/{{[a-z_A-Z]+?}}/g);
  if (matches) {
    matches.forEach((toReplace) => {
      const replacementKey: string = toReplace.replace(/[{}]/g, '');
      if (data[replacementKey]) {
        str = str.replace(toReplace, data[replacementKey]);
      }
    });
  }
  return str;
};
