import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginOrRegisterModalComponent } from './login-or-register-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginModule } from '../login/login.module';
import { RegisterModule } from '../register/register.module';
import { ButtonModule } from '@padspin/ui';

@NgModule({
  declarations: [LoginOrRegisterModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    RegisterModule,
    ButtonModule,
  ],
  providers: [{ provide: MatDialogRef, useValue: {} }],
})
export class LoginOrRegisterModalModule {}
