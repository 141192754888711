import { UserProfileWithDateModel } from './profile.model';
import { RentalApplicationMetadataModel } from './rental-application-metadata.model';
import { RentalApplicationModel } from './rental-application.model';
import { WithId } from './types/with-id';

export const rental_application_landlord_name = 'application_landlord' as const;

/**
 * The RentalApplicationLandlordModel is a readonly copy of:
 * - rental application
 * - rental application's metadata
 * - landlord (name, email, phone)
 * - account manager profile
 * This should be updated when the rental application metadata changes.
 */
export type RentalApplicationLandlordModel = {
  /** Same as the application and metadata's ID */
  id: string;
  /** Copy of the Rental Application */
  app: WithId<RentalApplicationModel>;
  /** Copy of the metadata */
  metadata: RentalApplicationMetadataModel;
  /** Copy of the landlord's profile */
  landlord: WithId<UserProfileWithDateModel>;
  /** Copy of the account manager's profile */
  account_manager: WithId<UserProfileWithDateModel>;
};

export class RentalApplicationLandlordImpl
  implements RentalApplicationLandlordModel
{
  readonly id: string;
  readonly app: WithId<RentalApplicationModel>;
  readonly metadata: RentalApplicationMetadataModel;
  readonly landlord: WithId<UserProfileWithDateModel>;
  readonly account_manager: WithId<UserProfileWithDateModel>;

  constructor(ralm: WithId<RentalApplicationLandlordModel>) {
    this.id = ralm.id;
    this.app = ralm.app;
    this.metadata = ralm.metadata;
    this.landlord = ralm.landlord;
    this.account_manager = ralm.account_manager;
  }
}
