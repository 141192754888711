import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingStepperComponent } from './onboarding-stepper.component';
import { ButtonModule } from '@padspin/ui';
import { CdkStepperModule } from '@angular/cdk/stepper';

@NgModule({
  declarations: [OnboardingStepperComponent],
  imports: [CommonModule, ButtonModule, CdkStepperModule],
  exports: [OnboardingStepperComponent],
})
export class OnboardingStepperModule {}
