<padspin-user-avatar
  [uid]="uid"
  [first_name]="first_name"
  [last_name]="last_name"
></padspin-user-avatar>
<span class="email" *ngIf="!!email">{{ email }}</span>
<span class="phone" *ngIf="!!phone">{{ phone }}</span>
<span class="name" *ngIf="first_name && last_name"
  >{{ first_name }} {{ last_name }}</span
>
