import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingEditContactComponent } from './listing-edit-contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [ListingEditContactComponent],
  imports: [CommonModule, ReactiveFormsModule, InputTextModule],
  exports: [ListingEditContactComponent],
})
export class ListingEditContactModule {}
