import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  WithFieldValue,
  DocumentData,
} from '@angular/fire/firestore';
import { CredentialsModel } from '@padspin/models';

export const credentialsConverterAngular: FirestoreDataConverter<CredentialsModel> =
  {
    fromFirestore: (snapshot: QueryDocumentSnapshot): CredentialsModel => {
      const data = snapshot.data();
      let value: string | null;
      try {
        value = atob(data['value']);
      } catch (e) {
        value = null;
      }
      const credential: CredentialsModel = {
        key: typeof data['key'] === 'string' ? data['key'] : null,
        description:
          typeof data['description'] === 'string' ? data['description'] : null,
        value,
      };
      return credential;
    },
    toFirestore: (
      modelObject: WithFieldValue<CredentialsModel>
    ): DocumentData => {
      const data: WithFieldValue<CredentialsModel> = {
        value:
          typeof modelObject.value === 'string'
            ? btoa(modelObject.value)
            : modelObject.value,
        key: modelObject.key,
        description: modelObject.description,
      };
      return data;
    },
  };
