import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  collection,
  limit,
  query,
  deleteDoc,
  doc,
} from '@angular/fire/firestore';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import {
  CentPropNYListing,
  CentPropNYListingID,
} from '@padspin/function-types';
import { PadType } from '@padspin/models';
import { parse } from 'date-fns';
import { getFirestore, orderBy } from 'firebase/firestore';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { collection as collection$ } from 'rxfire/firestore';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { AuthService } from '../auth.service';

export type AdminPostOutput = {
  user_relation: 'owner' | 'property_manager' | 'lease_owner';
  type: PadType;
  rent_amount: number;
  bedrooms: number;
  bathrooms: number;
  area: number;
  earliest_move_in_date: Date;
  description: string;
  floor: number | null;
  address: string;
  city: string;
  unit_number: string;
  state: string;
  postal_code: string;
  central_air: boolean;
  dishwasher: boolean;
  doorman: boolean;
  elevator: boolean;
  fireplace: boolean;
  gym: boolean;
  high_ceilings: boolean;
  outdoor: boolean;
  parking: boolean;
  pets: boolean;
  pool: boolean;
  video_url: string;
  washer_dryer: boolean;
  wood_floors: boolean;
};

@Component({
  selector: 'padspin-admin-post-pad-component',
  template: `
    <aside id="card" cdkDrag *ngIf="isVisible$ | async">
      <section
        style="display: flex; justify-content: space-between; gap: 10px; padding: 10px;"
      >
        <h2>Admin Migrations</h2>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-minus"
          class="p-button-rounded"
          (click)="minimized = !minimized"
        ></button>
      </section>
      <mat-selection-list #selection [multiple]="false" *ngIf="!minimized">
        <ng-container *ngFor="let listing of listings$ | async">
          <mat-list-option>
            <div mat-line>
              {{ listing.rent_amount | currency }} {{ listing.bedrooms }}bd
              {{ listing.bathrooms }}ba
            </div>
            <div mat-line>
              <span>{{ listing.address }}</span>
            </div>
            <div mat-line>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-angle-right"
                class="p-button-rounded p-button-sm"
                (click)="output(listing)"
              ></button>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-sm"
                (click)="remove(listing)"
              ></button>
            </div>
          </mat-list-option>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-selection-list>
    </aside>
  `,
  styles: [
    `
      :host {
        bottom: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
        min-width: 100px;
        position: fixed;
        z-index: 10;
      }
      #card {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: rgba(255, 255, 255, 0.875);
        border-radius: 3px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        overflow-y: scroll;
        max-height: 50vh;
      }
    `,
  ],
})
export class AdminPostComponent implements OnInit, OnDestroy {
  #destroy$ = new Subject<void>();
  @Output() listing = new EventEmitter<AdminPostOutput>();
  selection: string[] = [];
  minimized = false;
  isAdmin$ = new BehaviorSubject<boolean>(false);

  listings$: Observable<Array<CentPropNYListingID>> = this.isAdmin$.pipe(
    filter((isAdmin) => isAdmin),
    switchMap((_isAdmin) =>
      collection$(
        query(
          collection(getFirestore(), 'migrate_cent_prop_ny_listings'),
          orderBy('address'),
          limit(1000)
        )
      )
    ),
    tap(() => console.log('loading migrations')),
    map((snaps) =>
      snaps.map((snap) => ({
        ...(snap.data() as CentPropNYListing),
        id: snap.id,
      }))
    ),
    tap(() => this.cd.detectChanges()),
    tap(() => this.cd.markForCheck()),
    shareReplay({ refCount: false, bufferSize: 1 }),
    takeUntil(this.#destroy$)
  );

  isVisible$: Observable<boolean> = combineLatest([
    this.isAdmin$,
    this.listings$.pipe(startWith([])),
  ]).pipe(map(([isAdmin, listings]) => isAdmin && listings.length > 0));

  constructor(
    private readonly authService: AuthService,
    private readonly cd: ChangeDetectorRef
  ) {}

  /** Deletes the current selection */
  async remove(listing: CentPropNYListingID): Promise<void> {
    if (!listing || !listing.id) {
      return;
    }
    await deleteDoc(
      doc(getFirestore(), `migrate_cent_prop_ny_listings/${listing.id}`)
    );
  }

  async output(selection: CentPropNYListingID): Promise<void> {
    let user_relation: 'owner' | 'property_manager' | 'lease_owner';
    switch (selection.user_relation.toLowerCase()) {
      case 'owner':
        user_relation = 'owner';
        break;
      case 'property_manager':
        user_relation = 'property_manager';
        break;
      case 'lease_owner':
        user_relation = 'lease_owner';
        break;
      default:
        user_relation = 'owner';
    }
    let earliest_move_in_date: Date;
    const emid: string = selection.earliest_move_in_date.toLowerCase();
    if (emid === 'imm' || emid === 'tbd' || emid === '') {
      earliest_move_in_date = new Date();
    } else if (emid.match(/[0-9]+\/[0-9]+\/[0-9]+/)) {
      // "7/1/2022"
      earliest_move_in_date = parse(emid, 'P', new Date());
    } else if (emid.match(/[A-Z][a-z][a-z] [0-9]+/)) {
      // "Jul 29"
      earliest_move_in_date = parse(emid, 'MMM d', new Date());
    } else if (emid.match(/[A-Z][a-z]+ [0-9]+/)) {
      // "January 29"
      earliest_move_in_date = parse(emid, 'MMMM d', new Date());
    } else {
      earliest_move_in_date = new Date();
    }

    const listing: AdminPostOutput = {
      user_relation,
      type: 'apartment',
      rent_amount: selection.rent_amount,
      bedrooms: selection.bedrooms || 0,
      bathrooms: selection.bathrooms || 0,
      area: selection.area || 0,
      earliest_move_in_date,
      description: selection.description || '',
      floor: selection.floor || null,
      address: selection.address,
      city: selection.city,
      unit_number: selection.unit_number,
      state: selection.state,
      postal_code: selection.postal_code,
      central_air: selection.central_air,
      dishwasher: selection.dishwasher,
      doorman: selection.doorman,
      elevator: selection.elevator,
      fireplace: selection.fireplace,
      gym: selection.gym,
      high_ceilings: selection.high_ceilings,
      outdoor: selection.outdoor,
      parking: selection.parking,
      pets: selection.pets,
      pool: selection.pool,
      video_url: selection.video_url,
      washer_dryer: selection.washer_dryer,
      wood_floors: selection.wood_flooring,
    };
    this.listing.emit(listing);
  }

  ngOnInit(): void {
    this.authService.isAdmin$
      .pipe(
        tap((isAdmin) => this.isAdmin$.next(isAdmin)),
        takeUntil(this.#destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}

@NgModule({
  declarations: [AdminPostComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatDividerModule,
    MatRippleModule,
    ButtonModule,
    RippleModule,
    DragDropModule,
  ],
  exports: [AdminPostComponent],
})
export class AdminPostModule {}
