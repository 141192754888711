import { Injectable } from '@angular/core';
import { v1 as uuidv1 } from 'uuid';
import { customRandom, random, urlAlphabet } from 'nanoid';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root',
})
export class UuidService {
  #slug_length = 6 as const;
  /** Create an URL-safe 10-char id */
  slug: () => string = customRandom(urlAlphabet, this.#slug_length, random);

  slug3: () => string = customRandom(urlAlphabet, 3, random);

  /** -0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz */
  isSlug = (maybeSlug: string): boolean => /^[-0-9_a-zA-Z]{6}$/.test(maybeSlug);

  /**
   * Create a time-sortable UUID
   * @see {@link https://www.ietf.org/archive/id/draft-peabody-dispatch-new-uuid-format-02.txt}
   */
  v6 = () => {
    // From: aaaaaaaa-bbbb-1ccc-dddd-eeeeeeeeeeee (time-based, version 1)
    // To:   cccbbbba-aaaa-6aaa-dddd-eeeeeeeeeeee (time-ordered, version 6)
    const raw = uuidv1();
    const prefix = `${raw.substring(15, 18)}${raw.substring(
      9,
      13
    )}${raw.substring(0, 5)}6${raw.substring(5, 8)}`;
    const prefixFormatted = `${prefix.substr(0, 8)}-${prefix.substr(
      8,
      4
    )}-${prefix.substr(12)}`;
    return `${prefixFormatted}${raw.substr(18)}`;
  };

  isUuidV6 = (maybeUuidv6: string): boolean =>
    /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/.test(
      maybeUuidv6
    );

  isFirestoreUuid = (maybeFirestoreUuid: string): boolean =>
    /^[a-z0-9A-Z]{28}$/.test(maybeFirestoreUuid);
  isEmail = (maybeEmail: string): boolean => /^.*@.*$/.test(maybeEmail);
  isPhone = (maybePhoneNumber: string) =>
    isPossiblePhoneNumber(maybePhoneNumber, 'US');
}
