/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'padspin-input-text',
  template: `<input
    [type]="type"
    [disabled]="disabled"
    (change)="handleOutgoingValue($any($event.target).value)"
    (keyup)="handleOutgoingValue($any($event.target).value)"
    [placeholder]="placeholder"
    [pattern]="pattern"
    [required]="required"
  />`,
  styleUrls: ['./input-text.component.scss'],
  /* eslint-disable-next-line @angular-eslint/no-host-metadata-property */
  host: {
    id: 'id',
    disabled: 'disabled',
    required: 'required',
    'attr.placeholder': 'placeholder',
    'attr.readonly': 'readonly',
  },
})
export class InputTextComponent implements ControlValueAccessor {
  @Input() type: 'text' | 'number' = 'text';
  @Input() placeholder = '';
  @Input() pattern: RegExp | string | null = null;
  @Input() required = false;

  internalValue!: string;

  #disabled = false;
  /** You can bind to this in your template as needed. */
  @HostBinding('class.padspin-text-input-disabled')
  @HostBinding('attr.disabled')
  @Input()
  set disabled(value: unknown) {
    this.#disabled = value != null && `${value}` !== 'false';
  }
  get disabled(): unknown {
    return this.#disabled ? true : null;
  }

  /** Call this to emit a new value when it changes. */
  emitOutgoingValue: (value: string) => void = () => {};

  /** Call this to "commit" a change, traditionally done e.g. on blur. */
  onTouched = () => {};

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly control: NgControl
  ) {
    this.control.valueAccessor = this;
  }

  get invalid(): boolean {
    return this.control ? coerceBooleanProperty(this.control.invalid) : false;
  }

  /** Handle a new value coming from the component */
  handleOutgoingValue(value: string): void {
    this.emitOutgoingValue(value);
    this.onTouched();
  }

  /** Handle a new value coming in from outside. */
  handleIncomingValue(value: string): void {
    this.internalValue = value;
  }

  /** Called as angular propagates value changes to this `ControlValueAccessor`. You normally do not need to use it. */
  writeValue(value: string): void {
    this.handleIncomingValue(value);
    this.changeDetectorRef.markForCheck();
  }

  /** Called as angular sets up the binding to this `ControlValueAccessor`. You normally do not need to use it. */
  registerOnChange(fn: (value: string) => void): void {
    this.emitOutgoingValue = fn;
  }

  /** Called as angular sets up the binding to this `ControlValueAccessor`. You normally do not need to use it. */
  registerOnTouched(fn: VoidFunction): void {
    this.onTouched = fn;
  }

  /** Called as angular propagates disabled changes to this `ControlValueAccessor`. You normally do not need to use it. */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
}
