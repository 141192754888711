import { CoordTypes } from './coord-types';
import { GeoPoint } from './geopoint';
import { LatLngArray } from './lat-lng-array';
import { LatLngLiteral } from './lat-lng-literal';
import {
  geohashForLocation,
  geohashQueryBounds,
  EARTH_EQ_RADIUS,
} from 'geofire-common';
import { LatLngBounds } from './lat-lng-bounds';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace google.maps {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  class LatLng {
    constructor(
      latOrLatLngLiteral: number | LatLngLiteral,
      lngOrNoWrap?: number | boolean | null,
      noWrap?: boolean
    );
    equals(other: google.maps.LatLng | null): boolean;
    lat(): number;
    lng(): number;
    toJSON(): LatLngLiteral;
    toString(): string;
    toUrlValue(precision?: number): string;
  }
}

export const isLatLngLiteral = (
  coord: LatLngLiteral | google.maps.LatLng | LatLngArray | GeoPoint
): coord is LatLngLiteral => {
  if (coord instanceof Array) {
    return false; // [number, number]
  }
  if ('toJSON' in coord && 'equals' in coord && 'toUrlValue' in coord) {
    return false;
  }
  if (coord instanceof GeoPoint) {
    return false;
  }
  return (
    Object.prototype.hasOwnProperty.call(coord, 'lat') &&
    typeof coord.lat === 'number' &&
    Object.prototype.hasOwnProperty.call(coord, 'lng') &&
    typeof coord.lng === 'number'
  );
};

export const isLatLng = (
  latlng: LatLngLiteral | google.maps.LatLng | LatLngArray | GeoPoint
): latlng is google.maps.LatLng => {
  if (latlng instanceof Array) {
    return false; // [number, number]
  }
  if (latlng instanceof GeoPoint) {
    return false;
  }
  if (
    Object.prototype.hasOwnProperty.call(latlng, 'lat') &&
    typeof latlng.lat === 'number' &&
    Object.prototype.hasOwnProperty.call(latlng, 'lng') &&
    typeof latlng.lng === 'number'
  ) {
    return false; // LatLngLiteral
  }
  return 'toJSON' in latlng && 'equals' in latlng && 'toUrlValue' in latlng;
};

export const isLatLngArray = (
  latlng: LatLngLiteral | google.maps.LatLng | LatLngArray
): latlng is LatLngArray => {
  if (!(latlng instanceof Array)) {
    return false;
  }
  return (
    latlng.length === 2 &&
    latlng[0] >= -180 &&
    latlng[0] <= 180 &&
    latlng[1] >= -180 &&
    latlng[1] <= 180
  );
};

export const isGeoPoint = (
  coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray
): coord is GeoPoint => {
  return coord instanceof GeoPoint;
};

export const typeOfCoord = (
  coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray
): CoordTypes => {
  if (isGeoPoint(coord)) {
    return CoordTypes.GeoPoint;
  } else if (isLatLngArray(coord)) {
    return CoordTypes.LatLngArray;
  } else if (isLatLngLiteral(coord)) {
    return CoordTypes.LatLngLiteral;
  } else {
    return CoordTypes.LatLng;
  }
};

export const convertCoordToLatLngArray = (
  coord: LatLngLiteral | LatLngArray | google.maps.LatLng | GeoPoint
): LatLngArray => {
  if (isLatLngLiteral(coord)) {
    return [coord.lat, coord.lng];
  } else if (isLatLng(coord)) {
    return [coord.lat(), coord.lng()];
  } else if (isGeoPoint(coord)) {
    return [coord.latitude, coord.longitude];
  }
  return coord;
};

export const convertCoordToLatLng = (
  coord: LatLngLiteral | LatLngArray | google.maps.LatLng | GeoPoint
): google.maps.LatLng => {
  if (isLatLngLiteral(coord)) {
    return new google.maps.LatLng(coord);
  } else if (coord instanceof GeoPoint) {
    return new google.maps.LatLng({
      lat: coord.latitude,
      lng: coord.longitude,
    });
  } else if (isLatLngArray(coord)) {
    return new google.maps.LatLng({ lat: coord[0], lng: coord[1] });
  }
  return coord;
};

export const convertCoordToLatLngLiteral = (
  coord: LatLngLiteral | LatLngArray | google.maps.LatLng | GeoPoint | undefined
): LatLngLiteral => {
  if (!coord) {
    return { lat: 0, lng: 0 };
  }
  if (isLatLng(coord)) {
    return { lat: coord.lat(), lng: coord.lng() };
  } else if (isGeoPoint(coord)) {
    return { lat: coord.latitude, lng: coord.longitude };
  } else if (isLatLngArray(coord)) {
    return { lat: coord[0], lng: coord[1] };
  }
  return coord;
};

export const convertCoordToGeoPoint = (
  coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray
): GeoPoint => {
  if (isGeoPoint(coord)) {
    return coord;
  } else if (isLatLngArray(coord)) {
    return new GeoPoint(coord[0], coord[1]);
  } else if (isLatLngLiteral(coord)) {
    return new GeoPoint(coord.lat, coord.lng);
  } else {
    return new GeoPoint(coord.lat(), coord.lng());
  }
};

export const geoHash = (
  coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray,
  precision = 6
): string => {
  const latlng = convertCoordToLatLngArray(coord);
  return geohashForLocation(latlng, precision);
};

export const isCoordInsideBounds = (
  coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray,
  bounds: LatLngBounds
): boolean => {
  const latlng: LatLngLiteral = convertCoordToLatLngLiteral(coord);
  const latMin = Math.min(bounds.northeast.lat, bounds.southwest.lat);
  const latMax = Math.max(bounds.northeast.lat, bounds.southwest.lat);
  const lngMin = Math.min(bounds.northeast.lng, bounds.southwest.lng);
  const lngMax = Math.max(bounds.northeast.lng, bounds.southwest.lng);
  const isLatitudeBetween = latMin <= latlng.lat && latMax >= latlng.lat;
  const isLongitudeBetween = lngMin <= latlng.lng && lngMax >= latlng.lng;
  return isLatitudeBetween && isLongitudeBetween;
};

export const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180;

export const distanceBetween = (
  location1: LatLngArray,
  location2: LatLngArray
) => {
  const radius = 6371;
  const latDelta = degreesToRadians(location2[0] - location1[0]);
  const lonDelta = degreesToRadians(location2[1] - location1[1]);
  const a =
    Math.sin(latDelta / 2) * Math.sin(latDelta / 2) +
    Math.cos(degreesToRadians(location1[0])) *
      Math.cos(degreesToRadians(location2[0])) *
      Math.sin(lonDelta / 2) *
      Math.sin(lonDelta / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return radius * c;
};

export const getRadiusOfBoundsInM = (bounds: LatLngBounds): number => {
  const circumferenceInKM = distanceBetween(
    [bounds.northeast.lat, bounds.northeast.lng],
    [bounds.southwest.lat, bounds.southwest.lng]
  );
  const circumferenceInM = circumferenceInKM * 1000;
  return circumferenceInM / 2;
};

export const getCenterOfBounds = (b: LatLngBounds): LatLngLiteral => ({
  lat: (b.southwest.lat + b.northeast.lat) / 2,
  lng: (b.southwest.lng + b.northeast.lng) / 2,
});

/** Calculates a geohash to fully contain a given area. */
export const getGeohashBounds = (bounds: LatLngBounds): string => {
  const center: LatLngLiteral = getCenterOfBounds(bounds);
  const radius = getRadiusOfBoundsInM(bounds);
  const hashBounds = geohashQueryBounds([center.lat, center.lng], radius);
  const flat = hashBounds.reduce((acc, val) => [...acc, ...val], []);
  return longestCommonPrefix(flat);
};

export const longestCommonPrefix = (strings: string[]): string => {
  let prefix = '';
  for (let i = 0; i < strings[0].length; i++) {
    for (let j = 1; j < strings.length; j++) {
      if (strings[j][i] !== strings[0][i]) {
        return prefix;
      }
    }
    prefix = prefix + strings[0][i];
  }
  return prefix;
};

export const resizeBounds = (
  bounds: LatLngBounds,
  distanceInM = 500
): LatLngBounds => {
  const northeast: LatLngLiteral = { lat: 0, lng: 0 };
  const southwest: LatLngLiteral = { lat: 0, lng: 0 };
  const r_earth = EARTH_EQ_RADIUS;
  const dy = distanceInM;
  const dx = distanceInM;

  northeast.lat = bounds.northeast.lat + (dy / r_earth) * (180 / Math.PI);
  northeast.lng =
    bounds.northeast.lng +
    ((dx / r_earth) * (180 / Math.PI)) /
      Math.cos((bounds.northeast.lat * Math.PI) / 180);
  southwest.lat = bounds.southwest.lat + (-dy / r_earth) * (180 / Math.PI);
  southwest.lng =
    bounds.southwest.lng +
    ((-dx / r_earth) * (180 / Math.PI)) /
      Math.cos((bounds.southwest.lat * Math.PI) / 180);

  return { northeast, southwest };
};
