import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PadExpandedCarouselModule } from '../pad-expanded-carousel/pad-expanded-carousel.module';
import { PadExpandedComponent } from './pad-expanded.component';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  ButtonModule,
  OrdinalSuffixPipeModule,
  SanitizeHtmlModule,
} from '@padspin/ui';
import { SkeletonModule } from 'primeng/skeleton';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { YoutubeModule } from '../../youtube/youtube.module';
import { PadExpandedCarouselTwoModule } from '../pad-expanded-carousel-two/pad-expanded-carousel-two.module';
import { RouterModule } from '@angular/router';
import { AfterBookViewingModule } from '../../after-book-viewing/after-book-viewing.module';

import { MessageModule } from 'primeng/message';

@NgModule({
  declarations: [PadExpandedComponent],
  imports: [
    CommonModule,
    PadExpandedCarouselModule,
    GoogleMapsModule,
    ButtonModule,
    SkeletonModule,
    SanitizeHtmlModule,
    OrdinalSuffixPipeModule,
    YouTubePlayerModule,
    YoutubeModule,
    PadExpandedCarouselTwoModule,
    RouterModule,
    AfterBookViewingModule,
    MessageModule,
  ],
  exports: [PadExpandedComponent],
})
export class PadExpandedModule {}
