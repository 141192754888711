import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionComponent } from './version.component';
import { ButtonModule } from '@padspin/ui';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [VersionComponent],
  imports: [CommonModule, ButtonModule, MatDialogModule],
  exports: [VersionComponent],
})
export class VersionModule {}
