import { Injectable } from '@angular/core';
import {
  geohashQueryBounds,
  distanceBetween,
  geohashForLocation,
} from 'geofire-common';
import { LatLngLiteral } from './lat-lng-literal';
import { MapService } from './map.service';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AngularGeofireService {
  geocodeResponsesCache: Array<{ address: string; res: MapGeocoderResponse }> =
    [];

  constructor(
    private readonly ms: MapService,
    private readonly geocoder: MapGeocoder
  ) {}

  /** @see geohashQueryBounds */
  geoHashQueryBounds(center: number[], radiusInM: number): string[][] {
    return geohashQueryBounds(center, radiusInM);
  }

  /** @see geohashForLocation */
  geoHashForLocation(
    coord: [number, number] | LatLngLiteral | google.maps.LatLng,
    precision: number = 10
  ): string {
    return geohashForLocation(
      this.ms.convertCoordToLatLngArray(coord),
      precision
    );
  }

  getPlaceIDForAddress(address: string): Observable<string> {
    const hit = this.geocodeResponsesCache.find(
      (item) => item.address === address
    );
    if (hit) {
      console.log(hit.res.results);
      return of(hit.res.results[0].place_id);
    }
    const request: google.maps.GeocoderRequest = { address };
    return this.geocoder.geocode(request).pipe(
      filter((res) => res.status === google.maps.GeocoderStatus.OK),
      map((res) => res.results[0].place_id)
    );
  }

  /** @see distanceBetween */
  distanceBetween(
    coord: [number, number] | LatLngLiteral | google.maps.LatLng,
    center: [number, number] | LatLngLiteral | google.maps.LatLng
  ): number {
    return distanceBetween(
      this.ms.convertCoordToLatLngArray(coord),
      this.ms.convertCoordToLatLngArray(center)
    );
  }

  getPlaceNamesForAddress(address: string): Observable<string[]> {
    const hit = this.geocodeResponsesCache.find(
      (item) => item.address === address
    );
    if (hit) {
      return of(this.#getPlaceNamesFromGeocodeResponse(hit.res));
    }
    const request: google.maps.GeocoderRequest = { address };
    return this.geocoder.geocode(request).pipe(
      tap((res) => console.log(res.status)),
      filter(
        (res: MapGeocoderResponse) =>
          res.status === google.maps.GeocoderStatus.OK
      ),
      map((res: MapGeocoderResponse) =>
        this.#getPlaceNamesFromGeocodeResponse(res)
      )
    );
  }

  #getPlaceNamesFromGeocodeResponse(response: MapGeocoderResponse): string[] {
    const place_names: string[] = [];
    if (response.results.length === 0 || !response.results) {
      console.log('No results');
      return [];
    }
    response.results.forEach((result) =>
      result.address_components.forEach((ac) => {
        const checkFor: string[] = [
          'neighborhood',
          'administrative_area_level_2',
          'administrative_area_level_1',
          'locality',
          'political',
          'sublocality',
        ];
        ac.types.forEach((type) => {
          if (checkFor.includes(type)) {
            place_names.push(ac.short_name);
          }
        });
      })
    );
    return [...new Set(place_names)];
  }

  getCoordsForAddress(address: string): Observable<LatLngLiteral> {
    const hit = this.geocodeResponsesCache.find(
      (item) => item.address === address
    );
    if (hit) {
      return of(
        this.ms.convertCoordToLatLngLiteral(
          hit.res.results[0].geometry.location
        )
      );
    }
    const request: google.maps.GeocoderRequest = { address };
    return this.geocoder.geocode(request).pipe(
      tap((response) => console.log({ response })),
      filter((res) => res.status === google.maps.GeocoderStatus.OK),
      map((res) =>
        this.ms.convertCoordToLatLngLiteral(res.results[0].geometry.location)
      )
    );
  }
}
