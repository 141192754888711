export const credentials_model_name = 'credentials' as const;

export type CredentialsModel = {
  description: string | null;
  key: string | null;
  value: string | null;
};

export type CredentialsWithKV = {
  key: NonNullable<CredentialsModel['key']>;
  value: NonNullable<CredentialsModel['value']>;
};
