<form [formGroup]="form">
  <label class="name">
    <span>Name</span>
    <div style="display: flex; flex-direction: row">
      <input
        type="text"
        formControlName="first_name"
        padspinAutofocus=""
        placeholder="First"
      />
      <input type="text" formControlName="last_name" placeholder="Last" />
    </div>
  </label>
  <p
    class="validation"
    *ngIf="
      form.get('first_name')?.invalid &&
      form.get('first_name')?.errors &&
      form.get('first_name')?.hasError('required') &&
      (form.get('first_name')?.dirty || form.get('first_name')?.touched)
    "
  >
    First name required
  </p>
  <p
    class="validation"
    *ngIf="
      form.get('last_name')?.invalid &&
      form.get('last_name')?.errors &&
      form.get('last_name')?.hasError('required') &&
      (form.get('last_name')?.dirty || form.get('last_name')?.touched)
    "
  >
    Last name required
  </p>

  <label>
    <span>Phone</span>
    <input
      type="tel"
      formControlName="phone"
      [class.error]="form.get('phone')?.errors"
    />
  </label>
  <p
    class="validation"
    *ngIf="
      form.get('phone')?.invalid &&
      form.get('phone')?.errors &&
      form.get('phone')?.hasError('required') &&
      (form.get('phone')?.dirty || form.get('phone')?.touched)
    "
  >
    Phone required
  </p>

  <label>
    <span>Email</span>
    <input type="email" formControlName="email" />
  </label>
  <p
    class="validation"
    *ngIf="
      form.get('email')?.invalid &&
      form.get('email')?.errors &&
      form.get('email')?.hasError('required') &&
      (form.get('email')?.dirty || form.get('email')?.touched)
    "
  >
    Email required
  </p>
  <p
    class="validation"
    *ngIf="
      form.get('email')?.invalid &&
      form.get('email')?.errors &&
      form.get('email')?.hasError('email') &&
      (form.get('email')?.dirty || form.get('email')?.touched)
    "
  >
    Invalid email
  </p>

  <label>
    <span>Password</span>
    <input type="password" formControlName="password" />
  </label>
  <p
    class="validation"
    *ngIf="
      form.get('password')?.invalid &&
      form.get('password')?.errors &&
      form.get('password')?.hasError('pattern') &&
      (form.get('password')?.dirty || form.get('password')?.touched)
    "
  >
    Password should be at least <strong>8</strong> characters
  </p>

  <label class="tos">
    <input type="checkbox" formControlName="tos" />
    <span
      >&nbsp;I agree to Padspin's
      <a routerLink="/terms-of-service">terms and conditions</a>.</span
    >
  </label>

  <p class="validation" *ngIf="validationMessage | async">
    Error: {{ validationMessage | async }}
  </p>

  <button
    padspin-button
    [attr.color]="accountType"
    [color]="accountType"
    (click)="register()"
    [disabled]="form.invalid || (isRegistering$ | async)"
  >
    <div
      style="width: 22px; height: 22px; margin-right: 10px"
      *ngIf="isRegistering$ | async"
    >
      <i class="pi pi-spin pi-spinner"></i>
    </div>
    <span>Sign Up</span>
  </button>
</form>
