import { hasOwnProperty } from '../lib/utils/has-own-property';

export const listingsAdminName = 'listings_admin' as const;

export type ListingContactTypes =
  | 'owner'
  | 'property_manager'
  | 'lease_owner'
  | 'supervisor';

export type ListingContact = {
  type: ListingContactTypes;
  name: string;
  email: string;
  phone: string;
};

/**
 * Anything about a listing that we do not want a user to see goes here,
 * instead of in {@link ListingsModel}
 */
export type ListingsAdminModel = {
  /** Slug of the listing */
  slug: string | null; // TODO: this should never be null after the migration
  /** HTML notes. Eg: Current tenant contact info. Acct Manager, existing rent, etc. */
  notes: string;
  /** @deprecated */
  owner: ListingContact | null;
  property_manager: ListingContact | null;
  lease_owner: ListingContact | null;
  supervisor: ListingContact | null;
  /** Access details (eg: lockbox instructions / code) */
  access: string;
  lockbox: string | null;
  lease_start_date: Date | null;
  account_manager_email: string | null;
  default_contact: ListingContact | null;
};

export class ListingsAdminImpl implements ListingsAdminModel {
  slug: string;
  notes: string;
  /** @deprecated */
  contacts?: [ListingContact, ListingContact, ListingContact] = undefined;
  owner: ListingContact | null;
  property_manager: ListingContact | null;
  lease_owner: ListingContact | null;
  supervisor: ListingContact | null;
  access: string;
  lockbox: string | null;
  lease_start_date: Date | null;
  account_manager_email: string | null;
  default_contact: ListingContact | null;

  constructor(o: Partial<ListingsAdminModel>) {
    this.slug = o.slug || '';
    this.notes = typeof o.notes === 'string' ? o.notes : '';
    this.owner = o.owner || null;
    this.property_manager = o.property_manager || null;
    this.lease_owner = o.lease_owner || null;
    this.supervisor = o.supervisor || null;
    this.access = typeof o.access === 'string' ? o.access : '';
    this.lockbox = typeof o.lockbox === 'string' ? o.lockbox : null;
    this.lease_start_date =
      o.lease_start_date instanceof Date ? o.lease_start_date : null;
    this.account_manager_email =
      typeof o.account_manager_email === 'string'
        ? o.account_manager_email
        : null;
    this.default_contact = o.default_contact || null;
  }
}

export const isListingContact = (input: unknown): input is ListingContact => {
  if (typeof input !== 'object' || input === null) {
    return false;
  }
  if (
    !hasOwnProperty(input, 'type') ||
    !hasOwnProperty(input, 'name') ||
    !hasOwnProperty(input, 'email') ||
    !hasOwnProperty(input, 'phone')
  ) {
    return false;
  }
  if (
    typeof input.email !== 'string' ||
    typeof input.name !== 'string' ||
    typeof input.phone !== 'string' ||
    typeof input.type !== 'string'
  ) {
    return false;
  }
  return true;
};

export const isValidListingContact = (input: unknown): boolean => {
  if (!isListingContact(input)) {
    return false;
  }
  return !!input.phone && !!input.email && !!input.name;
};

export const isListingContactEqual = (
  a: ListingContact | null,
  b: ListingContact | null
): boolean =>
  a !== null &&
  b !== null &&
  a.type === b.type &&
  a.email === b.email &&
  a.phone === b.phone &&
  a.name === b.name;
