<form id="edit_form" [formGroup]="formPublic">
  <p id="title">Edit Listing</p>
  <section id="closesave">
    <button padspin-button (click)="close()">Close</button>
    <button
      padspin-button
      (click)="save()"
      [disabled]="formPublic.invalid || formAdmin.invalid"
    >
      <div
        [hidden]="(isSaving$ | async) === false"
        icon
        style="width: 24px; height: 24px"
      >
        <i class="pi pi-spin pi-spinner"></i>
      </div>
      <span>Save</span>
    </button>
    <button padspin-button (click)="navigateToDashboard()">Active Pads</button>
  </section>
  <section id="posted_by">
    <div
      style="
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      "
    >
      <span
        >{{ profileFirstName$ | async }} {{ profileLastName$ | async }}</span
      >
      <span>{{ formPublic.get('user_email')?.value }}</span>
      <span>{{ formPublic.get('user_phone')?.value }}</span>
      <span>Posted {{ listingCreated$ | async | relativeDate }}</span>
    </div>
    <div>
      <p-radioButton
        value="owner"
        formControlName="user_relation"
        inputId="owner"
        label="Property owner"
      ></p-radioButton>
      <p-radioButton
        value="property_manager"
        formControlName="user_relation"
        inputId="property_manager"
        label="Property manager"
      ></p-radioButton>
      <p-radioButton
        value="lease_owner"
        formControlName="user_relation"
        inputId="lease_owner"
        label="Lease owner"
      ></p-radioButton>
      <form [formGroup]="formAdmin">
        <span class="p-float-label">
          <input
            pInputText
            formControlName="account_manager_email"
            type="text"
            id="account_manager_email"
            [size]="30"
          />
          <label for="account_manager_email">Account Manager Email</label>
        </span>
      </form>
    </div>
  </section>
  <section id="post_to">
    <p-button
      [disabled]="true"
      pTooltip="Post listing to Facebook, Facebook Groups, and Facebook Marketplace, Instagram, and WhatsApp"
    >
      <ng-template pTemplate="content">
        <i
          *ngIf="(isLoadingFacebook$ | async) === true"
          class="pi pi-spin pi-spinner"
        ></i>
        <img
          *ngIf="(isLoadingFacebook$ | async) === false"
          src="assets/web/icons/facebook_white_24dp.svg"
          alt="Facebook"
        />
      </ng-template>
    </p-button>
    <p-button
      (onClick)="postToExternalPlatform('craigslist')"
      pTooltip="Post listing to Craigslist"
    >
      <ng-template pTemplate="content">
        <i
          *ngIf="(isLoadingCraigslist$ | async) === true"
          class="pi pi-spin pi-spinner"
        ></i>
        <img
          *ngIf="(isLoadingCraigslist$ | async) === false"
          src="assets/web/icons/craigslist_white_24dp.svg"
          alt="Craigslist"
        />
      </ng-template>
    </p-button>
    <p-button
      (onClick)="postToExternalPlatform('linkedin')"
      [disabled]="true"
      pTooltip="Post listing to LinkedIn"
    >
      <ng-template pTemplate="content">
        <i
          *ngIf="(isLoadingLinkedIn$ | async) === true"
          class="pi pi-spin pi-spinner"
        ></i>
        <img
          *ngIf="(isLoadingLinkedIn$ | async) === false"
          src="assets/web/icons/linkedin_white_24dp.svg"
          alt="LinkedIn"
        />
      </ng-template>
    </p-button>
    <p-button
      (onClick)="postToExternalPlatform('twitter')"
      [disabled]="true"
      pTooltip="Post listing to Twitter"
    >
      <ng-template pTemplate="content">
        <i
          *ngIf="(isLoadingTwitter$ | async) === true"
          class="pi pi-spin pi-spinner"
        ></i>
        <img
          *ngIf="(isLoadingTwitter$ | async) === false"
          src="assets/web/icons/twitter_white_24dp.svg"
          alt="Twitter"
        />
      </ng-template>
    </p-button>
    <p-button
      (onClick)="postToExternalPlatform('apartments_dot_com')"
      [disabled]="true"
      pTooltip="Post listing to apartments.com"
    >
      <ng-template pTemplate="content">
        <i
          *ngIf="(isLoadingTwitter$ | async) === true"
          class="pi pi-spin pi-spinner"
        ></i>
        <img
          *ngIf="(isLoadingTwitter$ | async) === false"
          src="assets/web/icons/apartments_dot_com_white_24dp.svg"
          alt="Apartments.com"
        />
      </ng-template>
    </p-button>
    <p-button
      (onClick)="postToExternalPlatform('zillow')"
      [disabled]="true"
      pTooltip="Post listing to Zillow"
    >
      <ng-template pTemplate="content">
        <i
          *ngIf="(isLoadingTwitter$ | async) === true"
          class="pi pi-spin pi-spinner"
        ></i>
        <img
          *ngIf="(isLoadingTwitter$ | async) === false"
          src="assets/web/icons/zillow_white_24dp.svg"
          alt="Apartments.com"
        />
      </ng-template>
    </p-button>
  </section>
  <section id="rental_details">
    <span class="p-float-label">
      <p-inputNumber
        formControlName="rent_amount"
        inputId="rent_amount"
        mode="currency"
        currency="USD"
        locale="en-US"
        [minFractionDigits]="2"
        [min]="0"
        [inputStyle]="{ width: '130px' }"
      ></p-inputNumber>
      <label for="rent_amount">Rent</label>
    </span>
    <span class="p-float-label">
      <p-calendar
        formControlName="available_from_date"
        inputId="available_from_date"
        appendTo="body"
        [inputStyle]="{ width: '130px' }"
      ></p-calendar>
      <label for="available_from_date">Available from</label>
    </span>
  </section>
  <section id="details">
    <span class="p-float-label">
      <input
        pInputText
        formControlName="address"
        type="text"
        id="address"
        [size]="30"
      />
      <label for="address">Address</label>
    </span>

    <span class="p-float-label">
      <input
        pInputText
        formControlName="unit_number"
        type="text"
        id="unit_number"
        [size]="8"
      />
      <label for="unit_number">Suite Number</label>
    </span>

    <span class="p-float-label">
      <input
        pInputText
        formControlName="city"
        type="text"
        id="city"
        [size]="6"
      />
      <label for="city">City</label>
    </span>

    <span class="p-float-label">
      <input
        pInputText
        formControlName="state"
        type="text"
        id="state"
        [size]="2"
      />
      <label for="state">State</label>
    </span>

    <span class="p-float-label">
      <input
        pInputText
        formControlName="postal_code"
        type="text"
        id="postal_code"
        [size]="5"
      />
      <label for="postal_code">Zip Code</label>
    </span>

    <span class="p-float-label">
      <input
        pInputText
        formControlName="neighborhood"
        type="text"
        id="neighborhood"
        [size]="15"
      />
      <label for="neighborhood">Neighborhood</label>
    </span>

    <span class="p-float-label">
      <input
        pInputText
        formControlName="cross_street"
        type="text"
        id="cross_street"
      />
      <label for="cross_street">Cross Street</label>
    </span>

    <span class="p-float-label">
      <p-inputNumber
        formControlName="bedrooms"
        inputId="bedrooms"
        [min]="0"
        [inputStyle]="{ width: '90px' }"
        [size]="1"
      ></p-inputNumber>
      <label for="bedrooms">Bedrooms</label>
    </span>

    <span class="p-float-label">
      <p-inputNumber
        formControlName="bathrooms"
        inputId="bathrooms"
        [min]="1"
        [maxFractionDigits]="1"
        [inputStyle]="{ width: '90px' }"
      ></p-inputNumber>
      <label for="bathrooms">Bathrooms</label>
    </span>

    <span class="p-float-label ui-fluid">
      <p-inputNumber
        formControlName="floor"
        inputId="floor"
        [inputStyle]="{ width: '130px' }"
      ></p-inputNumber>
      <label for="floor">Floor</label>
    </span>
  </section>
  <section id="activation">
    <div>
      <p-toggleButton
        formControlName="is_active"
        onLabel="Deactivate"
        onIcon="pi pi-pause"
        offLabel="Activate"
        offIcon="pi pi-play"
        styleClass="p-button-lg"
        pTooltip="Save the listing to activate or deactivate this listing"
        tooltipPosition="left"
        (onChange)="toggleActivation()"
      ></p-toggleButton>
    </div>
    <div>
      <p-button
        label="Send Application"
        (onClick)="openApplicationModal()"
      ></p-button>
    </div>
    <span class="p-float-label ui-fluid">
      <p-dropdown
        [options]="typeOptions"
        formControlName="type"
        inputId="padType"
      ></p-dropdown>
      <label for="padType">Pad Type</label>
    </span>
    <span class="p-float-label">
      <input
        pInputText
        formControlName="slugFriendly"
        type="text"
        id="slugFriendly"
        [size]="30"
      />
      <label for="slugFriendly">SEO friendly</label>
    </span>
    <p-message
      severity="info"
      text="https://padspin.com/l/{{ (listingSlug$ | async) || '' }}"
      title="Listing Slug"
    ></p-message>
    <p-message
      severity="info"
      text="{{ (listingId$ | async) || 'Loading' }}"
      title="Listing ID"
    ></p-message>
  </section>
  <section id="adminnotes">
    <label>Apartment Notes</label>
    <form [formGroup]="formAdmin">
      <p-editor
        formControlName="notes"
        [style]="{ minHeight: '150px', minWidth: '250px' }"
        [formats]="['size: 18px']"
      ></p-editor>
    </form>
  </section>
  <section id="notesaccess">
    <form [formGroup]="formAdmin" style="height: 100%; display: block">
      <span class="p-float-label" style="height: 100%">
        <textarea
          pInputTextarea
          [autoResize]="true"
          [style]="{ width: '100%', minHeight: '100%' }"
          formControlName="access"
        ></textarea>
        <label>Access / Lock Box Details</label>
      </span>
    </form>
  </section>
  <section
    id="contacts"
    [ngStyle]="{ outlineColor: formDefaultContact.invalid ? 'red' : '#ced4da' }"
  >
    <div style="margin-left: 4px">
      <p-message
        *ngIf="formDefaultContact.hasError('typeRequired')"
        text="Must choose a default contact"
        severity="error"
      ></p-message>
      <p-message
        *ngIf="formDefaultContact.hasError('defaultContactIsRequired')"
        text="Must enter a valid default contact"
        severity="error"
      ></p-message>
      <p-message
        *ngIf="formDefaultContact.hasError('contactDoesNotMatchSelectedType')"
        text="Invalid Selection"
        severity="error"
      ></p-message>
    </div>
    <form [formGroup]="formAdmin">
      <div>
        <div [formGroup]="formAdmin">
          <padspin-listing-edit-contact
            formControlName="owner"
            type="owner"
          ></padspin-listing-edit-contact>
        </div>
        <div [formGroup]="formDefaultContact">
          <p-radioButton
            value="owner"
            formControlName="default"
            inputId="default_owner"
          ></p-radioButton>
          <label for="default_owner">Owner</label>
        </div>
      </div>
      <div>
        <div [formGroup]="formAdmin">
          <padspin-listing-edit-contact
            formControlName="property_manager"
            type="property_manager"
          ></padspin-listing-edit-contact>
        </div>
        <div [formGroup]="formDefaultContact">
          <p-radioButton
            value="property_manager"
            formControlName="default"
            inputId="default_property_manager"
          ></p-radioButton>
          <label for="default_property_manager">Property Manager</label>
        </div>
      </div>
      <div>
        <div [formGroup]="formAdmin">
          <padspin-listing-edit-contact
            formControlName="lease_owner"
            type="lease_owner"
          ></padspin-listing-edit-contact>
        </div>
        <div [formGroup]="formDefaultContact">
          <p-radioButton
            value="lease_owner"
            formControlName="default"
            inputId="default_lease_owner"
          ></p-radioButton>
          <label for="default_lease_owner">Lease Owner</label>
        </div>
      </div>
      <div>
        <div [formGroup]="formAdmin">
          <padspin-listing-edit-contact
            formControlName="supervisor"
            type="supervisor"
          ></padspin-listing-edit-contact>
        </div>
        <div [formGroup]="formDefaultContact">
          <p-radioButton
            value="supervisor"
            formControlName="default"
            inputId="default_supervisor"
          ></p-radioButton>
          <label for="default_supervisor">Supervisor</label>
        </div>
      </div>
    </form>
  </section>
  <section id="description">
    <label>Description</label>
    <p-editor
      formControlName="description"
      [style]="{ minHeight: '150px', minWidth: '250px' }"
    >
    </p-editor>
  </section>
  <section id="amenities">
    <div style="display: grid; grid-template-columns: 1fr 1fr; row-gap: 2rem">
      <p-checkbox
        formControlName="central_air"
        id="central_air"
        [binary]="true"
        label="Central Air"
      ></p-checkbox>
      <p-checkbox
        formControlName="dishwasher"
        id="dishwasher"
        [binary]="true"
        label="Dishwasher"
      ></p-checkbox>
      <p-checkbox
        formControlName="doorman"
        id="Doorman"
        [binary]="true"
        label="Doorman"
      ></p-checkbox>
      <p-checkbox
        formControlName="elevator"
        id="elevator"
        [binary]="true"
        label="Elevator"
      ></p-checkbox>
      <p-checkbox
        formControlName="fireplace"
        id="fireplace"
        [binary]="true"
        label="Fireplace"
      ></p-checkbox>
      <p-checkbox
        formControlName="gym"
        id="gym"
        [binary]="true"
        label="Gym"
      ></p-checkbox>
      <p-checkbox
        formControlName="high_ceilings"
        id="high_ceilings"
        [binary]="true"
        label="High Ceilings"
      ></p-checkbox>
      <p-checkbox
        formControlName="outdoor"
        id="outdoor"
        [binary]="true"
        label="Outdoor"
      ></p-checkbox>
      <p-checkbox
        formControlName="parking"
        id="parking"
        [binary]="true"
        label="Parking"
      ></p-checkbox>
      <p-checkbox
        formControlName="pets"
        id="pets"
        [binary]="true"
        label="Pets"
      ></p-checkbox>
      <p-checkbox
        formControlName="pool"
        id="pool"
        [binary]="true"
        label="Pool"
      ></p-checkbox>
      <p-checkbox
        formControlName="washer_dryer"
        id="washer_dryer"
        [binary]="true"
        label="Washer / Dryer"
      ></p-checkbox>
      <p-checkbox
        formControlName="wood_floors"
        id="wood_floors"
        [binary]="true"
        label="Wood Flooring"
      ></p-checkbox>
    </div>
  </section>
  <section id="youtube">
    <label>YouTube Videos</label>
    <label class="p-field p-fluid">
      <p-chips formControlName="video_urls" separator=","></p-chips>
    </label>
  </section>
  <section id="images" *ngIf="images$ | async as images">
    <div>
      <p>SMS Image:</p>
      <p-avatar
        [image]="formPublic.get('image_jpeg')?.value"
        size="xlarge"
      ></p-avatar>
    </div>

    <p-fileUpload
      chooseIcon="{{
        (isUploading$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-upload'
      }}"
      chooseLabel="Upload Images"
      [disabled]="(isUploading$ | async) === true"
      [multiple]="true"
      mode="basic"
      accept="image/*"
      [maxFileSize]="8000000"
      [customUpload]="true"
      [auto]="true"
      (uploadHandler)="onFileSelected($event)"
      [style]="{ marginBottom: '4px' }"
    ></p-fileUpload>
    <p-button
      label="Download Images"
      icon="pi pi-cloud-download"
      iconPos="left"
      (onClick)="openDownloadImagesModal()"
    >
    </p-button>
    <p-orderList [value]="images" [dragdrop]="true" (onReorder)="onReorder()">
      <ng-template let-zipped pTemplate="item">
        <div style="display: flex; gap: 10px">
          <img
            [ngStyle]="{ width: zipped.expanded ? '100%' : '75px' }"
            src="{{ zipped.image_urls_600 }}"
            alt="Uploaded image"
          />
          <p-button
            [icon]="
              !zipped.expanded ? 'pi pi-search-plus' : 'pi pi-search-minus'
            "
            (click)="zipped.expanded = !zipped.expanded"
          ></p-button>
          <p-button
            icon="pi pi-trash"
            styleClass="p-button-danger"
            (onClick)="deleteImage(zipped.images)"
          ></p-button>
        </div>
      </ng-template>
    </p-orderList>
    <p-message
      severity="info"
      text="Click to expand. Drag to re-order."
    ></p-message>
  </section>
  <section id="streetview">
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem 4px;
        padding-top: 1.5rem;
      "
    >
      <p-button
        type="button"
        label="Reset"
        icon="pi pi-undo"
        (click)="resetStreetView()"
      ></p-button>
      <span class="p-float-label">
        <p-inputNumber
          formControlName="street_view_longitude"
          id="street_view_longitude"
          [showButtons]="true"
          buttonLayout="horizontal"
          [maxFractionDigits]="10"
          [step]="0.0004"
        ></p-inputNumber>
        <label for="address">Longitude (East / West)</label>
      </span>
      <span class="p-float-label">
        <p-inputNumber
          formControlName="street_view_latitude"
          id="street_view_latitude"
          [showButtons]="true"
          buttonLayout="horizontal"
          [maxFractionDigits]="10"
          [step]="0.0004"
        ></p-inputNumber>
        <label for="address">Latitude (North / South)</label>
      </span>
      <p-button
        (click)="streetViewPanorama?.setVisible(true)"
        label="Show Street View"
      ></p-button>
    </div>
    <ng-container *ngIf="isMapLoaded$ | async">
      <google-map
        #streetView
        height="600px"
        width="100%"
        [center]="(streetViewCenter$ | async) || { lat: 40.7, lng: -73.9 }"
        [hidden]="(isMapLoaded$ | async) !== true"
        [options]="{ disableDefaultUI: true }"
      ></google-map>
    </ng-container>
  </section>
  <section id="footer">
    <button
      pButton
      pRipple
      (click)="navigateToDashboard()"
      icon="pi pi-eye"
      label="Active Pads"
    ></button>
    <button
      pButton
      pRipple
      (click)="close()"
      label="Close"
      icon="pi pi-times"
    ></button>
    <button
      pButton
      pRipple
      (click)="save()"
      label="Save"
      [icon]="
        (isSaving$ | async) === false ? 'pi pi-save' : 'pi pi-spin pi-spinner'
      "
      [disabled]="formPublic.invalid || formAdmin.invalid"
    ></button>
  </section>
</form>
