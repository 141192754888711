<nav>
  <ul>
    <li><a href="mailto:support@padspin.com">Contact</a></li>
    <li><a routerLink="/faq">FAQ</a></li>
    <li><a routerLink="/terms-of-service">Terms</a></li>
    <!--    <li><a href="https://www.indeed.com/cmp/Padspin">Careers</a></li>-->
    <li><a href="https://www.indeed.com/cmp/Padspin-2">Careers</a></li>
    <li><a href="https://medium.com/@Padspin">Blog</a></li>
    <!--    <li><a routerLink="Investor Relations">Investor Relations</a></li>-->
  </ul>
</nav>
<!-- <ul>
  <li>
    <a
      href="https://www.facebook.com/padspin/"
      (click)="onClickSocialMedia('facebook')"
      target="_blank"
      ><img
        src="/assets/web/icons/facebook_white_30dp.svg"
        alt="Follow Padspin on Facebook"
    /></a>
  </li>
  <li>
    <a
      href="https://instagram.com/padspinapp?utm_medium=site"
      (click)="onClickSocialMedia('instagram')"
      target="_blank"
      ><img
        src="/assets/web/icons/instagram_white_30dp.svg"
        alt="Follow Padspin on Instagram"
    /></a>
  </li>
  <li>
    <a
      href="https://twitter.com/padspinapp"
      (click)="onClickSocialMedia('twitter')"
      target="_blank"
      ><img
        src="/assets/web/icons/twitter_white_30dp.svg"
        alt="Follow Padspin on Twitter"
    /></a>
  </li>
  <li>
    <a
      href="https://www.reddit.com/user/padspin/"
      (click)="onClickSocialMedia('reddit')"
      target="_blank"
      ><img
        src="/assets/web/icons/reddit_white_30dp.svg"
        alt="Follow Padspin on Reddit"
    /></a>
  </li>
  <li>
    <a
      href="https://www.youtube.com/channel/UCmGNkNYa-CbH9kzEpJ5AkiA"
      (click)="onClickSocialMedia('youtube')"
      target="_blank"
      ><img
        src="/assets/web/icons/youtube_white_30dp.svg"
        alt="Follow Padspin on Youtube"
    /></a>
  </li>
  <li>
    <a
      href="https://www.tiktok.com/@padspin"
      (click)="onClickSocialMedia('tiktok')"
      target="_blank"
      ><img
        src="/assets/web/icons/tiktok_white_30dp.svg"
        alt="Follow Padspin on Tiktok"
    /></a>
  </li>
  <li>
    <a
      href="https://www.linkedin.com/company/padspin"
      (click)="onClickSocialMedia('linkedin')"
      target="_blank"
      ><img
        src="/assets/web/icons/linkedin_white_30dp.svg"
        alt="Follow Padspin on Linkedin"
    /></a>
  </li>
</ul> -->
<!-- <p>Together we can change the way people find homes.</p> -->
<div class="copyright">
  <p>
    Copyright © 2023 Padspin ·
    <span>Born And Raised In NYC</span>
  </p>
</div>
