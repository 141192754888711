import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '../header.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { startOfDay } from 'date-fns';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  shareReplay,
  skipUntil,
  startWith,
  Subject,
} from 'rxjs';
import { getDownloadURL, UploadResult } from '@angular/fire/storage';
import { UuidService } from '../uuid.service';
import { ListingImageService } from '../listing-image.service';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AuthService } from '../auth.service';
import {
  listings_name,
  ListingsModel,
  PadType,
  UserProfileID,
} from '@padspin/models';
import { AngularGeofireService } from '../search-page/angular-geofire.service';
import { LatLngLiteral } from '../search-page/lat-lng-literal';
import { doc, getFirestore, setDoc } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ProfileService } from '../profile.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewportScroller } from '@angular/common';
import { MapLoadingService } from '../map-loading.service';
import { isYouTubeLink } from '../validators/validators';
import { AdminPostOutput } from './post-migration.module';
import { FooterService } from '../footer.service';

import { GoogleAnalyticsService } from '../google-analytics.service';
import { PixelService } from 'ngx-pixel';

export const typeOptions: { label: string; value: PadType }[] = [
  { label: 'Apartment', value: 'apartment' },
  { label: 'Condo', value: 'condo' },
  { label: 'Co-op', value: 'co-op' },
  { label: 'House', value: 'house' },
  { label: 'Town house', value: 'townhouse' },
];

export const bedroomOptions = [
  { label: 'Studio', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4+', value: 4 },
];

@Component({
  selector: 'padspin-post-a-pad-two',
  templateUrl: './post-a-pad-two.component.html',
  styleUrls: ['./post-a-pad-two.component.scss'],
})
export class PostAPadTwoComponent implements OnInit, OnDestroy, AfterViewInit {
  #destroy$ = new Subject<void>();
  isUploadingFiles$ = new BehaviorSubject<boolean>(false);
  isSubmitting$ = new BehaviorSubject<boolean>(false);

  readonly listingId = this.uuid.v6();

  form: FormGroup = this.fb.group({
    // Poster Information
    user_relation: ['owner', Validators.required],
    // Details
    type: ['apartment', Validators.required],
    rent_amount: [
      0,
      Validators.compose([Validators.required, Validators.min(1)]),
    ],
    bedrooms: [0, Validators.required],
    bathrooms: [1, Validators.required],
    area: [null],
    earliest_move_in_date: [startOfDay(Date.now()), Validators.required],
    description: ['', Validators.required],
    floor: ['1'],
    // Address
    address: ['', Validators.required],
    city: ['', Validators.required],
    unit_number: ['', this.unitNumberValidator],
    state: ['', Validators.required],
    postal_code: ['', Validators.required],
    // Amenities
    central_air: [false],
    dishwasher: [false],
    doorman: [false],
    elevator: [false],
    fireplace: [false],
    gym: [false],
    high_ceilings: [false],
    outdoor: [false],
    parking: [false],
    pets: [false],
    pool: [false],
    video_url: [null, isYouTubeLink],
    washer_dryer: [false],
    wood_floors: [false],
  });

  typeOptions: { label: string; value: PadType }[] = typeOptions;

  bedroomOptions = bedroomOptions;

  startOfToday = startOfDay(Date.now());

  /** Array of [imageId, imageURL, File, UploadResult] */
  uploadedFilesMap$ = new BehaviorSubject<
    Array<{
      imageId: string;
      imageUrl: string;
      file: File;
      uploadResult: UploadResult;
    }>
  >([]);
  currentFilesInvalid$: Observable<boolean> = this.uploadedFilesMap$.pipe(
    map((a) => a.length === 0)
  );

  addressChanges$: Observable<string> = combineLatest([
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    this.form.get('address')!.valueChanges,
    this.form.get('city')!.valueChanges,
    this.form.get('state')!.valueChanges,
    this.form.get('postal_code')!.valueChanges,
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  ]).pipe(
    debounceTime(1000),
    map(([address, city, state, postal_code]) => ({
      address,
      city,
      state,
      postal_code,
      unit_number: this.form.get('unit_number')?.value,
    })),
    map(
      (address) =>
        `${address.address} ${address.city} ${address.state} ${address.postal_code}`
    )
  );

  addressCoords$: Observable<LatLngLiteral> = this.addressChanges$.pipe(
    skipUntil(this.mapLoadingService.isMapLoaded$),
    debounceTime(1000),
    switchMap((address) => this.geofireService.getCoordsForAddress(address))
  );

  geoHash$: Observable<string> = this.addressCoords$.pipe(
    map((addressLatLng) =>
      this.geofireService.geoHashForLocation(addressLatLng)
    )
  );

  placeId$: Observable<string> = this.addressChanges$.pipe(
    switchMap((address) => this.geofireService.getPlaceIDForAddress(address))
  );

  placeNames$: Observable<string[]> = this.addressChanges$.pipe(
    tap((input) => console.log(`Fetching place names for ${input}`)),
    switchMap((address: string) =>
      this.geofireService.getPlaceNamesForAddress(address)
    )
  );

  profile$: Observable<UserProfileID> =
    this.profileService.currentProfile$.pipe(
      filter((maybeProfile): maybeProfile is UserProfileID => !!maybeProfile),
      takeUntil(this.#destroy$)
    );

  listing$: Observable<ListingsModel> = combineLatest([
    this.addressCoords$,
    this.geoHash$,
    this.placeId$,
    this.profile$,
    this.uploadedFilesMap$,
    this.placeNames$,
  ]).pipe(
    map(
      ([addressLatLng, geohash, place_id, profile, uploaded, place_names]) => ({
        addressLatLng,
        geohash,
        place_id,
        profile,
        uploaded,
        place_names,
      })
    ),
    map(
      ({ addressLatLng, geohash, place_id, profile, uploaded, place_names }) =>
        <ListingsModel>{
          address: this.form.get('address')?.value,
          area: this.form.get('area')?.value,
          bathrooms: this.form.get('bathrooms')?.value,
          bedrooms: this.form.get('bedrooms')?.value,
          central_air: this.form.get('central_air')?.value,
          created: new Date(),
          city: this.form.get('city')?.value,
          description: this.form.get('description')?.value,
          dishwasher: this.form.get('dishwasher')?.value,
          doorman: this.form.get('doorman')?.value,
          elevator: this.form.get('elevator')?.value,
          fireplace: this.form.get('fireplace')?.value,
          floor: this.form.get('floor')?.value,
          geohash,
          gym: this.form.get('gym')?.value,
          high_ceilings: this.form.get('high_ceilings')?.value,
          images: uploaded.map((img) => img.imageId),
          image_urls_200: uploaded.map((up) => `${up.imageUrl}_200x200`),
          image_urls_600: uploaded.map((up) => `${up.imageUrl}_600x600`),
          image_urls_1200: uploaded.map((up) => `${up.imageUrl}_1200x1200`),
          image_urls_original: uploaded.map((up) => up.imageUrl),
          is_active: false,
          is_exclusive: true,
          latitude: addressLatLng.lat,
          longitude: addressLatLng.lng,
          street_view_latitude: addressLatLng.lat,
          street_view_longitude: addressLatLng.lng,
          available_from_date: this.form.get('earliest_move_in_date')?.value,
          outdoor: this.form.get('outdoor')?.value,
          parking: this.form.get('parking')?.value,
          pets: this.form.get('pets')?.value,
          place_id,
          // This needs to be done on the backend
          place_ids: [],
          place_names,
          pool: this.form.get('pool')?.value,
          postal_code: this.form.get('postal_code')?.value,
          rent_amount: this.form.get('rent_amount')?.value,
          require_other: [],
          slug: this.uuid.slug(),
          state: this.form.get('state')?.value,
          type: this.form.get('type')?.value,
          unit_number: this.form.get('unit_number')?.value,
          updated: new Date(),
          user_email: profile.email,
          user_id: profile.id,
          user_phone: profile.phone,
          user_relation: this.form.get('user_relation')?.value,
          video_urls: [this.form.get('video_url')?.value],
          washer_dryer: this.form.get('washer_dryer')?.value,
          wood_floors: this.form.get('wood_floors')?.value,
          require_tenants_combined_min_income_per_person: null,
          acquisition_source: null,
          cross_street: null,
          image_jpeg: null,
          legacyID: null,
          neighborhood: null,
          require_guarantor_count: null,
          require_guarantor_min_credit: null,
          require_guarantor_min_income: null,
          require_tenant_max_income: null,
          require_tenant_min_credit_score: null,
          require_tenant_min_income: null,
          require_tenants_combined_max_income_per_person: null,
        }
    ),
    tap((listing) => console.log({ geohash: listing.geohash })),
    shareReplay({ bufferSize: 1, refCount: false })
  );

  listingInvalid$: Observable<boolean> = this.listing$.pipe(
    startWith(true),
    map((listing) => !listing)
  );

  constructor(
    private readonly header: HeaderService,
    private readonly fb: FormBuilder,
    private readonly uuid: UuidService,
    private readonly listingImageService: ListingImageService,
    private readonly authService: AuthService,
    private readonly geofireService: AngularGeofireService,
    private readonly dialog: MatDialog,
    private readonly profileService: ProfileService,
    private readonly router: Router,
    private readonly matSnackBar: MatSnackBar,
    private readonly viewport: ViewportScroller,
    private readonly mapLoadingService: MapLoadingService,
    private readonly footer: FooterService,
    private readonly analytics: GoogleAnalyticsService,
    private readonly pixel: PixelService
  ) {
    this.footer.setVisibility(false);
    this.form.get('video_url')?.valueChanges.subscribe((video_url) => {
      console.log(video_url);
    });
  }

  post = async (firestore = getFirestore()): Promise<void> => {
    this.analytics.log('submit_post_a_pad_form');
    try {
      this.pixel.trackCustom('conversion', {
        route: '/post-a-pad/confirm',
      });
    } catch (err) {
      console.log(
        `Error on submitting event submit_post_a_pad_form to facebook`
      );
    }
    this.analytics.gtag('event', 'conversion', {
      send_to: 'AW-10987733817/BFfvCK6B5qgYELmGrvco',
    });
    try {
      this.isSubmitting$.next(true);
      const currentUser = await firstValueFrom(this.authService.currentUser$);
      if (!currentUser) {
        await this.authService.attemptLogin({
          type:
            this.form.get('user_relation')?.value !== 'lease_owner'
              ? 'landlord'
              : 'tenant',
        });
      }
      let listing: ListingsModel;
      try {
        listing = await firstValueFrom(this.listing$);
      } catch (error: unknown) {
        console.warn('unable to get listing because of error', error);
        if (
          error instanceof Error &&
          error?.message.includes('Unable to understand address')
        ) {
          this.matSnackBar.open('Fix the address before posting', 'OK');
        }
        return;
      }
      // Wait for account to be created before we can save the listing
      await firstValueFrom(this.authService.currentUser$);
      await new Promise((r) => setTimeout(r, 200));
      const docRef = doc(firestore, `${listings_name}/${this.listingId}`);
      await setDoc(docRef, listing, { merge: true });
      this.router.navigate(['/post-a-pad/confirm']);
    } catch (e) {
      console.log('Failed to post listing. Error:', e);
      this.matSnackBar.open('Failed to post listing', 'OK', { duration: 3 });
    } finally {
      this.isSubmitting$.next(false);
    }
  };

  /** Immediately upload any images that haven't been uploaded yet */
  onFileSelected = async (event: { files?: File[] }): Promise<void> => {
    this.isUploadingFiles$.next(true);
    try {
      if (!event.files) {
        return;
      }
      const previous = this.uploadedFilesMap$.value;
      const next: typeof previous = [];
      for (const file of event.files) {
        const previousUploaded = previous.find(
          (uploaded) => uploaded.file.name === file.name
        );
        if (previousUploaded) {
          next.push(previousUploaded);
        } else {
          const imageId = this.uuid.v6();
          const uploadResult =
            await this.listingImageService.uploadListingImage(
              file,
              this.listingId,
              imageId
            );
          const imageUrl = await getDownloadURL(uploadResult.ref);
          next.push({ imageId, imageUrl, file, uploadResult });
        }
        this.uploadedFilesMap$.next(next);
      }
    } finally {
      this.isUploadingFiles$.next(false);
    }
  };

  async migrate(listing: AdminPostOutput): Promise<void> {
    this.form.get('address')?.setValue(listing.address || null);
    this.form.get('area')?.setValue(listing.area || null);
    this.form.get('bathrooms')?.setValue(listing.bathrooms || null);
    this.form.get('bedrooms')?.setValue(listing.bedrooms || null);
    this.form.get('central_air')?.setValue(listing.central_air || null);
    this.form.get('city')?.setValue(listing.city || null);
    this.form.get('description')?.setValue(listing.description || null);
    this.form.get('dishwasher')?.setValue(listing.dishwasher || null);
    this.form.get('doorman')?.setValue(listing.doorman || null);
    this.form.get('elevator')?.setValue(listing.elevator || null);
    this.form.get('fireplace')?.setValue(listing.fireplace || null);
    this.form.get('floor')?.setValue(listing.floor || null);
    this.form.get('gym')?.setValue(listing.gym || null);
    this.form.get('high_ceilings')?.setValue(listing.high_ceilings || null);
    this.form
      .get('earliest_move_in_date')
      ?.setValue(listing.earliest_move_in_date || null);
    this.form.get('outdoor')?.setValue(listing.outdoor || null);
    this.form.get('parking')?.setValue(listing.parking || null);
    this.form.get('pets')?.setValue(listing.pets || null);
    this.form.get('pool')?.setValue(listing.pool || null);
    this.form.get('postal_code')?.setValue(listing.postal_code || null);
    this.form.get('rent_amount')?.setValue(listing.rent_amount || null);
    this.form.get('state')?.setValue(listing.state || null);
    this.form.get('type')?.setValue(listing.type || null);
    this.form.get('unit_number')?.setValue(listing.unit_number || null);
    this.form.get('user_relation')?.setValue(listing.user_relation || null);
    this.form.get('video_url')?.setValue(listing.video_url || null);
    this.form.get('washer_dryer')?.setValue(listing.washer_dryer || null);
    this.form.get('wood_floors')?.setValue(listing.wood_floors || null);
  }

  ngOnInit(): void {
    this.header.setType('landlord');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        console.log('scroll to top');
      }
    });
  }

  ngAfterViewInit(): void {
    // This is a hack. Without this, the component loads mid-way down the page.
    setTimeout(() => this.viewport.scrollToPosition([0, 0]), 1);
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
  unitNumberValidator(control: AbstractControl): ValidationErrors | null {
    const type: string | undefined = control.parent?.get('type')?.value;
    if (!type) {
      return { invalidForm: 'No Parent' };
    }
    if (typeof control.value !== 'string' || control.value.length > 0) {
      return null;
    }
    if (type === 'apartment') {
      return { required: 'Apartments must have unit numbers' };
    } else if (type === 'condo') {
      return { required: 'Condos must have unit numbers' };
    } else if (type === 'townhouse') {
      return { required: 'Town houses must have a unit number' };
    }
    return null;
  }
}
