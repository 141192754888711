import {
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import {
  BehaviorSubject,
  defer,
  distinctUntilChanged,
  firstValueFrom,
  Observable,
  ReplaySubject,
  shareReplay,
  startWith,
  Subject,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Listings2Service } from '../listings-2.service';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  isListingContactEqual,
  ListingContact,
  ListingContactTypes,
  ListingsAdminModel,
  ListingsModel,
  ListingsModelID,
} from '@padspin/models';
import {
  emailValidator,
  isArrayOfYouTubeLinksValidator,
  phoneValidator,
} from '../validators/validators';
import { typeOptions } from '../post-a-pad-two/post-a-pad-two.component';
import { ListingImageService } from '../listing-image.service';
import { LoadingService } from '../loading.service';
import { FileUpload } from 'primeng/fileupload';
import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { MapLoadingService } from '../map-loading.service';
import { Router } from '@angular/router';
import { FirestoreService } from '../firestore.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleMap } from '@angular/google-maps';
import { ProfileService } from '../profile.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  listing_post_to_external_platform_fn_name,
  ListingPostToExternalPlatformRequestData,
  ListingPostToExternalPlatformResponse,
} from '@padspin/function-types';
import { SendApplicationModalComponent } from '../send-application-modal/send-application-modal.component';
import { DownloadImagesModalComponent } from '../download-images-modal/download-images-modal.component';
import { DefaultContactFormValidator } from './default-contact-form-validator.service';

export type ZippedListingImages = Record<
  | 'images'
  | 'image_urls_200'
  | 'image_urls_600'
  | 'image_urls_1200'
  | 'image_urls_original',
  string
>;
export type ListingEditModalData = {
  /**
   * @example 1ecb128c-d88d-6e30-b14e-17a854fba417
   * @example uKv-k7
   */
  listingIdOrSlug?: string;
};

/**
 * @todo: There are a number of issues with the contacts (and the listing-edit-
 * contact component) that are the result of improper change detection.
 */
@Component({
  selector: 'padspin-listing-edit-modal',
  templateUrl: './listing-edit-modal.component.html',
  styleUrls: ['./listing-edit-modal.component.scss', '../primeflex.scss'],
  providers: [DefaultContactFormValidator],
})
export class ListingEditModalComponent implements OnInit {
  #destroy$ = new Subject<void>();

  @ViewChild(FileUpload) fileUpload!: FileUpload;
  isUploading$ = new ReplaySubject<boolean>(1);
  isSaving$ = new BehaviorSubject<boolean>(false);
  @ViewChild('streetView') streetViewMap!: GoogleMap;
  streetViewPanorama?: google.maps.StreetViewPanorama;

  #publicControlsConfig: Record<keyof ListingsModel, unknown> = {
    images: [[], Validators.minLength(1)],
    image_urls_1200: [[], Validators.minLength(1)],
    image_urls_600: [[], Validators.minLength(1)],
    image_urls_200: [[], Validators.minLength(1)],
    image_urls_original: [[], Validators.minLength(1)],
    image_jpeg: [null],
    video_urls: [[], isArrayOfYouTubeLinksValidator],
    area: [null],
    address: [null, Validators.required],
    acquisition_source: [null],
    bathrooms: [null, Validators.min(1)],
    bedrooms: [null, Validators.min(0)],
    central_air: [null],
    city: [null, Validators.required],
    created: [],
    cross_street: [null],
    description: [null, Validators.required],
    dishwasher: [null],
    doorman: [null],
    elevator: [null],
    fireplace: [null],
    floor: [null, Validators.required],
    geohash: [null, Validators.required],
    place_id: [null, Validators.required],
    gym: [null],
    high_ceilings: [null],
    is_active: [null],
    is_exclusive: [null],
    latitude: [null, Validators.required],
    legacyID: [null],
    street_view_latitude: [null],
    street_view_longitude: [null],
    longitude: [null, Validators.required],
    available_from_date: [null, Validators.required],
    place_names: [null],
    neighborhood: [null],
    place_ids: [null],
    outdoor: [null],
    parking: [null],
    pets: [null],
    pool: [null],
    postal_code: [null, Validators.required],
    rent_amount: [null, Validators.min(0)],
    slug: [null],
    state: [null, Validators.required],
    type: [null, Validators.required],
    unit_number: [null],
    updated: [this.firestore.serverTimestamp()],
    user_id: [null, Validators.required],
    user_phone: [null, phoneValidator],
    user_email: [null, emailValidator],
    user_relation: [null, Validators.required],
    washer_dryer: [null],
    wood_floors: [null],
    require_tenant_min_income: [null],
    require_tenant_max_income: [null],
    require_tenant_min_credit_score: [null],
    require_tenants_combined_min_income_per_person: [null],
    require_tenants_combined_max_income_per_person: [null],
    require_guarantor_count: [null],
    require_guarantor_min_credit: [null],
    require_guarantor_min_income: [null],
    require_other: [null],
    slugFriendly: [null],
  };
  #adminControlsConfig: Record<keyof ListingsAdminModel, unknown> = {
    notes: [null],
    owner: [null],
    property_manager: [null],
    lease_owner: [null],
    supervisor: [null],
    access: [null],
    lockbox: [null],
    lease_start_date: [null],
    account_manager_email: [null, emailValidator],
    slug: [null],
    default_contact: [null, Validators.required],
  };
  formPublic: FormGroup = this.fb.group(this.#publicControlsConfig);
  formAdmin: FormGroup = this.fb.group(this.#adminControlsConfig);
  formDefaultContact = this.fb.group(
    {
      default: ['owner', Validators.required],
    },
    {
      updateOn: 'change',
      asyncValidators: [
        this.defaultContactFormValidator.doesDefaultContactSelectionMatchContact(
          this.formAdmin
        ),
      ],
    }
  );
  listingId$ = new ReplaySubject<string>(1);
  listingChanges$ = this.listingId$.pipe(
    switchMap((id) => this.listings2.getListingChangesByID$(id)),
    tap((listing: ListingsModelID) => {
      const patch = {
        ...listing,
        updated: new Date(),
      };
      this.formPublic.patchValue(patch);
      this.images$.next(this.zip(listing));
    }),
    shareReplay({ bufferSize: 1, refCount: false })
  );
  listingSlug$ = this.listingChanges$.pipe(
    map((listing) => listing.slug),
    shareReplay({ refCount: false, bufferSize: 1 })
  );
  listingSlugFriendly$ = this.listingChanges$.pipe(
    map((listing) => listing.slugFriendly),
    shareReplay({ refCount: false, bufferSize: 1 })
  );
  listingCreated$: Observable<Date> = this.listingChanges$.pipe(
    map((listing: Pick<ListingsModelID, 'created'>) => listing.created),
    map((maybeTimestamp: Date | Timestamp | FieldValue | null) =>
      maybeTimestamp && maybeTimestamp instanceof Timestamp
        ? maybeTimestamp.toDate()
        : maybeTimestamp instanceof Date
        ? maybeTimestamp
        : new Date()
    )
  );
  listingCreatedByProfile$ = this.listingChanges$.pipe(
    map((listing) => listing.user_id),
    distinctUntilChanged(),
    switchMap((uid) => this.profile.getProfileByUID(uid)),
    takeUntil(this.#destroy$),
    shareReplay({ bufferSize: 1, refCount: false })
  );
  profileFirstName$ = this.listingCreatedByProfile$.pipe(
    map((profile) => profile?.first_name)
  );
  profileLastName$ = this.listingCreatedByProfile$.pipe(
    map((profile) => profile?.last_name)
  );
  private _profileLastNameSub = this.profileLastName$
    .pipe(
      tap(() => {
        setTimeout(() => this.cd.detectChanges(), 1);
      }),
      takeUntil(this.#destroy$)
    )
    .subscribe();

  listingAdminChanges$: Observable<ListingsAdminModel> = this.listingId$.pipe(
    switchMap((id) => this.listings2.getListingAdminChangesByID$(id)),
    tap((la: ListingsAdminModel) => {
      if (la.default_contact) {
        this.formDefaultContact
          .get('default')
          ?.setValue(la.default_contact.type, { emitEvent: false });
      }
    }),
    tap((listingAdmin) => this.formAdmin.patchValue(listingAdmin)),
    takeUntil(this.#destroy$),
    shareReplay({ bufferSize: 1, refCount: false })
  );
  private _listingAdminChangesSub = this.listingAdminChanges$
    .pipe(takeUntil(this.#destroy$))
    .subscribe();

  private _formAdminSub = this.formAdmin.valueChanges
    .pipe(
      tap((fa) => {
        // When the admin form is changed, ensure the `default_contact`
        // is copied from the correct contact, and that the formDefaultContact is updated
        const type: ListingContactTypes | null =
          this.formDefaultContact.get('default')?.value || null;
        let default_contact: ListingContact | null = null;
        if (!type) {
          return;
        }
        if (!fa[type]) {
          return;
        }
        default_contact = fa[type];
        const existing_default_contact =
          this.formAdmin.get('default_contact')?.value;
        if (!isListingContactEqual(existing_default_contact, default_contact)) {
          this.formAdmin
            .get('default_contact')
            ?.setValue(default_contact, { emitEvent: true });
          setTimeout(() => this.cd.detectChanges(), 1);
        }
      }),
      tap(() => {
        // force revalidation of default contact radio buttons
        this.formDefaultContact.updateValueAndValidity({ emitEvent: true });
      }),
      takeUntil(this.#destroy$)
    )
    .subscribe();

  private _formDefaultContactSub = this.formDefaultContact.valueChanges
    .pipe(
      map((form: { default: string }) => form.default),
      filter((dc: string): dc is ListingContactTypes =>
        ['owner', 'property_manager', 'lease_owner', 'supervisor'].includes(dc)
      ),
      tap((dc) => {
        // When the default contact is changed, ensure it is copied to the formAdmin
        const contact: ListingContact | null =
          this.formAdmin.get(dc)?.value || null;
        this.formAdmin
          .get('default_contact')
          ?.setValue(contact, { emitEvent: false });
      }),
      takeUntil(this.#destroy$)
    )
    .subscribe();

  listingIsNotLegacy$: Observable<boolean> = this.listingId$.pipe(
    map((id) => isNaN(Number(id)))
  );

  typeOptions = typeOptions;

  /** Zip images of the property together to maintain order */
  images$: ReplaySubject<ZippedListingImages[]> = new ReplaySubject<
    ZippedListingImages[]
  >(1);

  isMapLoaded$: Observable<boolean> = this.mapLoaded.isMapLoaded$;
  center$: Observable<google.maps.LatLngLiteral> =
    this.formPublic.valueChanges.pipe(
      startWith({ latitude: 40.7, longitude: -73.9 }),
      map((value) => ({
        lat: Number(value.latitude) || 40.7,
        lng: Number(value.longitude) || -73.9,
      }))
    );
  streetViewCenter$: Observable<google.maps.LatLngLiteral> =
    this.formPublic.valueChanges.pipe(
      withLatestFrom(this.isMapLoaded$),
      map(([form, _loaded]) => {
        const lat = Number(form.street_view_latitude);
        const lng = Number(form.street_view_longitude);
        return { lat, lng };
      }),
      tap((position) => {
        this.streetViewMap.center = position;
        if (!this.streetViewPanorama) {
          this.streetViewPanorama = this.streetViewMap.getStreetView();
          this.streetViewPanorama.setOptions({
            position,
            pov: { heading: 0, pitch: 7 },
            disableDefaultUI: true,
            enableCloseButton: false,
          });
        }
        this.streetViewPanorama.setOptions({ position });
      })
    );

  enabled$: Observable<boolean> = defer(() =>
    this.formPublic.valueChanges.pipe(map((form) => form.is_active))
  );

  isLoadingFacebook$ = new BehaviorSubject<boolean>(false);
  isLoadingCraigslist$ = new BehaviorSubject<boolean>(false);
  isLoadingLinkedIn$ = new BehaviorSubject<boolean>(false);
  isLoadingTwitter$ = new BehaviorSubject<boolean>(false);
  isLoadingApartmentsDotCom$ = new BehaviorSubject<boolean>(false);
  isLoadingZillow$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly dialogRef: MatDialogRef<ListingEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: ListingEditModalData,
    private readonly listings2: Listings2Service,
    private readonly fb: FormBuilder,
    private readonly images: ListingImageService,
    private readonly loading: LoadingService,
    private readonly mapLoaded: MapLoadingService,
    public readonly router: Router,
    private readonly firestore: FirestoreService,
    private readonly matSnackBar: MatSnackBar,
    private readonly profile: ProfileService,
    private readonly aff: AngularFireFunctions,
    private readonly cd: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly zone: NgZone,
    private readonly defaultContactFormValidator: DefaultContactFormValidator
  ) {
    this.isUploading$
      .pipe(
        tap((isUploading) =>
          isUploading ? this.loading.show() : this.loading.hide()
        ),
        takeUntil(this.#destroy$)
      )
      .subscribe();
  }

  async postToExternalPlatform(
    platform: ListingPostToExternalPlatformRequestData['platform']
  ) {
    const isLoading$ =
      platform === 'facebook'
        ? this.isLoadingFacebook$
        : platform === 'linkedin'
        ? this.isLoadingLinkedIn$
        : platform === 'craigslist'
        ? this.isLoadingCraigslist$
        : platform === 'twitter'
        ? this.isLoadingTwitter$
        : platform === 'apartments_dot_com'
        ? this.isLoadingApartmentsDotCom$
        : this.isLoadingZillow$;
    isLoading$.next(true);
    const callable$ = this.aff.httpsCallable<
      ListingPostToExternalPlatformRequestData,
      ListingPostToExternalPlatformResponse
    >(listing_post_to_external_platform_fn_name);
    const slug = await firstValueFrom(this.listingSlug$);
    const result = await firstValueFrom(callable$({ platform, slug }));
    console.log({ result });
    isLoading$.next(false);
  }

  onReorder = async () => {
    const zipped: ZippedListingImages[] = await firstValueFrom(this.images$);
    const patch: Partial<ListingsModel> = this.unzip(zipped);
    this.formPublic.patchValue(patch);
  };

  onFileSelected = async (event: { files?: File[] }): Promise<void> => {
    this.isUploading$.next(true);
    try {
      if (!event.files) {
        return;
      }
      const listingId = await firstValueFrom(this.listingId$);
      for (const file of event.files) {
        await firstValueFrom(
          this.listings2.uploadImageAndAddToListingByID$(file, listingId)
        );
      }
    } finally {
      this.fileUpload.clear();
      this.isUploading$.next(false);
    }
  };

  deleteImage = async (imageId: string) => {
    const listingId = await firstValueFrom(this.listingId$);
    await firstValueFrom(this.listings2.deleteImage$(imageId, listingId));
  };

  save = async () => {
    this.loading.show();
    this.isSaving$.next(true);
    try {
      const listingId = await firstValueFrom(this.listingId$);
      const listingPublic: Partial<ListingsModel> = this.formPublic.value;
      if (!listingPublic.address) {
        this.matSnackBar.open('Listing corrupted, cannot save', 'OK', {
          duration: 10_000,
        });
        return;
      }
      console.log(`writing data to listing`, this.formPublic.value);
      await this.savePublicListing(listingId, listingPublic);
      const listingAdmin: Partial<ListingsAdminModel> = this.formAdmin.value;
      await this.saveAdminListing(listingId, listingAdmin);
    } catch (error) {
      console.warn('Failed to save listing', error);
    } finally {
      this.loading.hide();
      this.isSaving$.next(false);
    }
    // await this.close();
  };

  close = async () => {
    const slug = await firstValueFrom(this.listingSlug$);
    const slugFriendly = await firstValueFrom(this.listingSlugFriendly$);
    this.dialogRef.close();
    const isActive: boolean = this.formPublic.get('is_active')?.value;

    await this.router.navigate([
      isActive
        ? `no-fee-apartments-for-rent/${slug}/${slugFriendly}`
        : `l/${slug}`,
    ]);
  };

  navigateToDashboard = async () => {
    this.dialogRef.close({ doNotNavigate: true });
    await this.router.navigate([`/dashboard/admin/active-pads`]);
  };

  toggleActivation = async () => {
    const isActive: boolean = this.formPublic.get('is_active')?.value;
    const listingId = await firstValueFrom(this.listingId$);
    if (!isActive) {
      const listingSlug = await firstValueFrom(this.listingSlug$);
      const dequeued = await this.listings2.dequeueListingSMS(
        listingId,
        listingSlug
      );
      this.matSnackBar.open(
        `Dequeued ${dequeued} SMS's for the listing`,
        'OK',
        { duration: 10_000 }
      );
    }
  };

  savePublicListing = async (
    listingId: string,
    partialListing: Partial<ListingsModel>
  ): Promise<void> => {
    await firstValueFrom(
      this.listings2.updateListingByID$(listingId, partialListing)
    );
  };

  saveAdminListing = async (
    listingId: string,
    partialAdmin: Partial<ListingsAdminModel>
  ): Promise<void> => {
    await this.listings2.updateListingAdminByID(listingId, partialAdmin);
  };

  private zip = (listing: ListingsModel): ZippedListingImages[] => {
    if (!listing.images) {
      return [];
    }
    return listing.images.map((image, index) => ({
      images: image,
      image_urls_1200: listing.image_urls_1200[index],
      image_urls_200: listing.image_urls_200[index],
      image_urls_600: listing.image_urls_600[index],
      image_urls_original: listing.image_urls_original[index],
    }));
  };

  private unzip = (zipped: ZippedListingImages[]): Partial<ListingsModel> => {
    const unzipped: Partial<ListingsModel> = {
      images: [],
      image_urls_1200: [],
      image_urls_200: [],
      image_urls_600: [],
      image_urls_original: [],
    };
    zipped.forEach((zip) => {
      unzipped.images?.push(zip.images);
      unzipped.image_urls_1200?.push(zip.image_urls_1200);
      unzipped.image_urls_200?.push(zip.image_urls_200);
      unzipped.image_urls_600?.push(zip.image_urls_600);
      unzipped.image_urls_original?.push(zip.image_urls_original);
    });
    return unzipped;
  };

  listErrors(): string[] {
    const errors: string[] = [];
    if (this.formAdmin.invalid || this.formPublic.invalid) {
      Object.entries({
        ...this.formAdmin.controls,
        ...this.formPublic.controls,
      }).forEach(([key, ctrl]) => {
        if (ctrl.invalid) {
          errors.push(`${key}: ${JSON.stringify(ctrl.errors)}`);
        }
      });
    }
    return errors;
  }

  async ngOnInit(): Promise<void> {
    const listingIdOrSlug = this.data.listingIdOrSlug;
    const listing = await firstValueFrom(
      this.listings2.getListing$(listingIdOrSlug)
    );
    console.log(`listing`);
    console.log(listing);
    if (listing) {
      this.listingId$.next(listing.id);
    } else {
      console.warn('Listing does not exist');
    }
    this.formAdmin.updateValueAndValidity({ emitEvent: true });
    this.formDefaultContact.updateValueAndValidity({ emitEvent: true });
  }

  resetStreetView = () => {
    const { latitude, longitude } = this.formPublic.value;
    this.formPublic.get('street_view_latitude')?.setValue(latitude);
    this.formPublic.get('street_view_longitude')?.setValue(longitude);
  };

  async openApplicationModal() {
    const account_manager_email = this.formAdmin.get(
      'account_manager_email'
    )?.value;
    const lease_start_date = this.formAdmin.get('lease_start_date')?.value;
    const slug = await firstValueFrom(this.listingSlug$);
    let address = '';
    const unit_number: string = this.formPublic.get('unit_number')?.value;
    if (unit_number === '') {
      address = `${this.formPublic.get('address')?.value}`;
    } else {
      address = `${this.formPublic.get('address')?.value} Unit ${unit_number}`;
    }
    await this.zone.run(async () => {
      const dialogRef = this.dialog.open(SendApplicationModalComponent, {
        data: { lease_start_date, slug, address, account_manager_email },
        panelClass: 'modal_panel',
      });
      await dialogRef.afterClosed();
    });
  }

  async openDownloadImagesModal() {
    const listing = await firstValueFrom(this.listingChanges$);
    const dialogRef = this.dialog.open(DownloadImagesModalComponent, {
      data: { listing },
    });
    await dialogRef.afterClosed();
  }
}
