import { eventWithTime } from 'rrweb/typings/types';
import type { Timestamp, FieldValue } from '@angular/fire/firestore';
import { WithId } from './types/with-id';

export const recordingsModelName = 'recordings' as const;

export class RecordingsModel {
  /** Names of the files stored in Firebase Storage. */
  parts!: string[];
  /** Recording started at */
  timestamp!: FieldValue | Timestamp;
  /** User ID if the user was logged at the end of the recording */
  uid?: string;
}

/**
 * Model for the recordings in Firebase Storage (stored as stringified objects)
 */
export class RecordingsStorageModel {
  /** Start UNIX timestamp */
  timestamp!: number;
  /**
   * Stringified rrweb events
   * {@link eventWithTime}
   */
  events!: eventWithTime[];
  /** Analytic events contained in the recording */
  analytic_events!: string[];
  /** User's ID, if present */
  uid?: string;
  /** Navigation path where recording began */
  url!: string;
  /** Latitude */
  lat?: number;
  /** Longitude */
  lng?: number;
}

export type RecordingsModelID = WithId<RecordingsModel>;

export const convertEventToEventWithTime = (
  stringifiedEvent: string
): eventWithTime => {
  return JSON.parse(stringifiedEvent);
};
