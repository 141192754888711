/* eslint-disable no-control-regex */
import { Injectable } from '@angular/core';
import { Listing } from './listing';
import { BehaviorSubject, Observable, of, OperatorFunction, race } from 'rxjs';
import { MapService } from './map.service';
import { ElasticSearchService } from './elastic-search.service';
import { catchError, filter, first, map } from 'rxjs/operators';
import { Filter } from './filter';
import { ElasticSearchResponseDTO } from './elastic-search-response-dto';
import { ListingsModelID } from '@padspin/models';

export type Pad = Listing & { options: google.maps.MarkerOptions };

/** @deprecated */
@Injectable({
  providedIn: 'root',
})
export class ListingsService {
  /** Value of filter options */
  private filter$ = new BehaviorSubject<Filter>({});

  /** All listings that have been loaded, key is the pad's id */
  private allListings$ = new BehaviorSubject(new Map<string | number, Pad>());

  markerIcon = '/assets/web/icons/location-outlined.svg';
  markerIconHover = '/assets/web/icons/location-mouseover.svg';

  defaultMarkerOptions: google.maps.MarkerOptions = {
    label: {
      text: 'Price unavailable',
    },
    title: 'Address unavailable',
    clickable: true,
    icon: this.markerIcon,
  };

  constructor(
    private readonly ess: ElasticSearchService,
    private readonly mapSvc: MapService
  ) {}

  private getListingByIdFromCache$(id: string): Observable<Pad | undefined> {
    return this.allListings$.pipe(
      map((listingsMap) => {
        return listingsMap.get(id);
      }),
      filter((pad: Pad | undefined) => pad !== undefined) as OperatorFunction<
        Pad | undefined,
        Pad
      >
    );
  }

  private getListingByIdUsingFetch$(id: string): Observable<Pad | undefined> {
    return this.ess.searchByListingId(String(id)).pipe(
      map((esdto: ElasticSearchResponseDTO) => {
        const listings = this.ess.convertESDTOToListings(esdto);
        return listings.length > 0
          ? this.convertListingToPad(listings[0])
          : undefined;
      }),
      catchError(() => of(undefined))
    );
  }

  /**
   * Get a listing
   * @param id the listing id
   * @deprecated
   * @returns undefined if the pad does not exist.
   */
  getListingById$(id: string | number): Observable<Pad | undefined> {
    // The cache observable will not emit until it has a value that matches
    return race(
      this.getListingByIdFromCache$(String(id)),
      this.getListingByIdUsingFetch$(String(id))
    ).pipe(first());
  }

  /** This is to get legacy listings (postgres) as if they were firestore listings */
  getListingByIdAsListing$ = (id: string) =>
    this.getListingById$(id).pipe(
      filter((maybePad) => !!maybePad) as OperatorFunction<
        Pad | undefined,
        Pad
      >,
      map((pad) => {
        const listing: ListingsModelID = {
          created: null,
          cross_street: '',
          floor: null,
          legacyID: pad.id,
          neighborhood: null,
          require_guarantor_count: null,
          require_guarantor_min_credit: null,
          require_guarantor_min_income: null,
          require_tenant_max_income: null,
          require_tenant_min_credit_score: null,
          require_tenant_min_income: null,
          require_tenants_combined_max_income_per_person: null,
          require_tenants_combined_min_income_per_person: null,
          user_relation: null,
          is_active: false,
          area: 0,
          available_from_date: new Date(pad.moveOutDate.toDateString()),
          geohash: '',
          id,
          image_urls_1200: pad.images,
          image_urls_200: pad.images,
          image_urls_600: pad.images,
          image_urls_original: pad.images,
          images: pad.images,
          image_jpeg: '',
          latitude: 0,
          longitude: 0,
          street_view_latitude: 0,
          street_view_longitude: 0,
          place_id: '',
          place_ids: [],
          place_names: [],
          require_other: [],
          updated: new Date(),
          user_email: '',
          user_id: '',
          user_phone: '',
          acquisition_source: 'N/A',
          address: pad.address,
          bathrooms: pad.baths,
          bedrooms: pad.beds,
          central_air: pad.ac,
          city: pad.area,
          description: pad.description.replace(
            new RegExp('\r?\n', 'g'),
            '<br />'
          ),
          dishwasher: pad.dishwasher,
          doorman: false,
          elevator: pad.elevator,
          fireplace: pad.fireplace,
          gym: pad.fireplace,
          high_ceilings: pad.highCeilings,
          is_exclusive: false,
          outdoor: pad.patio,
          parking: pad.parking,
          pets: pad.pets,
          pool: pad.pool,
          postal_code: '',
          rent_amount: pad.price,
          state: '',
          slug: pad.id,
          type: 'apartment',
          unit_number: '',
          video_urls: [],
          washer_dryer: pad.dishwasher,
          wood_floors: pad.woodFloors,
        };
        console.log(`Converting legacy listing to ListingModel`);
        return listing;
      })
    );

  filterListings<T extends Listing | Pad>(listings: T[], f: Filter): T[] {
    return listings.filter((listing) => {
      if (f.beds && f.beds.min > listing.beds) {
        return false;
      }
      if (f.price && f.price.max < listing.price) {
        return false;
      }
      if (f.baths && f.baths.min > listing.baths) {
        return false;
      }
      if (f.ac && f.ac.required && !listing.ac) {
        return false;
      }
      if (
        f.available &&
        f.available.after.getTime() < listing.moveOutDate.getTime()
      ) {
        return false;
      }
      if (f.laundry && f.laundry.inSuite && !listing.ac) {
        return false;
      }
      if (f.dishwasher && f.dishwasher.has && !listing.dishwasher) {
        return false;
      }
      if (f.pets && f.pets.allows && !listing.pets) {
        return false;
      }
      if (f.gym && f.gym.has && !listing.gym) {
        return false;
      }
      if (f.fireplace && f.fireplace.has && !listing.fireplace) {
        return false;
      }
      if (f.elevator && f.elevator.has && !listing.elevator) {
        return false;
      }
      if (f.highCeilings && f.highCeilings.has && !listing.highCeilings) {
        return false;
      }
      if (f.pool && f.pool.has && !listing.pool) {
        return false;
      }
      if (f.woodFloors && f.woodFloors.has && !listing.woodFloors) {
        return false;
      }
      if (f.parking && f.parking.has && !listing.parking) {
        return false;
      }
      if (f.patio && f.patio.has && !listing.patio) {
        return false;
      }
      return true;
    });
  }

  getMarkerOptionsForPad(pad: Listing): google.maps.MarkerOptions {
    return {
      ...this.defaultMarkerOptions,
      label: '',
      title: `$${pad.price}, @ ${pad.address}, ${pad.area}`,
      // TODO: If the pad is "selected" or in a mouseover state, use different icon
      //   icon: '/assets/web/icons/location-mouseover.svg'
    };
  }

  private convertListingToPad(listing: Listing): Pad {
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    const parts = listing.address.split(' ');
    parts.shift();
    if (parts instanceof Array) {
      listing.address = parts.join(' ');
    } else {
      listing.address = parts;
    }
    return {
      ...listing,
      options: this.getMarkerOptionsForPad(listing),
    };
  }

  private sortListingsByDistance(a: Pad, b: Pad): number {
    const centerOfMap: google.maps.LatLng = this.mapSvc.convertCoordToLatLng(
      this.mapSvc.centerOfMap
    );
    const distanceA = google.maps.geometry.spherical.computeDistanceBetween(
      this.mapSvc.convertCoordToLatLng(a.location),
      centerOfMap
    );
    const distanceB = google.maps.geometry.spherical.computeDistanceBetween(
      this.mapSvc.convertCoordToLatLng(b.location),
      centerOfMap
    );
    return distanceA - distanceB;
  }
}
