import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  firstValueFrom,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { BookingService } from '../booking.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  rental_app_fill_request_msg_name,
  SendRentalApplicationFillRequest,
  SendRentalApplicationFillResponse,
} from '@padspin/function-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileService } from '../profile.service';
import { doc, getDoc, getFirestore } from '@angular/fire/firestore';
import { MessageTemplate, message_template_name } from '@padspin/models';
import { webEnvironment as environment } from '../../environments/environment';

type Person = {
  name: string;
  phone: string;
  email: string;
  first_name: string;
};

@Component({
  selector: 'padspin-send-application-modal',
  templateUrl: './send-application-modal.component.html',
  styleUrls: ['./send-application-modal.component.scss'],
})
export class SendApplicationModalComponent implements OnInit, OnDestroy {
  #destroy$ = new Subject<void>();
  submitting$ = new BehaviorSubject<boolean>(false);
  options: Person[] = [];

  form = this.fb.group({
    user: [null, Validators.required],
    lease_start_date: [this.data.lease_start_date, Validators.required],
    body: [null, Validators.required],
    title: [null, Validators.required],
  });

  constructor(
    private readonly dialogRef: MatDialogRef<SendApplicationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      lease_start_date: Date;
      slug: string;
      address: string;
      first_name: string;
      account_manager_email: string;
    },
    private readonly bookingService: BookingService,
    private readonly fb: FormBuilder,
    private readonly functions: AngularFireFunctions,
    private readonly matSnackbar: MatSnackBar,
    private readonly profileService: ProfileService
  ) {}

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  changeText(): void {
    const lease_start_date = this.form.get('lease_start_date')?.valueChanges;
    const user = this.form.get('user')?.valueChanges;
    if (!lease_start_date || !user) {
      return;
    }
    combineLatest([lease_start_date, user])
      .pipe(
        filter(([lease_start_date, user]) => !!lease_start_date && !!user),
        switchMap(async ([lease_start_date, user]) => {
          const template = await this.retrieveTemplate();
          if (!template.email_body) {
            throw new Error();
          }
          const first_name = user.first_name;
          if (!first_name) {
            throw new Error();
          }
          const address = this.data.address;
          const application_form_link = `${environment.host}/rental-app/${
            this.data.slug
          }?s=${lease_start_date.getTime()}`;
          const message = this.combineHandlebars(template.email_body, {
            first_name,
            address,
            application_form_link,
          });
          const title = this.combineHandlebars(template.email_subject, {
            address,
          });
          return { title, message };
        }),
        tap(({ title, message }) => {
          this.form.get('title')?.setValue(title);
          this.form.get('body')?.setValue(message);
        }),
        catchError(() => of()),
        takeUntil(this.#destroy$)
      )
      .subscribe();
  }

  async ngOnInit(): Promise<void> {
    const viewings = await this.bookingService.getBookingsByListingSlug(
      this.data.slug
    );
    this.options = viewings
      .map((v) => ({
        email: v.email,
        name: `${v.first_name} ${v.last_name}`,
        phone: v.phone,
        first_name: v.first_name,
      }))
      .filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
    this.changeText();
  }

  combineHandlebars = (
    handlebars: string,
    data: Record<string, string | number> | Record<string, never>
  ) => {
    if (!data) {
      return handlebars;
    }
    const result: string = handlebars.replace(/\{\{([^}]+)\}\}/g, (match) => {
      match = match.slice(2, -2);
      if (data[match] !== undefined) {
        return String(data[match]);
      } else {
        return '';
      }
    });
    return result;
  };

  async retrieveTemplate(db = getFirestore()): Promise<MessageTemplate> {
    const snap = await getDoc(
      doc(db, message_template_name, 'initial_applicant_msg')
    );
    if (!snap.exists()) {
      throw new Error('Could not find template.');
    }
    return snap.data() as MessageTemplate;
  }
  async sendApplication(): Promise<void> {
    this.submitting$.next(true);
    const callable$ = this.functions.httpsCallable<
      SendRentalApplicationFillRequest,
      SendRentalApplicationFillResponse
    >(rental_app_fill_request_msg_name);
    const account_manager_email = this.data.account_manager_email;
    const profile = await firstValueFrom(this.profileService.currentProfile$);
    const email_sender: string = profile?.email || 'support@padspin.com';
    try {
      await firstValueFrom(
        callable$({
          email_body: this.form.get('body')?.value,
          phone_number: this.form.get('user')?.value?.phone,
          email_recipient: this.form.get('user')?.value?.email,
          email_sender,
          email_subject: this.form.get('title')?.value,
          sms_body: this.form.get('body')?.value.replace(/(<([^>]+)>)/gi, ''),
          account_manager_email,
        })
      );
      this.close();
    } catch (error) {
      this.matSnackbar.open('Unable to send message.', 'OK', {
        duration: 5000,
      });
    } finally {
      this.submitting$.next(false);
    }
  }
  async close(): Promise<void> {
    this.dialogRef.close();
  }
}
