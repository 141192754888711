import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export const nameValidator = Validators.compose([
  Validators.required,
  Validators.minLength(1),
]) as ValidatorFn;

export const emailValidator = Validators.compose([
  Validators.required,
  Validators.email,
]) as ValidatorFn;

export const phoneValidator = Validators.compose([
  Validators.required,
  Validators.pattern(/^([0-9 .\-()+]*$)/),
]) as ValidatorFn;

export const phoneValidator2: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const value = control.value;
  if (!value) {
    return null;
  }
  const isValid = isValidPhoneNumber(value, 'US');
  if (!isValid) {
    let parsedNumber = '';
    try {
      parsedNumber = parsePhoneNumber(value, 'US').number.toString();
    } catch (err) {
      console.log(`phoneValidator2 error: ${err}`);
    }
    const error: ValidationErrors = {
      value,
      constraints: { invalid: 'invalid_phone_number' },
      message: `${parsedNumber} is not a valid phone number`,
    };
    return error;
  }
  return null;
};

export const fireAuthPhoneValidator = Validators.compose([
  Validators.required,
  Validators.pattern(/^\+[0-9]* [0-9]{3}-[0-9]{3}-[0-9]{4}/),
]) as ValidatorFn;

export const passwordValidator = Validators.compose([
  Validators.required,
  Validators.minLength(8),
]) as ValidatorFn;

export const timeRangeValidator = Validators.compose([
  Validators.pattern(
    '([0-9][0-9]:[0-9][0-9]|[0-9]:[0-9][0-9]|[0-9][0-9]|[0-9])([aA]|[pP])[mM] ?([-]|to) ?([0-9][0-9]:[0-9][0-9]|[0-9]:[0-9][0-9]|[0-9][0-9]|[0-9])([aA]|[pP])[mM]?'
  ),
]) as ValidatorFn;

export const userTypeValidator = Validators.compose([
  Validators.required,
  Validators.pattern(/tenant|landlord/),
]) as ValidatorFn;

export const isYouTubeLink = Validators.pattern(
  /^http[s]?:\/\/(www.)?(youtube.com|youtu.be)\/.*/
) as ValidatorFn;

export const isArrayOfYouTubeLinksValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const controlValue = control.value;
  if (!controlValue) {
    return null;
  }
  if (!Array.isArray(controlValue)) {
    return { invalid: true, not_youtube_links: true, value: controlValue };
  }
  const pattern = /^http[s]?:\/\/(www.)?(youtube.com|youtu.be)\/.*/;
  for (const value of controlValue) {
    if (!pattern.test(value)) {
      return { invalid: true, not_youtube_links: true, value: controlValue };
    }
  }
  return null;
};

export const ssnValidator = Validators.pattern(/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/);

export const zipcodeValidator = Validators.pattern(
  /^(([0-9]{5})|([abceghj-nprstvxyABCEGHJ-NPRSTVXY][0-9][abceghj-nprstvxyABCEGHJ-NPRSTV-Z]( |)[0-9][abceghj-nprstvxyABCEGHJ-NPRSTV-Z][0-9]))$/
);

export const numberValidator = Validators.pattern(/^[0-9]*$/);

export const moneyValidator = Validators.pattern(/^(?=.*\d)\d*(?:\.\d\d)?$/);

export const creditScoreValidator = Validators.pattern(/^\d{3}$/);
