import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { LatLngLiteral } from './search-page/lat-lng-literal';
import { Observable, map, shareReplay } from 'rxjs';

export type GetUserLocationResponse = { coords: string };

@Injectable({
  providedIn: 'root',
})
export class UserLocationService {
  public location: Observable<LatLngLiteral | null>;

  constructor(private readonly aff: AngularFireFunctions) {
    const callable = this.aff.httpsCallable<object, GetUserLocationResponse>(
      'get_user_location'
    );
    const data$ = callable({});
    this.location = data$.pipe(
      map((res) =>
        !!res && !!res.coords
          ? this.convertStringCoordsToLatLngLiteral(res.coords)
          : null
      ),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  convertStringCoordsToLatLngLiteral(coord: string): LatLngLiteral {
    if (!coord) {
      throw new Error('Coordinate not provided, unable to convert');
    }
    const coords = coord.split(',').map((c) => Number(c));
    if (!coords || coords.length !== 2) {
      throw new Error('Coordinate invalid');
    }
    const lat = coords[0];
    const lng = coords[1];
    return { lat, lng };
  }
}
