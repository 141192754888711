<form [formGroup]="contactForm">
  <span class="p-float-label">
    <input pInputText formControlName="name" type="text" id="name" />
    <label for="name">Name of {{ type$ | async }}</label>
  </span>
  <span class="p-float-label">
    <input pInputText formControlName="email" type="text" id="email" />
    <label for="email">Email of {{ type$ | async }}</label>
  </span>
  <span class="p-float-label">
    <input pInputText formControlName="phone" type="text" id="phone" />
    <label for="phone">Phone of {{ type$ | async }}</label>
  </span>
  <input type="hidden" formControlName="type" />
</form>
