<h2 mat-dialog-title>Book a Tour</h2>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div style="text-align: center">
      <p>
        All Padspin apartments are hundreds below market rate, have been
        verified, and have no broker fees. Please complete below to confirm your
        viewing before it goes.
      </p>
    </div>

    <label>
      <span>Viewing options. Select both if they apply: *</span>
      <p-selectButton
        [options]="bookingTypesOpts"
        formControlName="viewing_type"
        optionLabel="label"
        optionValue="value"
        optionDisabled="disabled"
        [multiple]="true"
      ></p-selectButton>
    </label>
    <p
      class="error"
      *ngIf="
        (isDateTouched$ | async) && form.get('viewing_type')?.value === null
      "
    >
      Required
    </p>

    <ng-container *ngIf="details$ | async as details">
      <label *ngIf="details.length > 0">
        <span>
          <span
            >{{ data.listing?.neighborhood }} {{ data.listing.bedrooms
            }}<abbr title="bedroom">BR</abbr> {{ data.listing.bathrooms
            }}<abbr title="bathroom">BA</abbr> details:*</span
          >
          <div class="dealbreakers">
            <span *ngFor="let detail of details">
              <p-chip [label]="detail"></p-chip>
            </span>
          </div>
        </span>
        <p-checkbox
          [binary]="true"
          [formControl]="$any(form).controls['dealbreakers']"
          label="I confirm"
        ></p-checkbox>
      </label>
    </ng-container>

    <label [ngClass]="{ hidden: form.controls['guarantor'].disabled }">
      <span>Do you have a guarantor, co-signer, or any assistance? *</span>
      <p-selectButton
        [options]="guarantorOpts"
        formControlName="guarantor"
        optionLabel="label"
        optionValue="value"
      ></p-selectButton>
    </label>

    <label
      class="p-field p-fluid"
      [ngClass]="{ hidden: form.controls['credit_score'].disabled }"
    >
      <span>Credit score: *</span>
      <p-inputMask
        mask="999"
        placeholder="e.g.: 670"
        formControlName="credit_score"
      ></p-inputMask>
    </label>
    <span
      class="error"
      *ngIf="
        form.get('credit_score')?.invalid &&
        form.get('credit_score')?.errors &&
        form.get('credit_score')?.hasError('required') &&
        (form.get('credit_score')?.dirty || form.get('credit_score')?.touched)
      "
    >
      Please provide your credit score
    </span>

    <label
      class="p-field p-fluid"
      [ngClass]="{ hidden: form.controls['annual_income'].disabled }"
    >
      <span>Annual income: *</span>
      <p-inputNumber
        formControlName="annual_income"
        placeholder="e.g.: $50,000"
        mode="currency"
        currency="USD"
        locale="en-US"
      ></p-inputNumber>
    </label>
    <span
      class="error"
      *ngIf="
        form.get('annual_income')?.invalid &&
        form.get('annual_income')?.errors &&
        form.get('annual_income')?.hasError('required') &&
        (form.get('annual_income')?.dirty || form.get('annual_income')?.touched)
      "
    >
      Please provide your annual income
    </span>

    <label
      class="p-field p-fluid"
      [ngClass]="{ hidden: form.controls['funds_available'].disabled }"
    >
      <span
        >Savings or available funds that might help approve your application:
        *</span
      >
      <p-inputNumber
        formControlName="funds_available"
        placeholder="e.g.: $5,000 (checking account)"
        mode="currency"
        currency="USD"
        locale="en-US"
      ></p-inputNumber>
    </label>
    <span
      class="error"
      *ngIf="
        form.get('funds_available')?.invalid &&
        form.get('funds_available')?.errors &&
        form.get('funds_available')?.hasError('required') &&
        (form.get('funds_available')?.dirty ||
          form.get('funds_available')?.touched)
      "
    >
      Please give an approximation of your available funds
    </span>

    <label
      class="p-field p-fluid"
      [ngClass]="{ hidden: form.controls['earliest_move_in_date'].disabled }"
    >
      <span>Earliest move-in date: *</span>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <img
            src="/assets/web/icons/calendar.svg"
            alt="Calendar Icon"
            aria-hidden="true"
            width="16"
            height="16"
          />
        </span>
        <p-calendar
          formControlName="earliest_move_in_date"
          appendTo="body"
          [touchUI]="true"
          placeholder="Choose a date"
        ></p-calendar>
      </div>
    </label>

    <label class="p-field p-fluid">
      <p-checkbox
        [binary]="true"
        [formControl]="$any(form).controls['plan_to_hire_movers']"
        label="Do you plan to hire professional movers?"
      ></p-checkbox>
    </label>
    <span
      class="error"
      *ngIf="
        form.get('earliest_move_in_date')?.invalid &&
        form.get('earliest_move_in_date')?.errors &&
        form.get('earliest_move_in_date')?.hasError('required') &&
        (form.get('earliest_move_in_date')?.dirty ||
          form.get('earliest_move_in_date')?.touched)
      "
    >
      What is the first day you would be able to move in?
    </span>
    <div *ngIf="(currentUser$ | async) === undefined">
      <p-message
        severity="info"
        text="Click sign up and submit your contact info so we can coordinate your tour."
      >
      </p-message>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button padspin-button color="green" mat-dialog-close>Close</button>
  <button
    *ngIf="(currentUser$ | async) !== undefined"
    padspin-button
    color="green"
    mat-dialog-close
    [disabled]="form.invalid"
    (click)="scheduleViewing()"
  >
    Book
  </button>
  <button
    *ngIf="(currentUser$ | async) === undefined"
    padspin-button
    color="green"
    mat-dialog-close
    [disabled]="form.invalid"
    (click)="scheduleViewing()"
  >
    <img
      icon
      src="/assets/web/icons/person_add_white_24dp.svg"
      alt="Create Account"
    />
    Sign Up
  </button>
</div>
