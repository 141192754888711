import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadImagesModalComponent } from './download-images-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MessageModule } from 'primeng/message';

@NgModule({
  declarations: [DownloadImagesModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    MatSnackBarModule,
    MessageModule,
  ],
  exports: [DownloadImagesModalComponent],
})
export class DownloadImagesModalModule {}
