import {
  DocumentData,
  FieldValue,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from '@angular/fire/firestore';
import {
  ListingsModel,
  ReplaceDateWithNgTimestamp,
  ReplaceDateWithNgTimestampOrNgFieldValue,
} from '@padspin/models';

export const angularListingsConverter: FirestoreDataConverter<ListingsModel> = {
  toFirestore: function (o: WithFieldValue<ListingsModel>): DocumentData {
    const data: WithFieldValue<
      ReplaceDateWithNgTimestampOrNgFieldValue<ListingsModel>
    > = {
      acquisition_source: o['acquisition_source'] || null,
      address: o['address'] || '',
      area: o['area'] || 0,
      available_from_date:
        o['available_from_date'] instanceof Date
          ? Timestamp.fromDate(o['available_from_date'])
          : o['available_from_date'] instanceof FieldValue
          ? o['available_from_date']
          : Timestamp.now(),
      bathrooms: o['bathrooms'] || 0,
      bedrooms: o['bedrooms'] || 0,
      central_air: o['central_air'] || false,
      city: o['city'] || '',
      created:
        o['created'] instanceof Date
          ? Timestamp.fromDate(o['created'])
          : o['created'] instanceof Timestamp
          ? o['created']
          : o['created'] instanceof FieldValue
          ? o['created']
          : null,
      cross_street: o['cross_street'] || null,
      description: o['description'] || '',
      dishwasher: o['dishwasher'] || false,
      doorman: o['doorman'] || false,
      elevator: o['elevator'] || false,
      fireplace: o['fireplace'] || false,
      floor: o['floor'] || null,
      geohash: o['geohash'] || '',
      gym: o['gym'] || false,
      high_ceilings: o['high_ceilings'] || false,
      image_jpeg: o['image_jpeg'] || null,
      image_urls_1200: isStringArray(o['image_urls_1200'])
        ? o['image_urls_1200']
        : o['image_urls_1200'] instanceof FieldValue
        ? o['image_urls_1200']
        : [],
      image_urls_200: isStringArray(o['image_urls_200'])
        ? o['image_urls_200']
        : o['image_urls_200'] instanceof FieldValue
        ? o['image_urls_200']
        : [],
      image_urls_600: isStringArray(o['image_urls_600'])
        ? o['image_urls_600']
        : o['image_urls_600'] instanceof FieldValue
        ? o['image_urls_600']
        : [],
      image_urls_original: isStringArray(o['image_urls_original'])
        ? o['image_urls_original']
        : o['image_urls_original'] instanceof FieldValue
        ? o['image_urls_original']
        : [],
      images: isStringArray(o['images'])
        ? o['images']
        : o['images'] instanceof FieldValue
        ? o['images']
        : [],
      is_active: o['is_active'] || false,
      is_exclusive: o['is_exclusive'] || false,
      latitude: o['latitude'] || 0,
      legacyID: o['legacyID'] || null,
      longitude: o['longitude'] || 0,
      neighborhood: o['neighborhood'] || null,
      outdoor: o['outdoor'] || false,
      parking: o['parking'] || false,
      pets: o['pets'] || false,
      place_id: o['place_id'] || '',
      place_ids: isStringArray(o['place_ids'])
        ? o['place_ids']
        : o['place_ids'] instanceof FieldValue
        ? o['place_ids']
        : [],
      place_names: isStringArray(o['place_names'])
        ? o['place_names']
        : o['place_names'] instanceof FieldValue
        ? o['place_names']
        : [],
      pool: o['pool'] || false,
      postal_code: o['postal_code'] || '',
      rent_amount: o['rent_amount'] || 0,
      require_guarantor_count: o['require_guarantor_count'] || null,
      require_guarantor_min_credit: o['require_guarantor_min_credit'] || null,
      require_guarantor_min_income: o['require_guarantor_min_income'] || null,
      require_other: isStringArray(o['require_other'])
        ? o['require_other']
        : o['require_other'] instanceof FieldValue
        ? o['require_other']
        : [],
      require_tenant_max_income: o['require_tenant_max_income'] || null,
      require_tenant_min_credit_score:
        o['require_tenant_min_credit_score'] || null,
      require_tenant_min_income: o['require_tenant_min_income'] || null,
      require_tenants_combined_max_income_per_person:
        o['require_tenants_combined_max_income_per_person'] || null,
      require_tenants_combined_min_income_per_person:
        o['require_tenants_combined_min_income_per_person'] || null,
      slug: o['slug'] || '',
      state: o['state'] || '',
      street_view_latitude: o['street_view_latitude'] || 0,
      street_view_longitude: o['street_view_longitude'] || 0,
      type: o['type'] || 'apartment',
      unit_number:
        typeof o['unit_number'] === 'string'
          ? o['unit_number']
          : typeof o['unit_number'] === 'number'
          ? o['unit_number']
          : '',
      updated:
        o['updated'] instanceof Date
          ? Timestamp.fromDate(o['updated'])
          : o['updated'] instanceof Timestamp
          ? o['updated']
          : Timestamp.now(),
      user_email: o['user_email'] || '',
      user_id: o['user_id'] || '',
      user_phone: o['user_phone'] || '',
      user_relation: o['user_relation'] || null,
      video_urls: isStringArray(o['video_urls'])
        ? o['video_urls']
        : o['video_urls'] instanceof FieldValue
        ? o['video_urls']
        : [],
      washer_dryer: o['washer_dryer'] || false,
      wood_floors: o['wood_floors'] || false,
      slugFriendly: o['slugFriendly'] || '',
    };
    return data;
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions
  ): ListingsModel {
    const d = snapshot.data(
      options
    ) as ReplaceDateWithNgTimestamp<ListingsModel>;
    const listing: ListingsModel = {
      acquisition_source: d['acquisition_source'] || null,
      address: d['address'],
      area: d['area'],
      available_from_date:
        d['available_from_date'] instanceof Timestamp
          ? d['available_from_date'].toDate()
          : d['available_from_date'],
      bathrooms: d['bathrooms'],
      bedrooms: d['bedrooms'],
      central_air: d['central_air'],
      city: d['city'],
      created:
        d['created'] instanceof Timestamp ? d['created']?.toDate() : null,
      cross_street: d['cross_street'] || null,
      description: d['description'],
      dishwasher: d['dishwasher'],
      doorman: d['doorman'],
      elevator: d['elevator'],
      fireplace: d['fireplace'],
      floor: d['floor'] || null,
      geohash: d['geohash'],
      gym: d['gym'],
      high_ceilings: d['high_ceilings'],
      image_jpeg: d['image_jpeg'] || null,
      image_urls_1200: d['image_urls_1200'],
      image_urls_200: d['image_urls_200'],
      image_urls_600: d['image_urls_600'],
      image_urls_original: d['image_urls_original'],
      images: d['images'],
      is_active: d['is_active'],
      is_exclusive: d['is_exclusive'],
      latitude: d['latitude'],
      legacyID: d['legacyID'] || null,
      longitude: d['longitude'],
      neighborhood: d['neighborhood'] || null,
      outdoor: d['outdoor'],
      parking: d['parking'],
      pets: d['pets'],
      place_id: d['place_id'],
      place_ids: d['place_ids'],
      place_names: d['place_names'],
      pool: d['pool'],
      postal_code: d['postal_code'],
      rent_amount: d['rent_amount'],
      require_guarantor_count: d['require_guarantor_count'] || null,
      require_guarantor_min_credit: d['require_guarantor_min_credit'] || null,
      require_guarantor_min_income: d['require_guarantor_min_income'] || null,
      require_other: d['require_other'] || [],
      require_tenant_max_income: d['require_tenant_max_income'] || null,
      require_tenant_min_credit_score:
        d['require_tenant_min_credit_score'] || null,
      require_tenant_min_income: d['require_tenant_min_income'] || null,
      require_tenants_combined_max_income_per_person:
        d['require_tenants_combined_max_income_per_person'] || null,
      require_tenants_combined_min_income_per_person:
        d['require_tenants_combined_min_income_per_person'] || null,
      slug: d['slug'],
      state: d['state'],
      street_view_latitude: d['street_view_latitude'],
      street_view_longitude: d['street_view_longitude'],
      type: d['type'],
      unit_number: d['unit_number'],
      updated:
        d['updated'] instanceof Timestamp
          ? d['updated'].toDate()
          : d['updated'],
      user_email: d['user_email'],
      user_id: d['user_id'],
      user_phone: d['user_phone'],
      user_relation: d['user_relation'] || null,
      video_urls: d['video_urls'],
      washer_dryer: d['washer_dryer'],
      wood_floors: d['wood_floors'],
      slugFriendly: d['slugFriendly'],
    };
    return listing;
  },
};

const isStringArray = (arr: unknown): arr is Array<string> => {
  if (!arr) {
    return false;
  }
  if (!Array.isArray(arr)) {
    return false;
  }
  if (!arr.every((v) => typeof v === 'string')) {
    return false;
  }
  return true;
};
