import { WithId } from './types/with-id';
import { RentalApplicationPersonModel } from './rental-application.model';

export const rental_application_metadata_name = 'application_metadata' as const;

export type RentalApplicationMetadataModel = {
  /** null before approval. True when approved. False when denied. */
  approved: boolean | null;
  /** Date of approval/decline. */
  approved_at: Date | null;
  /** User ID of the user that approved the application */
  approved_by_uid: string | null;
  /** Date the application metadata was created */
  created: Date;
  /** Date the application was completed */
  completed_at: Date | null;
  /** @see {@link RentalApplicationModel.guarantors} */
  guarantors: Array<RentalApplicationPersonModel>;
  id: string;
  lease_start_at: Date;
  /** @see {@link RentalApplicationModel.roommates} */
  roommates: Array<RentalApplicationPersonModel>;
  /** Listing unit slug */
  slug: string;
  /** Date the application was reviewed */
  reviewed_at: Date | null;
  /** Creator's UID */
  uid: string | null;
};

export class RentalApplicationMetadataImpl
  implements RentalApplicationMetadataModel
{
  approved: boolean | null;
  approved_at: Date | null;
  approved_by_uid: string | null;
  created: Date;
  completed_at: Date | null;
  guarantors: Array<RentalApplicationPersonModel>;
  id: string;
  lease_start_at: Date;
  roommates: Array<RentalApplicationPersonModel>;
  slug: string;
  reviewed_at: Date | null;
  uid: string | null;

  constructor(partial: WithId<Partial<RentalApplicationMetadataModel>>) {
    this.id = partial.id;
    this.approved = partial.approved || null;
    this.approved_at = partial.approved_at || null;
    this.approved_by_uid = partial.approved_by_uid || null;
    this.created = partial.created || new Date();
    this.completed_at = partial.completed_at || null;
    this.guarantors = partial.guarantors || [];
    this.lease_start_at = partial.lease_start_at || new Date();
    this.roommates = partial.roommates || [];
    this.slug = partial.slug || '';
    this.reviewed_at = partial.reviewed_at || null;
    this.uid = partial.uid || null;
  }
}
