import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type PadExpandedCarouselDialogComponentInputData = {
  images: string[];
  featuredPath: string;
};
export type PadExpandedCarouselDialogComponentInput = {
  panelClass: string;
  data: PadExpandedCarouselDialogComponentInputData;
  maxWidth: string;
  maxHeight: string;
  width: string;
  height: string;
  backdropClass: string;
};

@Component({
  selector: 'padspin-pad-expanded-carousel-dialog',
  templateUrl: 'pad-expanded-carousel-dialog.html',
  styleUrls: ['./pad-expanded-carousel-dialog.component.scss'],
})
export class PadExpandedCarouselDialogComponent {
  images: { url: string; selected: boolean; index: number }[] = [];
  image?: { url: string; index: number };

  @ViewChildren('padspinThumbnail') thumbs!: QueryList<
    ElementRef<HTMLImageElement>
  >;

  @HostListener('document:keydown', ['$event'])
  onButtonPress(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft' || event.key === 'a') {
      this.prev();
    }
    if (event.key === 'ArrowRight' || event.key === 'd') {
      this.next();
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: PadExpandedCarouselDialogComponentInputData,
    private readonly dialogRef: MatDialogRef<PadExpandedCarouselDialogComponent>
  ) {
    const featuredPathIndex = this.firstIndexOfImage(data.featuredPath);
    if (data.images && data.images[featuredPathIndex]) {
      this.images = data.images.map((url: string, index: number) => ({
        url,
        index,
        selected: false,
      }));
      this.changeTo(featuredPathIndex);
    }
  }
  close = () => this.dialogRef.close();
  prev = () => {
    if (!this.image) {
      return;
    }
    if (this.isPrevable()) {
      this.changeTo(this.image ? this.image.index - 1 : 0);
    }
  };
  next = () => {
    if (!this.image) {
      return;
    }
    if (this.isNextable()) {
      this.changeTo(this.image ? this.image.index + 1 : 0);
    }
  };
  changeTo = (toIndex: number = 0) => {
    if (this.isChangeable(toIndex)) {
      this.image =
        this.images.find((image) => image.index === toIndex) || this.image;
      this.images = this.images.map((image, index) => ({
        ...image,
        selected: index === toIndex,
      }));
      if (this.thumbs) {
        this.thumbs.get(toIndex)?.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };
  private isNextable = (image = this.image): boolean =>
    !!this.images.find(
      (predicate) => predicate.index === (image?.index || 0) + 1
    );
  private isPrevable = (image = this.image): boolean =>
    !!this.images.find(
      (predicate) => predicate.index === (image?.index || 0) - 1
    );
  private isChangeable = (indexOrURL: number | string): boolean =>
    !!this.images.find((predicate) =>
      typeof indexOrURL === 'number'
        ? predicate.index === indexOrURL
        : predicate.url === indexOrURL
    );
  private firstIndexOfImage = (
    url: string | undefined = this.image?.url
  ): number => {
    const index = this.images.findIndex((image) => image.url === url);
    return index === -1 ? 0 : index;
  };
}
