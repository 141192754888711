import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginOrRegisterInputType } from './login-or-register-input.type';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'padspin-login-or-register-modal',
  templateUrl: './login-or-register-modal.component.html',
  styleUrls: ['./login-or-register-modal.component.scss'],
})
export class LoginOrRegisterModalComponent {
  form = this.fb.group({
    loginOrRegister: ['register'],
  });
  isTenant$ = new ReplaySubject<boolean>(1);
  isClosable = false;
  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<LoginOrRegisterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly input: LoginOrRegisterInputType
  ) {
    if (this.input.type) {
      this.isTenant$.next(this.input.type === 'tenant');
    }
    if (this.input.disable) {
      this.isClosable = true;
    }
    if (this.input.login) {
      this.form.get('loginOrRegister')?.setValue('login');
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  complete(_credential?: unknown): void {
    this.dialogRef.close();
  }
}
