import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingEditModalComponent } from './listing-edit-modal.component';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { SkeletonModule } from 'primeng/skeleton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessageModule } from 'primeng/message';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { ButtonModule as PrimeButtonModule } from 'primeng/button';
import {
  ButtonModule as PadspinButtonModule,
  RelativeDatePipeModule,
  TimestampToDatePipeModule,
  UserPreviewModule,
} from '@padspin/ui';
import { OrderListModule } from 'primeng/orderlist';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { CardModule } from 'primeng/card';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { GoogleMapsModule } from '@angular/google-maps';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PadExpandedModule } from '../search-page/pad-expanded/pad-expanded.module';
import { ChipsModule } from 'primeng/chips';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AvatarModule } from 'primeng/avatar';
import { RippleModule } from 'primeng/ripple';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SendApplicationModalModule } from '../send-application-modal/send-application-modal.module';
import { DownloadImagesModalModule } from '../download-images-modal/download-images-modal.module';
import { ListingEditContactModule } from '../listing-edit-contact/listing-edit-contact.module';

@NgModule({
  declarations: [ListingEditModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatCardModule,
    SkeletonModule,
    RadioButtonModule,
    MessageModule,
    EditorModule,
    CalendarModule,
    InputNumberModule,
    CheckboxModule,
    SelectButtonModule,
    DropdownModule,
    FieldsetModule,
    PadspinButtonModule,
    PrimeButtonModule,
    OrderListModule,
    InputTextModule,
    UserPreviewModule,
    FileUploadModule,
    RelativeDatePipeModule,
    TimestampToDatePipeModule,
    CardModule,
    InputTextareaModule,
    TooltipModule,
    GoogleMapsModule,
    InputSwitchModule,
    PadExpandedModule,
    ChipsModule,
    ConfirmPopupModule,
    MatSnackBarModule,
    AvatarModule,
    RippleModule,
    ToggleButtonModule,
    SendApplicationModalModule,
    DownloadImagesModalModule,
    ListingEditContactModule,
  ],
  exports: [ListingEditModalComponent],
})
export class ListingEditModalModule {}
