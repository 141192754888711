import {
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
  QueryDocumentSnapshot,
} from '@angular/fire/firestore';
import { MessageTemplate } from '@padspin/models';

export const messageTemplateDataConverterAngular: FirestoreDataConverter<MessageTemplate> =
  {
    toFirestore: (
      modelObject: WithFieldValue<MessageTemplate>
    ): DocumentData => {
      return modelObject;
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot): MessageTemplate => {
      const data = snapshot.data();
      return {
        email_body: String(data['email_body']),
        email_sender: String(data['email_sender']),
        email_subject: String(data['email_subject']),
        sms_body: String(data['sms_body']),
        sms_media_url: String(data['sms_media_url']),
        shared: Boolean(data['shared']),
      };
    },
  };
