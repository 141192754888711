import type { Timestamp, FieldValue } from '@angular/fire/firestore';
import { Public } from './models';

export const articleName = 'articles' as const;

export class ArticleModel {
  /** Name of author */
  author!: string;
  /** HTML content of article */
  description!: string;
  /** @example "hot-to-find-the-best-rental-in-nyc" */
  'description-small'!: string;
  /** Same slug as description-small */
  id!: string;
  timestamp!: Timestamp | FieldValue;
  /** @example "How to find the best rental in nyc" */
  title!: string;
  /** @example "padspinapp" */
  twitter!: string;
  constructor(article: Public<ArticleModel>) {
    Object.assign(this, article);
  }
}
