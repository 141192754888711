<main mat-dialog-content>
  <!-- <picture>
    <source srcset="/assets/web/post-pad-confirmation.webp" type="image/webp" />
    <source srcset="/assets/web/post-pad-confirmation.png" type="image/png" />
    <img src="/assets/web/post-pad-confirmation.svg" alt="Booking created" />
  </picture> -->
  <h1>
    Padspin will be contacting you soon to confirm your tour. On the next page
    click “Start Bidding” to see how much you can save on movers!
  </h1>
  <!-- <h2>
    Take a sec and set your Pad Criteria <br />
    to receive new matches as they post.
  </h2> -->
</main>
<div mat-dialog-actions align="center">
  <button
    padspin-button
    [color]="'tenant'"
    routerLink="/move"
    (click)="close()"
    [center]="true"
  >
    <span>Start Bidding</span>
  </button>
</div>
