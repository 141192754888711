import { Injectable } from '@angular/core';
import { LatLngLiteral } from './search-page/lat-lng-literal';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getFunctions, httpsCallableData } from '@angular/fire/functions';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  placeAutocompleteName,
  PlaceAutocompleteRequestData,
  PlaceAutocompleteFnResponse,
} from '@padspin/function-types';
import { MapGeocoder } from '@angular/google-maps';
import type { PlaceAutocompleteResponseData } from '@googlemaps/google-maps-services-js';
import { CriteriaLocation } from './criteria-location/criteria-location.component';
import { NeighborhoodImpl } from '@padspin/models';
import {
  get_neighborhoods_fn_name,
  GetNeighborhoodsRequestData,
  GetNeighborhoodsResponse,
} from '@padspin/function-types';

/** @see @googlemaps/google-maps-services-js */
export declare enum PlaceType2 {
  administrative_area_level_1 = 'administrative_area_level_1',
  administrative_area_level_2 = 'administrative_area_level_2',
  administrative_area_level_3 = 'administrative_area_level_3',
  administrative_area_level_4 = 'administrative_area_level_4',
  administrative_area_level_5 = 'administrative_area_level_5',
  archipelago = 'archipelago',
  colloquial_area = 'colloquial_area',
  continent = 'continent',
  country = 'country',
  establishment = 'establishment',
  finance = 'finance',
  floor = 'floor',
  food = 'food',
  general_contractor = 'general_contractor',
  geocode = 'geocode',
  health = 'health',
  intersection = 'intersection',
  landmark = 'landmark',
  locality = 'locality',
  natural_feature = 'natural_feature',
  neighborhood = 'neighborhood',
  place_of_worship = 'place_of_worship',
  plus_code = 'plus_code',
  point_of_interest = 'point_of_interest',
  political = 'political',
  post_box = 'post_box',
  postal_code = 'postal_code',
  postal_code_prefix = 'postal_code_prefix',
  postal_code_suffix = 'postal_code_suffix',
  postal_town = 'postal_town',
  premise = 'premise',
  room = 'room',
  route = 'route',
  street_address = 'street_address',
  street_number = 'street_number',
  sublocality = 'sublocality',
  sublocality_level_1 = 'sublocality_level_1',
  sublocality_level_2 = 'sublocality_level_2',
  sublocality_level_3 = 'sublocality_level_3',
  sublocality_level_4 = 'sublocality_level_4',
  sublocality_level_5 = 'sublocality_level_5',
  subpremise = 'subpremise',
  town_square = 'town_square',
}

@Injectable({
  providedIn: 'root',
})
export class PlaceService {
  // TODO: change to be a mapped cache (keyed by stringified LatLndLiteral? lat+lng?
  private nearby$?: Observable<google.maps.GeocoderResult[]>;

  constructor(
    private readonly http: HttpClient,
    private readonly mapGeocoderService: MapGeocoder,
    private readonly aff: AngularFireFunctions
  ) {}

  geocoderResultToString(result: google.maps.GeocoderResult): string {
    return result.formatted_address;
  }

  /** Find nearby administrative_area_2 neighbourhoods */
  searchNearby(
    location: LatLngLiteral,
    _bounds?: google.maps.LatLngBounds
  ): Observable<google.maps.GeocoderResult[]> {
    if (!this.nearby$) {
      this.nearby$ = this.searchNearbyRequest(location).pipe(
        shareReplay({ bufferSize: 1, refCount: false })
      );
    }
    return this.nearby$;
  }

  // private searchNearbyURI = (lat: number, lng: number) =>
  //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=administrative_area_level_2&key=${environment.googleMapsApiKey}`;
  private searchNearbyRequest(
    location: LatLngLiteral,
    bounds?: google.maps.LatLngBounds
  ): Observable<google.maps.GeocoderResult[]> {
    // const URI = this.searchNearbyURI(location.lat, location.lng);
    // return this.http.get<google.maps.GeocoderResponse>(URI).pipe(
    //   tap(response => console.log(response)),
    //   map(response => response.results),
    // );
    return this.mapGeocoderService
      .geocode({ location, bounds })
      .pipe(map((response) => response.results));
  }

  searchAutocomplete(
    query: string,
    location: LatLngLiteral
  ): Observable<PlaceAutocompleteFnResponse> {
    const options: PlaceAutocompleteRequestData = {
      query,
      lng: location.lng,
      lat: location.lat,
    };
    const callable$ = httpsCallableData<
      PlaceAutocompleteRequestData,
      PlaceAutocompleteFnResponse
    >(getFunctions(), placeAutocompleteName);
    return callable$(options);
  }

  searchAutoCompleteNeighborhoods = (
    query: string,
    location: LatLngLiteral = { lat: -73, lng: 40 },
    callable$ = this.aff.httpsCallable<
      GetNeighborhoodsRequestData,
      GetNeighborhoodsResponse
    >(get_neighborhoods_fn_name)
  ): Observable<NeighborhoodImpl[]> =>
    this.searchAutocomplete(query, location).pipe(
      map((searchAutocomplete: PlaceAutocompleteResponseData) =>
        searchAutocomplete.predictions.filter((result) => {
          return (
            result.types.includes(PlaceType2.neighborhood) ||
            result.types.includes(PlaceType2.administrative_area_level_2) ||
            result.types.includes(PlaceType2.administrative_area_level_1) ||
            result.types.includes(PlaceType2.locality) ||
            result.types.includes(PlaceType2.political) ||
            result.types.includes(PlaceType2.sublocality)
          );
        })
      ),
      map((places) =>
        places.map((result) => ({
          place_id: result.place_id,
          main_text: result.structured_formatting.secondary_text
            ? `${result.structured_formatting.main_text}, ${result.structured_formatting.secondary_text}`
            : result.structured_formatting.main_text,
        }))
      ),
      switchMap(async (criteriaLocations: CriteriaLocation[]) => {
        return callable$({
          place_ids: criteriaLocations.map(
            (criteriaLocation) => criteriaLocation.place_id
          ),
        });
      }),
      switchMap((results) => results),
      map((results) => Object.values(results)),
      map((neighborhoods) => neighborhoods.map((n) => new NeighborhoodImpl(n)))
    );
}
