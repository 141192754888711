import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RegisterInput } from './register-input.type';
import { AuthService } from '../auth.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { UserCredential } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { parsePhoneNumber } from 'libphonenumber-js';

@Component({
  selector: 'padspin-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  @Output()
  registrationComplete = new EventEmitter<UserCredential>();

  /** Current error message */
  validationMessage = new BehaviorSubject<string | null>(null);

  @Input() set type(value: 'tenant' | 'landlord') {
    this.accountType = value;
  }

  /** Default email */
  @Input() set email(value: string | undefined) {
    if (value) {
      this.form.get('email')?.setValue(value);
    }
  }

  /** Default phone */
  @Input() set phone(value: string | undefined) {
    if (value) {
      this.form.get('phone')?.setValue(value);
    }
  }

  accountType: 'tenant' | 'landlord' = 'tenant';

  form = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: [
      this.email || '',
      Validators.compose([Validators.required, Validators.email]),
    ],
    phone: [
      this.phone || '',
      Validators.compose([
        Validators.required,
        Validators.pattern(/^([0-9 .\-()+]*$)/),
      ]),
    ],
    password: ['', Validators.pattern(/^.{8,}$/)],
    tos: ['', Validators.requiredTrue],
  });

  isRegistering$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly fb: FormBuilder,
    private readonly auth: AngularFireAuth,
    private readonly authService: AuthService,
    private readonly firestore: AngularFirestore,
    private readonly dialogRef: MatDialogRef<RegisterComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly input: RegisterInput,
    private readonly analytics: GoogleAnalyticsService,
    private readonly functions: AngularFireFunctions
  ) {}

  /** Create a user account and login to the account without email validation */
  async register(): Promise<void> {
    this.isRegistering$.next(true);
    this.validationMessage.next(null);
    const email = this.form.get('email')?.value;
    const password = this.form.get('password')?.value;
    try {
      await this.authService.createUserWithEmailAndPassword({
        email,
        password,
        type: this.accountType,
        first_name: this.form.get('first_name')?.value,
        last_name: this.form.get('last_name')?.value,
        phone: this.formatPhoneNumber(this.form.get('phone')?.value),
      });
      const user = await this.loginUser(email, password);
      this.registrationComplete.emit(user);
      this.analytics.log('sign_up');
    } catch (error) {
      this.validationMessage.next(Object(error).message);
      return;
    } finally {
      this.isRegistering$.next(false);
    }
  }

  private async loginUser(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return this.authService.signInWithEmailAndPassword(email, password);
  }

  /** @example
   * formatPhoneNumber('17788687447'); // '+17788687447'
   * formatPhoneNumber('7788687447'); // '+17788687447'
   * formatPhoneNumber('7788687447:22'); // throw { message: 'Invalid phone number' }
   * formatPhoneNumber('778 868 7447'); // '+17788687447'
   */
  formatPhoneNumber(phone: string): string {
    const phoneStripped: string = phone.replace(/[^0-9:]/g, '');
    try {
      return parsePhoneNumber(phoneStripped, 'US').number as string;
    } catch (e) {
      throw new Error('Invalid phone number');
    }
  }
}
