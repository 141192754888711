import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from '@angular/fire/firestore';
import {
  LandlordExclusiveFormImpl,
  LandlordExclusiveFormModel,
  ReplaceDateWithNgTimestamp,
  WithId,
} from '@padspin/models';

export const landlordExclusiveFormConverterNg: FirestoreDataConverter<LandlordExclusiveFormModel> =
  {
    fromFirestore(
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options: SnapshotOptions | undefined
    ): LandlordExclusiveFormModel {
      const data = snapshot.data(
        options
      ) as ReplaceDateWithNgTimestamp<LandlordExclusiveFormModel>;
      const id = snapshot.id;
      const model: WithId<Partial<LandlordExclusiveFormModel>> = {
        id,
        ...data,
        available_by_date: data.available_by_date.toDate(),
        created_at: data.created_at.toDate(),
        date_of_acceptance: data.date_of_acceptance.toDate(),
      };
      return new LandlordExclusiveFormImpl(model);
    },
    toFirestore(
      modelObject: WithFieldValue<LandlordExclusiveFormModel>
    ): DocumentData {
      const documentData: ReplaceDateWithNgTimestamp<
        WithFieldValue<LandlordExclusiveFormModel>
      > = {
        ...modelObject,
        available_by_date:
          modelObject.available_by_date instanceof Date
            ? Timestamp.fromDate(modelObject.available_by_date)
            : modelObject.available_by_date,
        created_at:
          modelObject.created_at instanceof Date
            ? Timestamp.fromDate(modelObject.created_at)
            : modelObject.created_at,
        date_of_acceptance:
          modelObject.date_of_acceptance instanceof Date
            ? Timestamp.fromDate(modelObject.date_of_acceptance)
            : modelObject.date_of_acceptance,
      };
      if (!documentData.id) {
        delete documentData.id;
      }
      return documentData;
    },
  };
