import type { Timestamp, FieldValue } from '@angular/fire/firestore';
import { WithId } from './types/with-id';

export const profile_model_name = 'profile' as const;

export type UserType = 'tenant' | 'landlord';

/**
 *  @deprecated
 *  @see {@link UserProfileWithDateModel}
 */
export interface UserProfileInterface {
  type: UserType;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  createdAt: Timestamp | FieldValue;
}

/**
 *  @deprecated
 *  @see {@link UserProfileWithDateModel}
 */
export type UserProfileID = WithId<UserProfile>;

/**
 *  @deprecated
 *  @see {@link UserProfileWithDate}
 */
export class UserProfile implements UserProfileInterface {
  type!: UserType;
  email!: string;
  phone!: string;
  first_name!: string;
  last_name!: string;
  createdAt!: Timestamp | FieldValue;
  constructor(profile: UserProfileInterface) {
    Object.assign(this, profile);
  }
}

export type UserProfileWithDateModel = { createdAt: Date } & Omit<
  UserProfileInterface,
  'createdAt'
>;

export class UserProfileWithDate implements UserProfileWithDateModel {
  type!: UserType;
  email!: string;
  phone!: string;
  first_name!: string;
  last_name!: string;
  createdAt!: Date;
}
