import { Component, Inject, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PadComponent } from '../pad/pad.component';
import { serverTimestamp } from '@angular/fire/firestore';
import {
  BehaviorSubject,
  Observable,
  OperatorFunction,
  ReplaySubject,
  Subject,
  switchMap,
} from 'rxjs';
import { PadBookShowingOutput } from './pad-book-showing-output.interface';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { startOfDay, addHours, addDays, format } from 'date-fns';
import { GoogleAnalyticsService } from '../../google-analytics.service';
import { AuthService } from '../../auth.service';
import {
  TenantCriteriaID,
  TenantCriteriaService,
} from '../../tenant-criteria.service';
import { OrdinalSuffixPipe, TimestampService } from '@padspin/ui';
import { ListingsModelID } from '@padspin/models';
import { PadBookShowingInput } from './pad-book-showing-input.type';

import { User } from '@angular/fire/auth';

@Component({
  selector: 'padspin-pad-book-showing',
  templateUrl: './pad-book-showing.component.html',
  styleUrls: ['./pad-book-showing.component.scss'],
})
export class PadBookShowingComponent implements OnDestroy {
  destroy$ = new Subject<void>();
  currentUser$ = this.auth.currentUser$;
  startOfToday = startOfDay(Date.now());
  timeRangeRegExp =
    '([0-9][0-9]:[0-9][0-9]|[0-9]:[0-9][0-9]|[0-9][0-9]|[0-9])([aA]|[pP])[mM] ?([-]|to) ?([0-9][0-9]:[0-9][0-9]|[0-9]:[0-9][0-9]|[0-9][0-9]|[0-9])([aA]|[pP])[mM]?';
  guarantorOpts = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  fullyVaccinatedOpts = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  bookingTypesOpts = [
    { label: 'In Person', value: 'in_person', disabled: false },
    { label: 'Virtually', value: 'virtual', disabled: false },
  ];

  today?: string;
  today_start?: Date;
  today_end?: Date;
  tomorrow?: string;
  tomorrow_start?: Date;
  tomorrow_end?: Date;
  day_after_tomorrow?: string;
  day_after_tomorrow_start?: Date;
  day_after_tomorrow_end?: Date;
  form: FormGroup = this.fb.group(
    {
      dealbreakers: [null, Validators.requiredTrue],
      credit_score: [null, Validators.required],
      annual_income: [null, Validators.required],
      guarantor: [null, Validators.required],
      funds_available: [null, Validators.required],
      earliest_move_in_date: [null, Validators.required],
      viewing_type: [null, Validators.required],
      plan_to_hire_movers: [null],
    },
    { validators: [], updateOn: 'change' }
  );

  public error?: string | null;

  isDateTouched$ = new Subject<boolean>();

  isEmailUsed$ = new BehaviorSubject<boolean>(false);

  isPhoneNumberUsed$ = new BehaviorSubject<boolean>(false);

  user$: Observable<User | null> = this.auth.user$.pipe(
    takeUntil(this.destroy$)
  );

  criteria$: Observable<TenantCriteriaID | null> =
    this.auth.currentUserUID$.pipe(
      filter((maybeUid) => !!maybeUid) as OperatorFunction<
        string | undefined,
        string
      >,
      switchMap((uid) => this.criteria.criteriaByUid$({ uid })),
      takeUntil(this.destroy$)
    );

  listing$ = new ReplaySubject<ListingsModelID>(1);

  details$: Observable<string[]> = this.listing$.pipe(
    map((pad: ListingsModelID) => {
      const details: string[] = [];
      // Tenant doesn't care about the elevator on the first floor
      if (!pad.elevator && Number(pad.floor) !== 1 && pad.floor !== null) {
        details.push(
          pad.floor !== undefined
            ? `${this.ordinalSuffixPipe.transform(pad.floor)} fl walk-up`
            : 'Walk-up'
        );
      }
      if (!pad.washer_dryer) {
        details.push('No laundry');
      }
      if (!pad.pets) {
        details.push('No pets');
      }
      if (!pad.dishwasher) {
        details.push('No dishwasher');
      }
      return details;
    }),
    tap((details) => {
      if (details.length === 0) {
        // There are no dealbreakers, so auto agree for the user
        this.form.get('dealbreakers')?.setValue(true);
      }
    })
  );

  private ordinalSuffixPipe: OrdinalSuffixPipe;

  constructor(
    private readonly dialogRef: MatDialogRef<PadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PadBookShowingInput,
    private readonly fb: FormBuilder,
    private readonly analytics: GoogleAnalyticsService,
    private readonly auth: AuthService,
    private readonly criteria: TenantCriteriaService,
    private readonly timestamp: TimestampService
  ) {
    this.ordinalSuffixPipe = new OrdinalSuffixPipe();
    this.listing$.next(data.listing);
    this.form.valueChanges.subscribe((_value) => {
      if (this.form.valid) {
        this.analytics.log('pad_booking_form_valid');
      }
    });
    this.criteria$.subscribe((maybeCriteria: TenantCriteriaID | null) => {
      if (!maybeCriteria) {
        return;
      }
      if (maybeCriteria.credit_score) {
        console.log(
          'disabling the credit score field because the criteria.credit_score is known',
          maybeCriteria
        );
        this.form.get('credit_score')?.setValue(maybeCriteria.credit_score);
        this.form.get('credit_score')?.disable({ emitEvent: false });
      }
      if (maybeCriteria.annual_income) {
        this.form.get('annual_income')?.setValue(maybeCriteria.annual_income);
        this.form.get('annual_income')?.disable({ emitEvent: false });
      }
      if (typeof maybeCriteria.guarantor === 'boolean') {
        this.form.get('guarantor')?.setValue(maybeCriteria.guarantor);
        this.form.get('guarantor')?.disable({ emitEvent: false });
      }
      if (maybeCriteria.funds_available) {
        this.form
          .get('funds_available')
          ?.setValue(maybeCriteria.funds_available);
        this.form.get('funds_available')?.disable({ emitEvent: false });
      }
      if (maybeCriteria.earliest_move_in_date) {
        const earliest_move_in_date = startOfDay(
          this.timestamp.toDate(maybeCriteria.earliest_move_in_date)
        );
        this.form.get('earliest_move_in_date')?.setValue(earliest_move_in_date);
        this.form.get('earliest_move_in_date')?.disable({ emitEvent: false });
      }
    });
  }

  async scheduleViewing(): Promise<void> {
    const data: PadBookShowingOutput = this.output;
    return this.dialogRef.close(data);
  }

  get temp_output(): PadBookShowingOutput {
    try {
      return this.output;
    } catch (e) {
      return {} as PadBookShowingOutput;
    }
  }

  get output(): PadBookShowingOutput {
    const date = new Date();
    const todayStart = startOfDay(date);
    const todayEnd = addHours(todayStart, 17);
    const tomorrowStart = addDays(addHours(todayStart, 9), 1);
    const dayAfterTomorrowStart = addDays(addHours(todayStart, 9), 2);
    const tomorrowEnd = addDays(addHours(todayStart, 17), 1);
    const dayAfterTomorrowEnd = addDays(addHours(todayStart, 17), 2);
    const today = `${format(date, 'h:mmaaa')}-${format(todayEnd, 'h:mmaaa')}`;
    const tomorrow = `${format(tomorrowStart, 'h:mmaaa')}-${format(
      tomorrowEnd,
      'h:mmaaa'
    )}`;
    const dayAfterTomorrow = `${format(
      dayAfterTomorrowStart,
      'h:mmaaa'
    )}-${format(dayAfterTomorrowEnd, 'h:mmaaa')}`;

    this.today = today;
    this.tomorrow = tomorrow;
    this.today_start = date;
    this.today_end = todayEnd;
    this.tomorrow_start = tomorrowStart;
    this.tomorrow_end = tomorrowEnd;
    this.day_after_tomorrow_start = dayAfterTomorrowStart;
    this.day_after_tomorrow_end = dayAfterTomorrowEnd;
    this.day_after_tomorrow = dayAfterTomorrow;
    const viewing_type: 'virtual' | 'in_person' | 'virtual_or_in_person' =
      this.form.get('viewing_type')?.value;
    const prefers = viewing_type.includes('in_person')
      ? viewing_type.includes('virtual')
        ? 'virtual_or_in_person'
        : 'in_person'
      : 'virtual';
    const plan_to_hire_movers = !!this.form.get('plan_to_hire_movers')?.value;
    return {
      today: this.today,
      today_start: this.today_start,
      today_end: this.today_end,
      tomorrow: this.tomorrow,
      tomorrow_start: this.tomorrow_start,
      tomorrow_end: this.tomorrow_end,
      day_after_tomorrow_start: this.day_after_tomorrow_start,
      day_after_tomorrow_end: this.day_after_tomorrow_end,
      day_after_tomorrow: this.day_after_tomorrow,
      credit_score: this.form.get('credit_score')?.value,
      annual_income: this.form.get('annual_income')?.value,
      guarantor: this.form.get('guarantor')?.value,
      funds_available: this.form.get('funds_available')?.value,
      earliest_move_in_date: this.form
        .get('earliest_move_in_date')
        ?.value.toString(),
      has_vaccine: true,
      prefers,
      plan_to_hire_movers,
      updatedAt: serverTimestamp(),
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export const fullyVaccinatedValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: false | { name: string; value: boolean } = control.value;
    if (typeof value === 'boolean') {
      return null;
    }
    if (typeof value === 'object' && !value.value) {
      return { require_vax: true };
    }
    return null;
  };
};
