import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from '@angular/fire/firestore';
import {
  ReplaceDateWithNgTimestamp,
  UserProfileInterface,
  UserProfileWithDate,
  UserProfileWithDateModel,
  WithId,
} from '@padspin/models';

export const angularUserProfileConverter: FirestoreDataConverter<UserProfileWithDateModel> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options: SnapshotOptions | undefined
    ): WithId<UserProfileWithDate> => {
      return angularUserProfileWithDateModelFromDocumentData(
        snapshot.data(options),
        snapshot.id
      );
    },
    toFirestore: (
      o:
        | WithFieldValue<UserProfileWithDateModel | UserProfileInterface>
        | PartialWithFieldValue<
            UserProfileWithDateModel | UserProfileInterface
          >,
      _options?: SetOptions
    ): DocumentData => {
      const profile: ReplaceDateWithNgTimestamp<UserProfileWithDateModel> = {
        createdAt:
          o.createdAt instanceof Date
            ? Timestamp.fromDate(o.createdAt)
            : o.createdAt instanceof Timestamp
            ? o.createdAt
            : Timestamp.now(),
        email: typeof o.email === 'string' ? o.email : '',
        first_name: typeof o.first_name === 'string' ? o.first_name : '',
        last_name: typeof o.last_name === 'string' ? o.last_name : '',
        phone: typeof o.phone === 'string' ? o.phone : '',
        type: typeof o.type === 'string' ? o.type : 'tenant',
      };
      return profile;
    },
  };

export const angularUserProfileWithDateModelFromDocumentData = (
  data: DocumentData,
  id: string
): WithId<UserProfileWithDateModel> => {
  return {
    id: id,
    email: data['email'],
    type: data['type'] === 'tenant' ? 'tenant' : 'landlord',
    phone: data['phone'],
    first_name: data['first_name'],
    last_name: data['last_name'],
    createdAt:
      data['createdAt'] instanceof Timestamp
        ? data['createdAt'].toDate()
        : data['createdAt'],
  };
};
