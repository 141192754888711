import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FrontendRoutingModule } from './frontend-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFireModule } from '@angular/fire/compat';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { LoginOrRegisterModalModule } from '../login-or-register-modal/login-or-register-modal.module';
import { OnboardingModalModule } from '../onboarding-modal/onboarding-modal.module';
import {
  AngularFireRemoteConfigModule,
  DEFAULTS,
} from '@angular/fire/compat/remote-config';
import { DatePipe } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  COLLECTION_ENABLED,
} from '@angular/fire/compat/analytics';

import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { SideNavModule } from '../side-nav/side-nav.module';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FrontendComponent } from './frontend.component';
import { webEnvironment } from '../../environments/environment';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ListingEditModalModule } from '../listing-edit-modal/listing-edit-modal.module';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { MessageModule } from 'primeng/message';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitterModule } from 'primeng/splitter';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { SkeletonModule } from 'primeng/skeleton';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { VersionModule } from '../version/version.module';
import { Capacitor } from '@capacitor/core';
import { PixelModule } from 'ngx-pixel';

@NgModule({
  declarations: [FrontendComponent],
  imports: [
    BrowserModule,
    PixelModule.forRoot({ enabled: true, pixelId: '311269089285285' }),
    AngularFireModule.initializeApp(webEnvironment.firebase),
    provideFirebaseApp(() => initializeApp(webEnvironment.firebase)),
    AngularFireFunctionsModule,
    provideFirestore(() => getFirestore()),
    AngularFireAuthModule,
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    FrontendRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ScrollingModule,
    LayoutModule,
    MatDialogModule,
    MatSnackBarModule,
    LoginOrRegisterModalModule,
    OnboardingModalModule,
    ListingEditModalModule,
    FooterModule,
    HeaderModule,
    SideNavModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    SelectButtonModule,
    CheckboxModule,
    InputTextModule,
    InputNumberModule,
    CalendarModule,
    InputTextareaModule,
    RadioButtonModule,
    SplitterModule,
    FileUploadModule,
    MessageModule,
    DropdownModule,
    MatDialogModule,
    EditorModule,
    SkeletonModule,
    VersionModule,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    {
      provide: DEFAULTS,
      useValue: { show_book_a_pad_form_before_register_form: true },
    },
    DatePipe,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: COLLECTION_ENABLED,
      useValue: Capacitor.getPlatform() === 'web',
    },
  ],
  exports: [FrontendComponent],
})
export class FrontendModule {}
