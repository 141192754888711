import { Component } from '@angular/core';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'padspin-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(private readonly analytics: GoogleAnalyticsService) {}

  async onClickSocialMedia(
    site:
      | 'facebook'
      | 'instagram'
      | 'twitter'
      | 'linkedin'
      | 'reddit'
      | 'youtube'
      | 'tiktok'
      | 'medium'
  ): Promise<void> {
    await this.analytics.log('click_social_media_link', { site });
  }
}
