import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  WithFieldValue,
} from '@angular/fire/firestore';
import {
  RentalApplicationImpl,
  RentalApplicationModel,
  WithId,
} from '@padspin/models';
import { Timestamp } from '@angular/fire/firestore';

/**
 * Data converter for use in Angular. If you need an
 * application data-converter for NodeJS, see
 * @see nodeApplicationDataConverter
 */
export const angularApplicationDataConverter: FirestoreDataConverter<RentalApplicationModel> =
  {
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options: SnapshotOptions | undefined
    ): RentalApplicationImpl => {
      const data = snapshot.data(options);
      return angularRentalApplicationFromDocumentData(data, snapshot.id);
    },
    toFirestore: (
      modelObject:
        | PartialWithFieldValue<RentalApplicationModel>
        | WithFieldValue<RentalApplicationModel>,
      _options?: SetOptions
    ): DocumentData => {
      const dataWithDatesConvertedToTimestamps = Object.entries(modelObject)
        .map(
          ([k, v]: [string, unknown]) =>
            [k, v instanceof Date ? Timestamp.fromDate(v) : v] as const
        )
        .filter(([_k, v]) => v !== undefined)
        .reduce((acc: Record<string, unknown>, [k, v]) => {
          acc[k] = v;
          return acc;
        }, {});
      dataWithDatesConvertedToTimestamps['ssn'] = encodeSocialSecurityNumber(
        String(modelObject['ssn'])
      );
      return dataWithDatesConvertedToTimestamps;
    },
  };

export const angularRentalApplicationFromDocumentData = (
  data: DocumentData,
  id: string
): WithId<RentalApplicationModel> => {
  data['id'] = id;
  const dataWithTimestampsConvertedToDates = Object.entries(data)
    .map(
      ([k, v]: [string, unknown]) =>
        [k, v instanceof Timestamp ? v.toDate() : v] as const
    )
    .reduce((accum: Record<string, unknown>, [k, v]) => {
      accum[k] = v;
      return accum;
    }, {});
  dataWithTimestampsConvertedToDates['ssn'] = decodeSocialSecurityNumber(
    String(dataWithTimestampsConvertedToDates['ssn'])
  );
  dataWithTimestampsConvertedToDates['ssn'] = String(
    dataWithTimestampsConvertedToDates['ssn']
  );
  const impl = new RentalApplicationImpl(dataWithTimestampsConvertedToDates);
  return { ...impl, id };
};

export const encodeSocialSecurityNumber = (ssn: string) => {
  const eSSN: string = btoa(ssn);
  return eSSN;
};

const decodeSocialSecurityNumber = (eSSN: string) => {
  let ssn: string;
  try {
    ssn = atob(eSSN);
  } catch (error) {
    ssn = eSSN;
  }
  return ssn;
};
