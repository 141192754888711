<div class="pad-expanded-carousel">
  <div *ngIf="featuredPath$" class="pad-expanded-carousel__featured-img">
    <img [src]="featuredPath$ | async" class="feature" (click)="openDialog()" />
    <div class="pad-expanded-carousel__bottom-fade">
      <div class="d-flex">
        <div class="pad-expanded-carousel__group-one">
          <img src="/assets/web/icons/dollarsign-white.svg" alt="Dollar Sign" />
          <h1>{{ price }}</h1>
        </div>
        <div class="pad-expanded-carousel__group-two">
          <img src="/assets/web/icons/bedroom-white.svg" alt="Beds" />
          <p>{{ beds }}</p>
          <img src="/assets/web/icons/bathroom-white.svg" alt="Bathroom" />
          <p>{{ baths }}</p>
          <img
            *ngIf="area"
            src="/assets/web/icons/straighten_white_24dp.svg"
            alt="Area"
          />
          <p *ngIf="area">{{ area }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="pad-expanded-carousel__wrapper">
    <ng-container
      *ngFor="let img of images | slice: 0:imageCount - 1; let i = index"
    >
      <div>
        <img [src]="img" (click)="slideTo(img)" />
      </div>
    </ng-container>
    <div
      *ngIf="images && images[imageCount - 1]"
      class="pad-expanded-carousel__overlay-wrapper"
    >
      <img
        [src]="images[imageCount - 1]"
        (click)="slideTo(images[imageCount - 1])"
      />
      <div
        class="pad-expanded-carousel__load-more-wrapper"
        (click)="loadMore()"
        *ngIf="!loadMoreFlag && shouldLoadMore"
      >
        <div class="pad-expanded-carousel__load-more-overlay">
          <p>{{ remainingImagesLeft }}</p>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let img of images | slice: imageCount; let i = index">
      <div>
        <img
          [src]="img"
          (click)="slideTo(img)"
          *ngIf="loadMoreFlag && shouldLoadMore"
        />
      </div>
    </ng-container>
  </div>
</div>
