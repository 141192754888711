<button padspin-fab-button class="close" (click)="close()">
  <img icon src="/assets/web/icons/close-box-shadow.svg" alt="Close" />
</button>

<button padspin-fab-button [iconWidth]="22" class="prev-main" (click)="prev()">
  <img icon alt="Previous" src="/assets/web/icons/chevronleft.svg" />
</button>
<button padspin-fab-button [iconWidth]="22" class="next-main" (click)="next()">
  <img icon alt="Next" src="/assets/web/icons/chevronright.svg" />
</button>

<img class="main" [src]="image?.url" />

<button padspin-fab-button [iconWidth]="22" class="prev-opts" (click)="prev()">
  <img icon alt="Previous" src="/assets/web/icons/chevronleft.svg" />
</button>
<button padspin-fab-button [iconWidth]="22" class="next-opts" (click)="next()">
  <img icon alt="Next" src="/assets/web/icons/chevronright.svg" />
</button>

<aside class="opts">
  <img
    #padspinThumbnail
    *ngFor="let img of images; let i = index;"
    [src]="img.url"
    [class.selected]="img.selected"
    (click)="changeTo(i)"
    alt="Picture of listing"
  />
</aside>
