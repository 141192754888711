export interface FilterInterface {
  location?: string;
  beds?: { min: number };
  price?: { max: number };
  baths?: { min: number };
  available?: { after: Date };
  ac?: { required: true };
  laundry?: { inSuite: true };
  dishwasher?: { has: boolean };
  pets?: { allows: boolean };
  gym?: { has: boolean };
  fireplace?: { has: boolean };
  elevator?: { has: boolean };
  highCeilings?: { has: boolean };
  pool?: { has: boolean };
  woodFloors?: { has: boolean };
  parking?: { has: boolean };
  patio?: { has: boolean };
}

export const filterName = 'filter' as const;

export class FilterModel implements FilterInterface {
  location?: string;
  beds?: { min: number };
  price?: { max: number };
  baths?: { min: number };
  available?: { after: Date };
  ac?: { required: true };
  laundry?: { inSuite: true };
  dishwasher?: { has: boolean };
  pets?: { allows: boolean };
  gym?: { has: boolean };
  fireplace?: { has: boolean };
  elevator?: { has: boolean };
  highCeilings?: { has: boolean };
  pool?: { has: boolean };
  woodFloors?: { has: boolean };
  parking?: { has: boolean };
  patio?: { has: boolean };
  constructor(filter: FilterModel) {
    Object.assign(this, filter);
  }
}
