import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimestampToDatePipe } from './timestamp-to-date.pipe';
import { TimestampService } from './timestamp.service';

export { TimestampService };

@NgModule({
  declarations: [TimestampToDatePipe],
  imports: [CommonModule],
  exports: [TimestampToDatePipe],
  providers: [TimestampService],
})
export class TimestampToDatePipeModule {}
