import { Injectable } from '@angular/core';
import { google } from 'google-maps';
import {
  convertCoordToGeoPoint,
  convertCoordToLatLng,
  convertCoordToLatLngArray,
  convertCoordToLatLngLiteral,
  CoordTypes,
  GeoPoint,
  isGeoPoint,
  isLatLng,
  isLatLngArray,
  isLatLngLiteral,
  LatLngArray,
  LatLngLiteral,
  typeOfCoord,
} from '@padspin/mapping';

@Injectable({
  providedIn: 'root',
})
export class MapUtilService {
  isLatLngLiteral(
    coord: LatLngLiteral | google.maps.LatLng | LatLngArray | GeoPoint
  ): coord is LatLngLiteral {
    return isLatLngLiteral(coord);
  }

  isLatLng(
    latlng: LatLngLiteral | google.maps.LatLng | LatLngArray | GeoPoint
  ): latlng is google.maps.LatLng {
    return isLatLng(latlng);
  }

  isLatLngArray(
    latlng: LatLngLiteral | google.maps.LatLng | LatLngArray
  ): latlng is LatLngArray {
    return isLatLngArray(latlng);
  }

  isGeoPoint(
    coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray
  ): coord is GeoPoint {
    return isGeoPoint(coord);
  }

  typeOfCoord(
    coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray
  ): CoordTypes {
    return typeOfCoord(coord);
  }

  convertCoordToLatLngArray(
    coord: LatLngLiteral | LatLngArray | google.maps.LatLng | GeoPoint
  ): LatLngArray {
    return convertCoordToLatLngArray(coord);
  }

  convertCoordToLatLng(
    coord: LatLngLiteral | LatLngArray | google.maps.LatLng | GeoPoint
  ): google.maps.LatLng {
    return convertCoordToLatLng(coord);
  }

  convertCoordToLatLngLiteral(
    coord:
      | LatLngLiteral
      | LatLngArray
      | google.maps.LatLng
      | GeoPoint
      | undefined
  ): LatLngLiteral {
    return convertCoordToLatLngLiteral(coord);
  }

  convertCoordToGeoPoint(
    coord: GeoPoint | LatLngLiteral | google.maps.LatLng | LatLngArray
  ): GeoPoint {
    return convertCoordToGeoPoint(coord);
  }
}
