import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PadExpandedCarouselTwoComponent } from './pad-expanded-carousel-two.component';
import { YoutubeModule } from '../../youtube/youtube.module';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@padspin/ui';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [PadExpandedCarouselTwoComponent],
  imports: [
    CommonModule,
    YoutubeModule,
    RouterModule,
    ButtonModule,
    GoogleMapsModule,
  ],
  exports: [PadExpandedCarouselTwoComponent],
})
export class PadExpandedCarouselTwoModule {}
