import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingModalComponent } from './onboarding-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@padspin/ui';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { OnboardingStepperModule } from '../onboarding-stepper/onboarding-stepper.module';
import { RouterModule, Routes } from '@angular/router';
import { RegisterModule } from '../register/register.module';
import { LoginModule } from '../login/login.module';
import { MatDialogModule } from '@angular/material/dialog';

const routes: Routes = [{ path: '', component: OnboardingModalComponent }];

@NgModule({
  declarations: [OnboardingModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    CdkStepperModule,
    OnboardingStepperModule,
    RegisterModule,
    LoginModule,
    RouterModule.forChild(routes),
    MatDialogModule,
  ],
})
export class OnboardingModalModule {}
