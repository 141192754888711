import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { ButtonModule } from '@padspin/ui';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { AdminPhoneModule } from '../admin-phone/admin-phone.module';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule,
    MatMenuModule,
    AdminPhoneModule,
  ],
})
export class HeaderModule {}
