import { firestore } from 'firebase-admin';
import Timestamp = firestore.Timestamp;
import { FieldValue } from '@angular/fire/firestore';
export const messageSMSName = 'messages_sms' as const;

/**
 * These messages are combined from emails, sms messages, and in-app messages.
 * These messages are all from leads or tenants, to the padspin support.
 */
export class MessageSMSModel {
  /** E.164 Format (eg: "+17788687447") */
  phone!: string;
  email?: string;
  uid?: string;
  message!: string;
  created!: FieldValue | Timestamp;
}
