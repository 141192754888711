import { Pipe, PipeTransform } from '@angular/core';
import { formatRelative } from 'date-fns';
import { enUS } from 'date-fns/locale';

@Pipe({
  name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {
  transform(value: unknown): string {
    const formatRelativeLocale = {
      lastWeek: "'last' eeee 'at' p",
      yesterday: "'yesterday'",
      today: 'p',
      tomorrow: "'tomorrow at' p",
      nextWeek: "eeee 'at' p",
      other: 'P',
    } as const;
    const locale: Locale = {
      ...enUS,
      formatRelative: (token: keyof typeof formatRelativeLocale) =>
        formatRelativeLocale[token],
    };
    if (value && value instanceof Date) {
      let formatted: string;
      try {
        formatted = formatRelative(value, new Date(), { locale });
      } catch (e) {
        console.log(`unable to format date: ${value}`, e);
        return '';
      }
      return formatted;
    }
    return '';
  }
}
