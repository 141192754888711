import { Public } from './types/public';
import { FieldValue } from '@angular/fire/firestore';
import { firestore } from 'firebase-admin';
import Timestamp = firestore.Timestamp;
import { getGeohashBounds, LatLngBounds } from '@padspin/mapping';

export const neighborhoodModelName = 'neighborhoods';

/** {@link AddressType} */
export const neighbourhoodPlaceTypes = [
  'neighborhood',
  'administrative_area_level_2',
  'administrative_area_level_1',
  'locality',
  'political',
  'sublocality',
] as const;

/**
 * Neighborhoods are our cache of places, specifically, only of selected types
 * {@link neighbourhoodPlaceTypes}
 */
export interface NeighborhoodModel {
  /** @example "Delta" */
  main_text: string;
  /**
   * Formatted address.
   * @example "Delta, BC, Canada"
   */
  description?: string;
  /** @example "ChIJtwKI2-H8hVQRm63IwyoFr-c" */
  place_id: string;
  lat: number;
  lng: number;
  bounds_ne_lat: number;
  bounds_ne_lng: number;
  bounds_sw_lat: number;
  bounds_sw_lng: number;
  bounding_geohash: string;
  bounding_geohash_2: string;
  updated?: FieldValue | Timestamp;
}

export class NeighborhoodImpl implements NeighborhoodModel {
  main_text!: string;
  description?: string;
  place_id!: string;
  lat!: number;
  lng!: number;
  bounds_ne_lat!: number;
  bounds_ne_lng!: number;
  bounds_sw_lat!: number;
  bounds_sw_lng!: number;
  get bounding_geohash() {
    const bounds: LatLngBounds = {
      northeast: { lat: this.bounds_ne_lat, lng: this.bounds_ne_lng },
      southwest: { lat: this.bounds_sw_lat, lng: this.bounds_sw_lng },
    };
    return getGeohashBounds(bounds);
  }
  get bounding_geohash_2() {
    const bounds: LatLngBounds = {
      northeast: { lat: this.bounds_ne_lat, lng: this.bounds_ne_lng },
      southwest: { lat: this.bounds_sw_lat, lng: this.bounds_sw_lng },
    };
    const hash = getGeohashBounds(bounds);
    return hash.length >= 2 ? hash.slice(0, 2) : '';
  }
  updated?: FieldValue | Timestamp;
  constructor(
    neighborhood: Public<
      Omit<NeighborhoodModel, 'bounding_geohash' | 'bounding_geohash_2'>
    >
  ) {
    this.main_text = neighborhood.main_text;
    this.description = neighborhood.description;
    this.place_id = neighborhood.place_id;
    this.lat = neighborhood.lat;
    this.lng = neighborhood.lng;
    this.bounds_ne_lat = neighborhood.bounds_ne_lat;
    this.bounds_ne_lng = neighborhood.bounds_ne_lng;
    this.bounds_sw_lat = neighborhood.bounds_sw_lat;
    this.bounds_sw_lng = neighborhood.bounds_sw_lng;
    this.updated = neighborhood.updated;
  }

  toJson() {
    return {
      main_text: this.main_text,
      description: this.description,
      place_id: this.place_id,
      lat: this.lat,
      lng: this.lng,
      bounds_ne_lat: this.bounds_ne_lat,
      bounds_ne_lng: this.bounds_ne_lng,
      bounds_sw_lat: this.bounds_sw_lat,
      bounds_sw_lng: this.bounds_sw_lng,
      bounding_geohash: this.bounding_geohash,
      bounding_geohash_2: this.bounding_geohash_2,
      updated: this.updated,
    };
  }
}
