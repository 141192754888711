import { Public } from './types/public';

export const notificationPreferencesName = 'notification_preferences' as const;

export class NotificationPreferences {
  public tenant_sms_match!: boolean;
  constructor(
    preferences: Public<NotificationPreferences> = {
      tenant_sms_match: true,
    }
  ) {
    Object.assign(this, preferences);
  }
}
