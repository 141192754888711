import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PadExpandedCarouselDialogComponent } from './pad-expanded-carousel-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@padspin/ui';

@NgModule({
  declarations: [PadExpandedCarouselDialogComponent],
  imports: [CommonModule, MatDialogModule, ButtonModule],
  exports: [PadExpandedCarouselDialogComponent],
})
export class PadExpandedCarouselDialogModule {}
