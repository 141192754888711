import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BookingService } from '../../booking.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LatLngLiteral } from '../lat-lng-literal';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HeaderService } from '../../header.service';
import {
  BehaviorSubject,
  delay,
  firstValueFrom,
  Observable,
  OperatorFunction,
  ReplaySubject,
  Subject,
  withLatestFrom,
  combineLatest,
} from 'rxjs';
import { FooterService } from '../../footer.service';
import { ListingsModelID } from '@padspin/models';
import { Listings2Service } from '../../listings-2.service';
import { AuthService } from '../../auth.service';
import { Timestamp } from '@angular/fire/firestore';
import { addDays } from 'date-fns';
import { UuidService } from '../../uuid.service';
import { ListingsSeoService } from '../../listings-seo.service';
import { DOCUMENT } from '@angular/common';
import { GoogleMap } from '@angular/google-maps';
import { MapLoadingService } from '../../map-loading.service';
import { MatDialog } from '@angular/material/dialog';
import { AfterBookViewingComponent } from '../../after-book-viewing/after-book-viewing.component';

@Component({
  selector: 'padspin-pad-expanded',
  templateUrl: './pad-expanded.component.html',
  styleUrls: ['./pad-expanded.component.scss'],
})
export class PadExpandedComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @ViewChild('streetview', { read: ElementRef }) streetviewRef!: ElementRef;
  @ViewChild('streetview') streetview!: GoogleMap;

  pad$ = new ReplaySubject<ListingsModelID>(1);
  @Input()
  set pad(listing: ListingsModelID) {
    this.pad$.next(listing);
  }

  available$: Observable<Date> = this.pad$.pipe(
    map((value) => value.available_from_date),
    map((available) => {
      if (typeof available === 'string') {
        return new Date(available);
      } else if (available instanceof Timestamp) {
        return available.toDate();
      } else {
        return available;
      }
    })
  );

  youtubeIds$: Observable<string[]> = this.pad$.pipe(
    map((pad) => pad.video_urls),
    map((urls) =>
      urls.map((str) => {
        const url = new URL(str);
        return url.host === 'www.youtube.com' ||
          url.host === 'youtube.com' ||
          url.host === 'youtu.be'
          ? url.searchParams.get('v') || url.pathname.split('/')[2] || ''
          : undefined;
      })
    ),
    map((ids) => ids.filter((videoId) => !!videoId) as string[])
  );

  /**
   * Even if the apartment is ready, we still need at least two days' lead
   * time to get the move-in organized and perform background checks and
   * paperwork. So the minimum available date is 2 days in the future.
   */
  availablePublic$: Observable<Date> = this.available$.pipe(
    map((date) => {
      const currentDate = new Date();
      if (currentDate > date) {
        return addDays(currentDate, 2);
      } else {
        return addDays(date, 2);
      }
    })
  );

  isAdmin$ = this.authSvc.isAdmin$;

  isActive$: Observable<boolean> = this.pad$.pipe(map((p) => p.is_active));
  isInactive$: Observable<boolean> = this.isActive$.pipe(
    map((active) => !active)
  );
  isMyListing$: Observable<boolean> = combineLatest([
    this.pad$,
    this.authSvc.currentUserUID$,
  ]).pipe(map(([pad, uid]) => pad.user_id === uid));
  isVisible$: Observable<boolean> = combineLatest([
    this.isAdmin$,
    this.isMyListing$,
    this.isActive$,
  ]).pipe(map(([isAdmin, isMine, isActive]) => isAdmin || isMine || isActive));
  isHidden$: Observable<boolean> = this.isVisible$.pipe(
    map((isVisible) => !isVisible)
  );
  isMessageDisplayed$: Observable<boolean> = combineLatest([
    this.isAdmin$,
    this.isMyListing$,
    this.isInactive$,
  ]).pipe(
    map(([isAdmin, isMine, isInactive]) => (isAdmin || isMine) && isInactive)
  );

  isMapDirty$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly bookingSvc: BookingService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly header: HeaderService,
    private readonly footer: FooterService,
    private readonly listings2: Listings2Service,
    private readonly authSvc: AuthService,
    private readonly uuidSvc: UuidService,
    private readonly listingsSeoSvc: ListingsSeoService,
    @Inject(DOCUMENT) private document: Document,
    private readonly mapLoadingSvc: MapLoadingService,
    private readonly dialog: MatDialog
  ) {
    this.header.setType('tenant');
    this.footer.setVisibility(false);
    this.route.params
      .pipe(
        map((params) => {
          return params;
        }),
        filter((params) => {
          const p = params as Params;
          return typeof p['listingIdOrSlug'] === 'string';
        }) as OperatorFunction<unknown, Params>,
        switchMap((params) => {
          const { listingIdOrSlug, slugFriendly } = params;
          return this.uuidSvc.isSlug(listingIdOrSlug)
            ? this.listings2.getListingBySlug$(listingIdOrSlug, slugFriendly)
            : this.listings2.getListingByID$(listingIdOrSlug);
        }),
        tap((pad) => (this.pad = pad ?? this.pad)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  async openBookingDialog(): Promise<void> {
    const pad: ListingsModelID = await firstValueFrom(this.pad$);
    if (!pad) {
      return;
    }
    const isBooked = await this.bookingSvc.bookShowing(pad);
    if (isBooked) {
      this.dialog.open(AfterBookViewingComponent);
      this.router.navigate(['/move']);
    }
  }

  toSearch(): void {
    this.router.navigate(['/search'], {});
  }

  async toEdit(): Promise<void> {
    const pad = await firstValueFrom(this.pad$);
    const slug = pad.slug;
    this.router.navigate([`l/${slug}/edit`]);
  }

  ngOnInit() {
    const tag = this.document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    this.document.body.appendChild(tag);
  }

  ngAfterViewInit(): void {
    this.mapLoadingSvc.isMapLoaded$
      .pipe(
        delay(1000),
        withLatestFrom(this.pad$),
        tap(([_, pad]) => {
          if (!this.streetview) {
            return;
          }
          const streetView = this.streetview.getStreetView();
          const target: LatLngLiteral = {
            lat: pad.street_view_latitude,
            lng: pad.street_view_longitude,
          };
          streetView.setOptions({
            position: target,
            disableDefaultUI: true,
            enableCloseButton: false,
          });
          // setVisible scrolls the map into the viewport, so only call it when
          // the user scrolls down until the map is fully in viewport.
          const observer = new IntersectionObserver(
            (entries) => {
              if (entries[0] && entries[0].isIntersecting) {
                streetView.setVisible(true);
                observer.disconnect();
              }
            },
            { threshold: 1.0 }
          );
          observer.observe(this.streetviewRef?.nativeElement);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  hide360(): void {
    this.isMapDirty$.next(true);
  }

  show360(): void {
    this.isMapDirty$.next(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
