import { Injectable } from '@angular/core';
import { serverTimestamp } from '@angular/fire/firestore';

/**
 * Use this class to inject certain firestore functions that would require
 * an emulator just to run unit tests.
 */
@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  get serverTimestamp() {
    return serverTimestamp;
  }
}
