import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  Subject,
  withLatestFrom,
} from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import {
  PadExpandedCarouselDialogComponent,
  PadExpandedCarouselDialogComponentInput,
  PadExpandedCarouselDialogComponentInputData,
} from '../pad-expanded-carousel/pad-expanded-carousel-dialog/pad-expanded-carousel-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LatLngLiteral } from '@padspin/mapping';
import { GoogleMap } from '@angular/google-maps';
import ResizeObserver from 'resize-observer-polyfill';

export type CarouselMedia =
  | { type: 'image' | 'video'; value: string }
  | { type: 'street_view'; value: LatLngLiteral };

@Component({
  selector: 'padspin-pad-expanded-carousel-two',
  templateUrl: './pad-expanded-carousel-two.component.html',
  styleUrls: ['./pad-expanded-carousel-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PadExpandedCarouselTwoComponent
  implements AfterViewInit, OnDestroy
{
  #destroy$ = new Subject<void>();
  @ViewChildren('streetview', { read: ElementRef })
  streetviewRefs!: QueryList<ElementRef>;
  @ViewChildren('streetveiw', { read: ViewContainerRef })
  streetviewComponentRefs!: QueryList<GoogleMap>;
  @ViewChild('streetview', { read: ElementRef }) streetviewRef!: ElementRef;
  @ViewChild('streetview') streetview!: GoogleMap;
  @ViewChild('media', { read: ElementRef }) mediaRef!: ElementRef;
  streetViewWidth$ = new BehaviorSubject<string>('300px');
  observer?: ResizeObserver;
  images$ = new BehaviorSubject<CarouselMedia[]>([]);
  videos$ = new BehaviorSubject<CarouselMedia[]>([]);
  street_view$ = new BehaviorSubject<CarouselMedia[]>([]);
  media$: Observable<CarouselMedia[]> = combineLatest([
    this.images$,
    this.videos$,
    this.street_view$,
  ]).pipe(
    map(([images, videos, street_views]) => [
      ...videos,
      ...images,
      ...street_views,
    ])
  );
  selectedIndex$ = new BehaviorSubject<number>(0);
  selection$: Observable<CarouselMedia> = this.selectedIndex$.pipe(
    withLatestFrom(this.media$),
    filter(([_index, media]) => media.length > 0),
    map(([index, media]) => {
      if (index < 0) {
        this.selectedIndex$.next(media.length - 1);
        return media[0];
      } else if (index >= media.length) {
        this.selectedIndex$.next(0);
        return media[media.length - 1];
      } else {
        return media[index];
      }
    })
  );

  isMapDirty$ = new BehaviorSubject<boolean>(false);

  @Input() set images(images: string[]) {
    this.images$.next(images.map((value) => ({ value, type: 'image' })));
  }

  @Input() set videos(videos: string[]) {
    this.videos$.next(videos.map((value) => ({ value, type: 'video' })));
  }

  @Input() set center(center: LatLngLiteral) {
    this.street_view$.next([{ type: 'street_view', value: center }]);
  }

  @Input() price = 0;
  @Input() beds = 0;
  @Input() baths = 0;
  @Input() area = 0;

  defaultTouch = { x: 0, y: 0, time: 0 };

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  async handleTouch(event: TouchEvent) {
    const touch = event.touches[0] || event.changedTouches[0];
    if (!event) {
      return;
    }
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      const deltaX = touch.pageX - this.defaultTouch.x;
      const deltaTime = event.timeStamp - this.defaultTouch.time;
      if (deltaTime < 500) {
        const selection = await firstValueFrom(this.selection$);
        const isStreetView = selection.type === 'street_view';
        if (Math.abs(deltaX) > 60 && !isStreetView) {
          if (deltaX > 0) {
            this.prev();
          } else {
            this.next();
          }
          event.preventDefault();
        }
      }
    }
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly zone: NgZone
  ) {}

  ngAfterViewInit(): void {
    this.observer = new ResizeObserver(() => {
      this.zone.run((_entries) => {
        this.resizeStreetView();
      });
    });
    this.streetviewComponentRefs.changes
      .pipe(takeUntil(this.#destroy$))
      .subscribe((_ITEMS) => {
        this.resizeStreetView();
      });
    this.streetviewRefs.changes
      .pipe(takeUntil(this.#destroy$))
      .subscribe((_items) => {
        this.resizeStreetView();
      });
    this.selection$
      .pipe(
        tap(() => this.observer?.observe(this.mediaRef.nativeElement)),
        first()
      )
      .subscribe();
  }

  resizeStreetView(): void {
    const parent: HTMLDivElement = this.mediaRef.nativeElement;
    const parentWidth = parent.offsetWidth;
    if (!parent) {
      return;
    }
    const width = `${parentWidth}px`;
    this.streetViewWidth$.next(width);
    this.showStreetView();
  }

  showStreetView(): void {
    if (!this.streetview) {
      return;
    }
    const streetView = this.streetview.getStreetView();
    const latlngliteral = this.street_view$.value[0].value;
    if (typeof latlngliteral === 'string') {
      return;
    }
    streetView.setOptions({
      position: latlngliteral,
      enableCloseButton: false,
      addressControl: false,
      fullscreenControl: true,
    });
    streetView.setVisible(true);
  }

  async next(): Promise<void> {
    const currentIndex = await firstValueFrom(this.selectedIndex$);
    this.selectedIndex$.next(currentIndex + 1);
  }

  async prev(): Promise<void> {
    const currentIndex = await firstValueFrom(this.selectedIndex$);
    this.selectedIndex$.next(currentIndex + -1);
  }

  async slideTo(media: CarouselMedia) {
    const medias = await firstValueFrom(this.media$);
    const index = medias.lastIndexOf(media);
    this.selectedIndex$.next(index);
  }

  async openFullscreenCarousel(media: CarouselMedia) {
    const images: string[] = await firstValueFrom(
      this.images$.pipe(
        map(
          (arr) =>
            arr
              .filter((m) => typeof m.value === 'string')
              .map((i) => i.value) as string[]
        )
      )
    );
    if (typeof media.value !== 'string') {
      return;
    }
    const data: PadExpandedCarouselDialogComponentInputData = {
      featuredPath: media.value,
      images,
    };
    this.dialog.open(PadExpandedCarouselDialogComponent, <
      PadExpandedCarouselDialogComponentInput
    >{
      panelClass: 'pad-expanded-carousel-dialog-container',
      data,
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '100vw',
      height: '100vh',
      backdropClass: 'modal_backdrop',
    });
  }

  hide360(): void {
    this.isMapDirty$.next(true);
  }

  show360(): void {
    this.isMapDirty$.next(false);
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
