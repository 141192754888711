import { Public } from './types/public';
import { FieldValue, Timestamp as TimestampNg } from '@angular/fire/firestore';
import { firestore } from 'firebase-admin';
import TimestampAdmin = firestore.Timestamp;
import { UserType } from './profile.model';

// /** viewing/{uid} */
// export const viewingsName = 'viewing' as const;
// /** List of viewings for a user */
// export class Viewings {
//   items!: Record<string, Viewing>;
//   constructor(notificationPreferences: Public<Viewings>) {
//     Object.assign(this, notificationPreferences);
//   }
// }

/** viewings/{viewingid}*/
export const viewingName = 'viewings' as const;
/** One of a user's viewings */
export class Viewing {
  user_id?: string | null;
  annual_income!: number;
  createdAt!: TimestampNg | TimestampAdmin | FieldValue;
  credit_score!: number;
  earliest_move_in_date?: string;
  email!: string;
  first_name!: string;
  funds_available!: number;
  guarantor!: string;
  has_vaccine?: boolean;
  last_name!: string;
  listing_slug?: string | null;
  listingId!: string;
  phone!: string;
  prefers!: 'virtual' | 'in_person' | 'virtual_or_in_person';
  today!: string;
  today_start?: TimestampNg | TimestampAdmin | FieldValue;
  today_end?: TimestampNg | TimestampAdmin | FieldValue;
  tomorrow!: string;
  tomorrow_start?: TimestampNg | TimestampAdmin | FieldValue;
  tomorrow_end?: TimestampNg | TimestampAdmin | FieldValue;
  day_after_tomorrow!: string;
  day_after_tomorrow_start?: TimestampNg | TimestampAdmin | FieldValue;
  day_after_tomorrow_end?: TimestampNg | TimestampAdmin | FieldValue;
  type!: UserType;
  plan_to_hire_movers?: boolean;
  updatedAt!: TimestampNg | TimestampAdmin | FieldValue;
  constructor(notificationPreferences: Public<Viewing>) {
    Object.assign(this, notificationPreferences);
  }
}
