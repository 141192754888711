/** Copy of firebase.firestore.GeoPoint because it is not always defined at runtime. */
export class GeoPoint {
  constructor(
    public readonly latitude: number,
    public readonly longitude: number
  ) {}

  isEqual(other: GeoPoint): boolean {
    return (
      other.latitude === this.latitude && other.longitude === this.longitude
    );
  }
}
