import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPreviewComponent } from './user-preview.component';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';

@NgModule({
  declarations: [UserPreviewComponent],
  imports: [CommonModule, UserAvatarModule],
  exports: [UserPreviewComponent],
})
export class UserPreviewModule {}
