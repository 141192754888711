import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YoutubeComponent } from './youtube.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [YoutubeComponent],
  imports: [CommonModule, YouTubePlayerModule, SkeletonModule],
  exports: [YoutubeComponent],
})
export class YoutubeModule {}
