import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { LatLngLiteral } from '../lat-lng-literal';
import {
  PadExpandedCarouselDialogComponent,
  PadExpandedCarouselDialogComponentInput,
} from './pad-expanded-carousel-dialog/pad-expanded-carousel-dialog.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'padspin-pad-expanded-carousel',
  templateUrl: './pad-expanded-carousel.component.html',
  styleUrls: ['./pad-expanded-carousel.component.scss'],
})
export class PadExpandedCarouselComponent implements OnInit {
  #destroy$ = new Subject<void>();

  /* Constants */
  readonly BREAKPOINT_SM = 640;
  readonly BREAKPOINT_LG = 1024;
  readonly ITEM_MOBILE_COUNT = 4;
  readonly ITEM_TABLET_COUNT = 5;
  readonly ITEM_DESKTOP_COUNT = 8;

  /* Number of imgs to load; varies depending on screen width */
  public imageCount = 0;
  @Input() images: string[] = [];
  @Input() price = 0;
  @Input() beds = 0;
  @Input() baths = 0;
  @Input() lat = 0;
  @Input() lng = 0;
  @Input() area = 0;
  public loadMoreFlag = false;
  /* Set to true if image length > IMAGECOUNT */
  public shouldLoadMore = false;
  public featuredPath$: BehaviorSubject<string> = new BehaviorSubject('');
  public remainingImagesLeft = '';
  private resize$ = this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.XLarge,
  ]);
  constructor(
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    /* Inits first image to first element of array */
    this.featuredPath$.next(this.images[0]);
    this.setShouldLoadMore();
    this.setRemainingImagesLeft();
    this.resize$.pipe(takeUntil(this.#destroy$)).subscribe((resize) => {
      if (resize.breakpoints[Breakpoints.XSmall]) {
        this.imageCount = this.ITEM_MOBILE_COUNT;
      } else if (resize.breakpoints[Breakpoints.Small]) {
        this.imageCount = this.ITEM_TABLET_COUNT;
      } else {
        this.imageCount = this.ITEM_DESKTOP_COUNT;
      }
    });
  }

  private setShouldLoadMore(): void {
    if (this.images.length > this.imageCount) {
      this.shouldLoadMore = true;
    }
  }

  private setRemainingImagesLeft(): void {
    if (this.shouldLoadMore) {
      this.remainingImagesLeft = this.images.length - this.imageCount + '+';
    }
  }

  loadMore(): void {
    this.loadMoreFlag = true;
  }

  slideTo(path: string): void {
    this.featuredPath$.next(path);
  }

  back(): void {
    const loc: LatLngLiteral = { lat: this.lat, lng: this.lng };
    this.router.navigate(['/search'], {
      queryParams: { ...loc },
    });
  }

  openDialog(): void {
    this.dialog.open(PadExpandedCarouselDialogComponent, <
      PadExpandedCarouselDialogComponentInput
    >{
      panelClass: 'pad-expanded-carousel-dialog-container',
      data: {
        featuredPath: this.featuredPath$.value,
        images: this.images,
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '100vw',
      height: '100vh',
      backdropClass: 'modal_backdrop',
    });
  }
}
