<aside
  *ngIf="isSubmitting$ | async; else fillingOutForm"
  style="
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    height: 100vh;
  "
>
  <p-skeleton width="266px" height="193px" shape="circle"></p-skeleton>
  <p-skeleton width="50vw" height="32px" shape="rectangle"></p-skeleton>
  <p-skeleton width="50vw" height="24px" shape="rectangle"></p-skeleton>
  <p-skeleton width="100px" height="47px" shape="rounded"></p-skeleton>
</aside>

<ng-template #fillingOutForm>
  <form [formGroup]="form" #fillingOutForm>
    <h2>Tell us about your pad</h2>

    <div class="label">
      <label>
        <img src="assets/web/icons/home.svg" alt="Home" />
        <span>Pad Type</span>
      </label>
      <p-dropdown [options]="typeOptions" formControlName="type"></p-dropdown>
    </div>

    <div class="label">
      <label>
        <img src="assets/web/icons/bedroom.svg" alt="Bedrooms" />
        <span>Bedrooms</span>
      </label>
      <p-selectButton
        [options]="bedroomOptions"
        formControlName="bedrooms"
      ></p-selectButton>
    </div>

    <div class="label">
      <label>
        <img src="assets/web/icons/square_foot_black_18dp.svg" alt="Area" />
        <span>Area</span>
      </label>
      <div class="controls">
        <p-inputNumber
          formControlName="area"
          [showButtons]="false"
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          [min]="0"
          placeholder="sqft"
          [inputStyle]="{ width: '135px' }"
        ></p-inputNumber>
        <p-message
          severity="error"
          text="Area is required"
          *ngIf="
            form.invalid &&
            form.get('area')?.touched &&
            form.get('area')?.invalid
          "
        ></p-message>
      </div>
    </div>

    <div class="label">
      <label>
        <img
          src="assets/web/icons/stairs_black_24dp.svg"
          alt="Details"
          width="15"
          height="15"
        />
        <span>Floor</span>
      </label>
      <div class="controls">
        <span class="p-float-label">
          <p-inputNumber
            formControlName="floor"
            [showButtons]="true"
            buttonLayout="horizontal"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            [inputStyle]="{ width: '60px' }"
            id="floor"
          ></p-inputNumber>
        </span>
      </div>
    </div>

    <div class="label">
      <label>
        <img src="assets/web/icons/bathroom.svg" alt="Bathrooms" />
        <span>Bathrooms</span>
      </label>
      <div class="controls">
        <p-inputNumber
          formControlName="bathrooms"
          [showButtons]="true"
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          [inputStyle]="{ width: '60px' }"
          [maxFractionDigits]="1"
          [step]="1"
          [min]="1"
        ></p-inputNumber>
        <p-message
          severity="error"
          text="Bathrooms is required"
          *ngIf="
            form.invalid &&
            form.get('bathrooms')?.touched &&
            form.get('bathrooms')?.invalid
          "
        ></p-message>
      </div>
    </div>

    <div class="label">
      <label>
        <img src="assets/web/icons/amenities.svg" alt="Amenities" />
        <span>Amenities</span>
      </label>
      <div class="controls">
        <p-checkbox
          formControlName="central_air"
          id="central_air"
          [binary]="true"
          label="Central Air"
        ></p-checkbox>
        <p-checkbox
          formControlName="dishwasher"
          id="dishwasher"
          [binary]="true"
          label="Dishwasher"
        ></p-checkbox>
        <p-checkbox
          formControlName="doorman"
          id="Doorman"
          [binary]="true"
          label="Doorman"
        ></p-checkbox>
        <p-checkbox
          formControlName="elevator"
          id="elevator"
          [binary]="true"
          label="Elevator"
        ></p-checkbox>
        <p-checkbox
          formControlName="fireplace"
          id="fireplace"
          [binary]="true"
          label="Fireplace"
        ></p-checkbox>
        <p-checkbox
          formControlName="gym"
          id="gym"
          [binary]="true"
          label="Gym"
        ></p-checkbox>
        <p-checkbox
          formControlName="high_ceilings"
          id="high_ceilings"
          [binary]="true"
          label="High Ceilings"
        ></p-checkbox>
        <p-checkbox
          formControlName="outdoor"
          id="outdoor"
          [binary]="true"
          label="Outdoor"
        ></p-checkbox>
        <p-checkbox
          formControlName="parking"
          id="parking"
          [binary]="true"
          label="Parking"
        ></p-checkbox>
        <p-checkbox
          formControlName="pets"
          id="pets"
          [binary]="true"
          label="Pets"
        ></p-checkbox>
        <p-checkbox
          formControlName="pool"
          id="pool"
          [binary]="true"
          label="Pool"
        ></p-checkbox>
        <p-checkbox
          formControlName="washer_dryer"
          id="washer_dryer"
          [binary]="true"
          label="Washer / Dryer"
        ></p-checkbox>
        <p-checkbox
          formControlName="wood_floors"
          id="wood_floors"
          [binary]="true"
          label="Wood Flooring"
        ></p-checkbox>
      </div>
    </div>

    <div class="label">
      <label>
        <img
          src="assets/web/icons/location_black.svg"
          alt="Location"
          width="15"
          height="15"
        />
        <span>Location</span>
      </label>
      <div class="controls">
        <span class="p-float-label">
          <input
            pInputText
            formControlName="address"
            type="text"
            id="address"
          />
          <label for="address">Address</label>
        </span>
        <p-message
          severity="error"
          text="Address is required"
          *ngIf="
            form.invalid &&
            form.get('address')?.touched &&
            form.get('address')?.invalid
          "
        ></p-message>

        <span class="p-float-label">
          <input
            pInputText
            formControlName="unit_number"
            type="text"
            id="unit_number"
          />
          <label for="unit_number">Suite Number</label>
        </span>
        <p-message
          severity="error"
          text="Unit Number error"
          *ngIf="
            form.invalid &&
            form.get('unit_number')?.touched &&
            form.get('unit_number')?.invalid
          "
        ></p-message>

        <span class="p-float-label">
          <input pInputText formControlName="city" type="text" id="city" />
          <label for="city">City</label>
        </span>
        <p-message
          severity="error"
          text="City is required"
          *ngIf="
            form.invalid &&
            form.get('city')?.touched &&
            form.get('city')?.invalid
          "
        ></p-message>

        <span class="p-float-label">
          <input pInputText formControlName="state" type="text" id="state" />
          <label for="state">State</label>
        </span>
        <p-message
          severity="error"
          text="State is required"
          *ngIf="
            form.invalid &&
            form.get('state')?.touched &&
            form.get('state')?.invalid
          "
        ></p-message>

        <span class="p-float-label">
          <input
            pInputText
            formControlName="postal_code"
            type="text"
            id="postal_code"
          />
          <label for="postal_code">Zip Code</label>
        </span>
        <p-message
          severity="error"
          text="Zip Code is required"
          *ngIf="
            form.invalid &&
            form.get('postal_code')?.touched &&
            form.get('postal_code')?.invalid
          "
        ></p-message>
      </div>
    </div>

    <google-map
      *ngIf="addressCoords$ | async as center"
      [center]="center"
      [options]="{
        disableDefaultUI: true,
        draggable: false,
        keyboardShortcuts: false,
        clickableIcons: false
      }"
      width="100%"
      height="250px"
    >
      <map-marker
        [position]="center"
        [options]="{ icon: '/assets/web/icons/location-outlined.svg' }"
      ></map-marker>
    </google-map>

    <div class="label">
      <label>
        <img src="assets/web/icons/dollarsign.svg" alt="Dollars" />
        <span>Current Rent</span>
      </label>
      <div class="controls">
        <p-inputNumber
          formControlName="rent_amount"
          inputId="rent_amount"
          mode="currency"
          currency="USD"
          locale="en-US"
          [showButtons]="true"
          [minFractionDigits]="2"
          [min]="0"
        ></p-inputNumber>
        <p-message
          severity="error"
          text="Rent must be higher than $0"
          *ngIf="
            form.invalid &&
            form.get('rent_amount')?.touched &&
            form.get('rent_amount')?.invalid
          "
        ></p-message>
      </div>
    </div>

    <div class="label">
      <label>
        <img src="assets/web/icons/calendar.svg" alt="Calendar" />
        <span>Available from</span>
      </label>
      <div class="controls">
        <p-calendar
          formControlName="earliest_move_in_date"
          [minDate]="startOfToday"
          placeholder="Choose a date"
          [inline]="true"
        ></p-calendar>
        <p-message
          severity="error"
          text="Specify when the apartment is available"
          *ngIf="
            form.invalid &&
            form.get('earliest_move_in_date')?.invalid &&
            form.get('description')?.touched
          "
        ></p-message>
      </div>
    </div>

    <h2>Describe your pad</h2>
    <div style="display: flex; flex-direction: column">
      <span
        >You know your pad the best. Tell your future tenants what makes your
        pad special.</span
      >
      <p-editor formControlName="description" [style]="{ height: '320px' }">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button
              type="button"
              class="ql-italic"
              aria-label="Italic"
            ></button>
          </span>
        </ng-template>
      </p-editor>
    </div>
    <p-message
      severity="error"
      text="Description is required"
      *ngIf="
        form.invalid &&
        form.get('description')?.touched &&
        form.get('description')?.invalid
      "
    ></p-message>

    <h2>Add Pictures</h2>
    <div>
      <p>
        <strong
          >Photos are the most important aspect to create a successful
          listing.</strong
        >
        Make sure all photos are bright, clear, and show each room in its
        entirety. Multiple angles of rooms help filter people out.
      </p>
      <p-fileUpload
        name="images"
        [multiple]="true"
        accept="image/*"
        [maxFileSize]="8000000"
        [customUpload]="true"
        [auto]="true"
        (uploadHandler)="onFileSelected($event)"
        [showUploadButton]="false"
        [showCancelButton]="false"
        [previewWidth]="105"
      >
        <ng-template pTemplate="content">
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <img
              width="24"
              height="24"
              src="assets/web/icons/file_upload_black_24dp.svg"
              alt="Upload File"
            />
            <p>Upload images</p>
            <small>Or drag an image here to upload</small>
          </div>
        </ng-template>
      </p-fileUpload>
    </div>
    <p-message
      severity="info"
      text="Your pad won't rent without photos"
      *ngIf="!!(currentFilesInvalid$ | async)"
    ></p-message>

    <div class="label">
      <label>
        <img src="assets/web/icons/youtube_black_24dp.svg" alt="Videos" />
        <span>YouTube Video</span>
      </label>
      <div class="controls">
        <input
          pInputText
          formControlName="video_url"
          type="text"
          placeholder="https://youtu.be/your_video"
        />
        <p-message
          severity="error"
          text="We only support youtube videos for now"
          *ngIf="
            form.invalid &&
            form.get('video_url')?.touched &&
            form.get('video_url')?.invalid
          "
        ></p-message>
      </div>
    </div>

    <h2>What is your relationship with the property?</h2>
    <p-radioButton
      value="owner"
      formControlName="user_relation"
      inputId="owner"
      label="I'm the property owner"
    ></p-radioButton>
    <p-radioButton
      value="property_manager"
      formControlName="user_relation"
      inputId="property_manager"
      label="I'm the property manager"
    ></p-radioButton>
    <p-radioButton
      value="lease_owner"
      formControlName="user_relation"
      inputId="lease_owner"
      label="I'm the outgoing tenant"
    ></p-radioButton>
    <p-message
      severity="info"
      text="What is your relation to the property?"
      *ngIf="
        form.invalid &&
        form.get('user_relation')?.touched &&
        form.get('user_relation')?.invalid
      "
    ></p-message>

    <p-messages
      [hidden]="(isUploadingFiles$ | async) !== true"
      [closable]="false"
      [enableService]="false"
      [value]="[
        {
          severity: 'info',
          icon: 'pi pi-spin pi-spinner',
          detail: 'Uploading your photos'
        }
      ]"
    ></p-messages>
    <p-messages
      *ngIf="
        (listing$ | async) &&
        form.valid &&
        (isUploadingFiles$ | async) !== true &&
        (currentFilesInvalid$ | async) !== true &&
        (listingInvalid$ | async) !== true &&
        (isSubmitting$ | async) !== true
      "
      [closable]="false"
      [enableService]="false"
      [value]="[{ severity: 'info', detail: 'Ready to post your listing' }]"
    ></p-messages>
    <p-button
      label="Post My Pad"
      [disabled]="
        form.invalid ||
        !!(currentFilesInvalid$ | async) ||
        !!(listingInvalid$ | async) ||
        !!(isSubmitting$ | async) ||
        !!(isUploadingFiles$ | async)
      "
      (onClick)="post()"
    ></p-button>
  </form>
</ng-template>
<padspin-admin-post-pad-component
  (listing)="migrate($event)"
></padspin-admin-post-pad-component>
