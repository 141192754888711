import { Injectable } from '@angular/core';
import { ListingsModel } from '@padspin/models';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListingsSeoService {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  async addParamsToURL(listing: ListingsModel): Promise<void> {
    const parts = listing.address.split(' ');
    parts.shift();
    if (parts instanceof Array) {
      listing.address = parts.join(' ');
    } else {
      listing.address = parts;
    }
    const queryParams: Params = {
      beds: encodeURIComponent(listing.bedrooms),
      baths: encodeURIComponent(listing.bathrooms),
      address: encodeURIComponent(listing.address),
      price: encodeURIComponent(listing.rent_amount),
      ...(listing.neighborhood
        ? { neighborhood: encodeURIComponent(listing.neighborhood) }
        : {}),
    };
    // Check if query params are already set
    const currentQueryParams: Params = await firstValueFrom(
      this.route.queryParams
    );
    const areAllQueryParamsSet: boolean = Object.keys(queryParams).every(
      (key) => Object.keys(currentQueryParams).includes(key)
    );
    if (areAllQueryParamsSet) {
      return;
    }
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
