import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnboardingInput } from './onboarding-input.type';
import { GoogleAnalyticsService } from '../google-analytics.service';

@Component({
  selector: 'padspin-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
})
export class OnboardingModalComponent {
  /** This component either registers or signs a user in */
  formType: 'register' | 'login' = 'register';
  public showLandlord = true;

  form = this.fb.group({
    /** Client will start as a landlord or tenant */
    accountType: ['tenant', Validators.required],
  });

  constructor(
    private readonly dialogRef: MatDialogRef<OnboardingModalComponent>,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) private readonly input: OnboardingInput,
    private readonly analytics: GoogleAnalyticsService
  ) {
    this.formType = input.register ? 'register' : 'login';
  }

  onLoginSuccess(): void {
    // If a location was passed in, navigate to it
    if (this.input.navigate) {
      this.router.navigate(...this.input.navigate);
    }
    this.closeModal();
  }

  onRegisterSuccess(): void {
    if (this.input.navigate) {
      this.router.navigate(...this.input.navigate);
    }
    this.closeModal();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  onClickLogin(): Promise<void> {
    this.formType = 'login';
    return this.analytics.log('click_login');
  }

  onClickRegister(): Promise<void> {
    this.formType = 'register';
    return this.analytics.log('click_sign_up');
  }
}
