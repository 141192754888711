import { PadType } from './pad-type';
import { WithId } from './types/with-id';

export const listings_name = 'listings' as const;

export type ListingsModelID = WithId<ListingsModel>;

export type ListingsModel = {
  is_active: boolean;
  slug: string;
  /** If the listing has a legacy listing */
  legacyID: string | null;
  /** Ordered imageIds (UUIDv6's of images). When the order is changed update the image_url order */
  images: string[];
  /** Download link for 1200x1200 resized images */
  image_urls_1200: string[];
  /** Download link for 600x600 resized images */
  image_urls_600: string[];
  /** Download link for 200x200 resized images */
  image_urls_200: string[];
  /** Download link for original images */
  image_urls_original: string[];
  /** SMS's require the image type to be JPEG, so always store one JPEG */
  image_jpeg: string | null;
  video_urls: string[];
  /** @example 10071 Ryan Road */
  address: string;
  bathrooms: number;
  bedrooms: number;
  /** sqft */
  area: number;
  /** Air conditioning */
  central_air: boolean;
  cross_street: string | null;
  /**
   * To find the neighborhood (eg: "Upper East Side"), see place_names[0].
   * @example "New York"
   */
  city: string;
  /** Which floor of the building is the unit on? */
  floor: string | null;
  /** Custom neighborhood. Overrides place_names[0] when defined. */
  neighborhood: string | null;
  created: Date | null;
  /**
   * HTML string
   * @example "<p>Bright apartment!</p><p>Requires tenants to have 2 guarantors.</p>"
   */
  description: string;
  dishwasher: boolean;
  doorman: boolean;
  elevator: boolean;
  fireplace: boolean;
  /** @example "84XR4VPG+W7" */
  geohash: string;
  /** @example "ChIJVTspgBnghVQRpF_HitNdvjo" */
  place_id: string;
  gym: boolean;
  high_ceilings: boolean;
  is_exclusive: boolean;
  latitude: number;
  longitude: number;
  street_view_latitude: number;
  street_view_longitude: number;
  /** Organic? Craigslist? etc */
  acquisition_source: string | null;
  available_from_date: Date;
  /**
   * Places that the pad belongs to, from Google's reverse geocoding API.
   * @example ["Southarm", "Richmond", "Metro Vancouver"]
   */
  place_names: string[];
  /**
   * The Google Place ID's of the places the pad belongs to.
   */
  place_ids: string[];
  outdoor: boolean;
  parking: boolean;
  /** true if pets allowed */
  pets: boolean;
  pool: boolean;
  postal_code: string | number;
  rent_amount: number;
  state: string;
  type: PadType;
  unit_number: string | number;
  updated: Date;
  user_id: string;
  user_phone: string;
  user_email: string;
  user_relation: 'owner' | 'property_manager' | 'lease_owner' | null;
  washer_dryer: boolean;
  wood_floors: boolean;
  /** TODO: all questions assume combined income. */
  /** TODO: Collect min credit score of person on lease */
  /** TODO: Do you have a Guarantor? */
  /** TODO: Guarantor's credit score? */
  /** TODO: Guarantor's income? */
  /**
   * Questions that should be on create-account form
   0. How many people on lease? (add new input box for each person)
   1. Everyone on lease has a combined annual income of at least $85,000
   2. Everyone on lease has a credit score of 670 or more
   3. Or you have a guarantor with a minimum credit score of 685 and income of $115,000
   4. Or you have savings of 4 months worth of rent or more
   */
  require_tenant_min_income: number | null;
  require_tenant_max_income: number | null;
  require_tenant_min_credit_score: number | null;
  /**
   * eg: 1 tenant required to make $100k, but 2 tenants required to make only $120k, so for
   * multiple tenants, the requirement is actually $60k.
   */
  require_tenants_combined_min_income_per_person: number | null;
  require_tenants_combined_max_income_per_person: number | null;
  require_guarantor_count: number | null;
  require_guarantor_min_credit: number | null;
  require_guarantor_min_income: number | null;
  /** Other requirements (eg: low ceilings, so tenants under 6'2") */
  require_other: string[];
  /** part of the url that makes the url friendly for SEO */
  slugFriendly?: string;
};

export class ListingsImpl implements ListingsModel {
  is_active: boolean;
  slug: string;
  legacyID: string | null;
  images: string[];
  image_urls_1200: string[];
  image_urls_600: string[];
  image_urls_200: string[];
  image_urls_original: string[];
  image_jpeg: string | null;
  video_urls: string[];
  address: string;
  bathrooms: number;
  bedrooms: number;
  area: number;
  central_air: boolean;
  cross_street: string | null;
  city: string;
  floor: string | null;
  neighborhood: string | null;
  created: Date | null;
  description: string;
  dishwasher: boolean;
  doorman: boolean;
  elevator: boolean;
  fireplace: boolean;
  geohash: string;
  place_id: string;
  gym: boolean;
  high_ceilings: boolean;
  is_exclusive: boolean;
  latitude: number;
  longitude: number;
  street_view_latitude: number;
  street_view_longitude: number;
  acquisition_source: string | null;
  available_from_date: Date;
  place_names: string[];
  place_ids: string[];
  outdoor: boolean;
  parking: boolean;
  pets: boolean;
  pool: boolean;
  postal_code: string | number;
  rent_amount: number;
  state: string;
  type: PadType;
  unit_number: string | number;
  updated: Date;
  user_id: string;
  user_phone: string;
  user_email: string;
  user_relation: 'owner' | 'property_manager' | 'lease_owner' | null;
  washer_dryer: boolean;
  wood_floors: boolean;
  require_tenant_min_income: number | null;
  require_tenant_max_income: number | null;
  require_tenant_min_credit_score: number | null;
  require_tenants_combined_min_income_per_person: number | null;
  require_tenants_combined_max_income_per_person: number | null;
  require_guarantor_count: number | null;
  require_guarantor_min_credit: number | null;
  require_guarantor_min_income: number | null;
  require_other: string[];
  slugFriendly: string | undefined;

  constructor(p: Partial<ListingsModel>) {
    this.is_active = typeof p.is_active === 'boolean' ? p.is_active : false;
    if (!p.slug) {
      throw new Error('LISTING_MUST_CONTAIN_SLUG');
    }
    this.slug = p.slug;
    this.slugFriendly = p.slugFriendly;
    this.legacyID = p.legacyID ? p.legacyID : null;
    this.images = Array.isArray(p.images) ? p.images : [];
    this.image_urls_1200 = Array.isArray(p.image_urls_1200)
      ? p.image_urls_1200
      : [];
    this.image_urls_600 = Array.isArray(p.image_urls_600)
      ? p.image_urls_600
      : [];
    this.image_urls_200 = Array.isArray(p.image_urls_200)
      ? p.image_urls_200
      : [];
    this.image_urls_original = Array.isArray(p.image_urls_original)
      ? p.image_urls_original
      : [];
    this.image_jpeg = p.image_jpeg ? p.image_jpeg : null;
    this.video_urls = Array.isArray(p.video_urls) ? p.video_urls : [];
    // TODO: continue implementing past this point
    this.address = p.address ? p.address : '';
    this.bathrooms = p.bathrooms ? p.bathrooms : 0;
    this.bedrooms = p.bedrooms ? p.bedrooms : 0;
    this.area = p.area ? p.area : 0;
    this.central_air = p.central_air ? p.central_air : false;
    this.cross_street = p.cross_street ? p.cross_street : null;
    this.city = p.city ? p.city : '';
    this.floor = p.floor ? p.floor : null;
    this.neighborhood = p.neighborhood ? p.neighborhood : null;
    this.created = p.created ? p.created : new Date();
    this.description = p.description ? p.description : '';
    this.dishwasher = p.dishwasher ? p.dishwasher : false;
    this.doorman = p.doorman ? p.doorman : false;
    this.elevator = p.elevator ? p.elevator : false;
    this.fireplace = p.fireplace ? p.fireplace : false;
    this.geohash = p.geohash ? p.geohash : '';
    this.place_id = p.place_id ? p.place_id : '';
    this.gym = p.gym ? p.gym : false;
    this.high_ceilings = p.high_ceilings ? p.high_ceilings : false;
    this.is_exclusive = p.is_exclusive ? p.is_exclusive : false;
    this.latitude = p.latitude ? p.latitude : 0;
    this.longitude = p.longitude ? p.longitude : 0;
    this.street_view_latitude = p.street_view_latitude
      ? p.street_view_latitude
      : 0;
    this.street_view_longitude = p.street_view_longitude
      ? p.street_view_longitude
      : 0;
    this.acquisition_source = p.acquisition_source
      ? p.acquisition_source
      : null;
    this.available_from_date = p.available_from_date
      ? p.available_from_date
      : new Date();
    this.place_names = p.place_names ? p.place_names : [];
    this.place_ids = p.place_ids ? p.place_ids : [];
    this.outdoor = p.outdoor ? p.outdoor : false;
    this.parking = p.parking ? p.parking : false;
    this.pets = p.pets ? p.pets : false;
    this.pool = p.pool ? p.pool : false;
    this.postal_code = p.postal_code ? p.postal_code : '';
    this.rent_amount = p.rent_amount ? p.rent_amount : 0;
    this.state = p.state ? p.state : '';
    this.type = p.type ? p.type : 'apartment';
    this.unit_number = p.unit_number ? p.unit_number : 0;
    this.updated = p.updated ? p.updated : new Date();
    this.user_id = p.user_id ? p.user_id : '';
    this.user_phone = p.user_phone ? p.user_phone : '';
    this.user_email = p.user_email ? p.user_email : '';
    this.user_relation = p.user_relation ? p.user_relation : null;
    this.washer_dryer = p.washer_dryer ? p.washer_dryer : false;
    this.wood_floors = p.wood_floors ? p.wood_floors : false;
    this.require_tenant_min_income = p.require_tenant_min_income
      ? p.require_tenant_min_income
      : null;
    this.require_tenant_max_income = p.require_tenant_max_income
      ? p.require_tenant_max_income
      : null;
    this.require_tenant_min_credit_score = p.require_tenant_min_credit_score
      ? p.require_tenant_min_credit_score
      : null;
    this.require_tenants_combined_min_income_per_person =
      p.require_tenants_combined_min_income_per_person
        ? p.require_tenants_combined_min_income_per_person
        : null;
    this.require_tenants_combined_max_income_per_person =
      p.require_tenants_combined_max_income_per_person
        ? p.require_tenants_combined_max_income_per_person
        : null;
    this.require_guarantor_count = p.require_guarantor_count
      ? p.require_guarantor_count
      : null;
    this.require_guarantor_min_credit = p.require_guarantor_min_credit
      ? p.require_guarantor_min_credit
      : null;
    this.require_guarantor_min_income = p.require_guarantor_min_income
      ? p.require_guarantor_min_income
      : null;
    this.require_other = p.require_other ? p.require_other : [];
  }
}
