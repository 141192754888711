<button
  padspin-fab-button
  class="close-button"
  [iconWidth]="22"
  (click)="closeModal()"
>
  <img icon src="/assets/web/icons/close.svg" alt="Close" />
</button>
<form
  [formGroup]="form"
  [ngClass]="{
    landlord: form.get('accountType')!.value === 'landlord',
    tenant: form.get('accountType')!.value === 'tenant'
  }"
>
  <nav>
    <label class="landlord">
      <span>I'm a Landlord</span>
      <input
        type="radio"
        formControlName="accountType"
        name="accountType"
        value="landlord"
        required
      />
    </label>

    <label class="tenant">
      <span>I'm a Tenant</span>
      <input
        type="radio"
        formControlName="accountType"
        name="accountType"
        value="tenant"
        required
      />
    </label>
  </nav>

  <section class="questions">
    <padspin-register
      mat-dialog-content
      *ngIf="formType === 'register'"
      [type]="form.get('accountType')!.value"
      (registrationComplete)="onRegisterSuccess()"
    ></padspin-register>
    <padspin-login
      mat-dialog-content
      *ngIf="formType === 'login'"
      [type]="form.get('accountType')!.value"
      (loginComplete)="onLoginSuccess()"
    ></padspin-login>

    <p *ngIf="formType === 'register'" mat-dialog-actions>
      Already have an account?
      <button
        class="redirect"
        [color]="form.get('accountType')!.value"
        padspin-tertiary-button
        (click)="onClickLogin()"
      >
        Log in
      </button>
    </p>
    <p *ngIf="formType === 'login'">
      Don't have an account?
      <button
        class="redirect"
        [color]="form.get('accountType')!.value"
        padspin-tertiary-button
        (click)="onClickRegister()"
      >
        Sign up
      </button>
    </p>
  </section>
</form>
