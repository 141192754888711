import { ListingsAdminModel, isListingContact } from '@padspin/models';
import type {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from '@angular/fire/firestore';
import { Timestamp } from '@angular/fire/firestore';

export const angularListingsAdminConverter: FirestoreDataConverter<ListingsAdminModel> =
  {
    toFirestore: function (
      modelObject: WithFieldValue<ListingsAdminModel>
    ): DocumentData {
      const lam: {
        [K in keyof ListingsAdminModel]: unknown;
      } = {
        slug: typeof modelObject.slug === 'string' ? modelObject.slug : null,
        access:
          typeof modelObject.access === 'string' ? modelObject.access : null,
        lockbox:
          typeof modelObject.lockbox === 'string' ? modelObject.lockbox : null,
        account_manager_email:
          typeof modelObject.account_manager_email === 'string'
            ? modelObject.account_manager_email
            : null,
        owner: isListingContact(modelObject.owner) ? modelObject.owner : null,
        property_manager: isListingContact(modelObject.property_manager)
          ? modelObject.property_manager
          : null,
        lease_owner: isListingContact(modelObject.lease_owner)
          ? modelObject.lease_owner
          : null,
        supervisor: isListingContact(modelObject.supervisor)
          ? modelObject.supervisor
          : null,
        notes: modelObject.notes,
        lease_start_date:
          modelObject.lease_start_date instanceof Date
            ? Timestamp.fromDate(modelObject.lease_start_date)
            : null,
        default_contact: isListingContact(modelObject.default_contact)
          ? modelObject.default_contact
          : null,
      };
      return lam;
    },
    fromFirestore: function (
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options?: SnapshotOptions | undefined
    ): ListingsAdminModel {
      const data = snapshot.data(options);
      const listingsAdmin: ListingsAdminModel = {
        slug: data['slug'] || null,
        notes: data['notes'],
        owner: data['owner'] || null,
        property_manager: data['property_manager'] || null,
        lease_owner: data['lease_owner'] || null,
        supervisor: data['supervisor'] || null,
        access: data['access'],
        lockbox: data['lockbox'] || null,
        lease_start_date:
          data['lease_start_date'] instanceof Timestamp
            ? data['lease_start_date'].toDate()
            : null,
        account_manager_email: data['account_manager_email'],
        default_contact: isListingContact(data['default_contact'])
          ? data['default_contact']
          : null,
      };
      return listingsAdmin;
    },
  };
