import { Pipe, PipeTransform } from '@angular/core';
import { TimestampService } from './timestamp.service';

@Pipe({
  name: 'timestampToDate',
})
export class TimestampToDatePipe implements PipeTransform {
  private static readonly default: Date = new Date(2000, 1, 1);

  constructor(private readonly timestampService: TimestampService) {}

  transform(value: unknown): Date | null {
    if (!value) {
      return TimestampToDatePipe.default;
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'number') {
      return new Date(value);
    }
    if (!!value && this.timestampService.isTimestampLiteral(value)) {
      return this.timestampService.toTimestamp(value).toDate();
    }
    if (
      !!value &&
      typeof value === 'object' &&
      this.timestampService.isTimestampImpl(value)
    ) {
      return value.toDate();
    }
    if (typeof value === 'string') {
      try {
        return new Date(value);
      } catch {
        // Invalid Date: It can't be converted to a valid Date
      }
    }
    return TimestampToDatePipe.default;
  }
}
