import {
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ChangeDetectorRef,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FileUpload } from 'primeng/fileupload';
import {
  uploadString,
  getStorage,
  ref,
  uploadBytes,
} from '@angular/fire/storage';
import { customRandom, random, urlAlphabet } from 'nanoid';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'padspin-upload-to-storage-button',
  templateUrl: './upload-to-storage-button.component.html',
  styleUrls: ['./upload-to-storage-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadToStorageButtonComponent),
      multi: true,
    },
  ],
})
export class UploadToStorageButtonComponent
  implements ControlValueAccessor, OnDestroy
{
  #destroy$ = new Subject<void>();

  /** Firebase Storage path (excluding file name), ending with a trailing slash */
  @Input() upload_path = '';
  /** Text label for the button */
  @Input() label = '';
  /** Can multiple files be uploaded from this button? */
  @Input() multiple = false;

  /** The storage path of the uploaded file */
  @Output() uploaded = new EventEmitter<string[]>();

  @ViewChild(FileUpload) fileUpload?: FileUpload;
  @ViewChild('file', { read: ElementRef })
  fileUploadRef?: ElementRef<FileUpload>;
  @ViewChild('canvas', { read: ElementRef })
  canvasRef?: ElementRef<HTMLCanvasElement>;
  @ViewChild('img', { read: ElementRef }) imgRef?: ElementRef<HTMLImageElement>;
  slug6: () => string = customRandom(urlAlphabet, 6, random);

  isDisabled = false;
  isLoading$ = new BehaviorSubject<boolean>(false);

  val: string[] = [];
  onChange = (_val: string[] | null) => void 0;
  onTouch = (_val: string[] | null) => void 0;
  set value(val: string[] | null) {
    const isEqual =
      Array.isArray(val) &&
      this.val &&
      JSON.stringify(this.val?.sort()) === JSON.stringify(val.sort());
    if (val !== null && !isEqual) {
      this.onChange(val);
      this.onTouch(val);
      this.val = val;
      this.uploaded.emit(val);
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }

  constructor(private readonly cd: ChangeDetectorRef) {}

  writeValue(value: string[] | null): void {
    setTimeout(() => {
      this.value = value;
      this.cd.markForCheck();
    }, 0);
  }
  registerOnChange(fn: (val: string[] | null) => undefined): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: (val: string[] | null) => undefined): void {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  async upload(files: Array<File>): Promise<void> {
    if (files.length === 0) {
      return;
    }
    this.isLoading$.next(true);
    const paths: string[] = [];
    for (const file of files) {
      if (!!file && file.type !== '') {
        try {
          const maybePath: string | null = !file.type.includes('image')
            ? await this.docUpload(file)
            : await this.imageUpload(file);
          this.fileUpload?.clear();
          if (maybePath) {
            paths.push(maybePath);
          }
        } catch (error) {
          this.isLoading$.next(false);
        }
      }
    }
    this.writeValue(paths);
    this.isLoading$.next(false);
  }

  async imageUpload(originalFile: File): Promise<string | null> {
    const canvas = this.canvasRef?.nativeElement;
    if (!canvas) {
      return null;
    }
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.warn(`Unable to get rendering context`);
      return null;
    }
    const imgRef = this.imgRef?.nativeElement;
    if (!imgRef) {
      return null;
    }
    try {
      await new Promise<void>((resolve, reject) => {
        imgRef.onload = () => resolve();
        imgRef.onerror = () => reject();
        imgRef.src = URL.createObjectURL(originalFile);
      });
    } catch (error) {
      console.warn(
        `Unable to load the image. Error: ${JSON.stringify(Object(error))}`
      );
      return null;
    }
    canvas.width = imgRef.width;
    canvas.height = imgRef.height;
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(imgRef, 0, 0);
    const base64Jpeg: string = canvas.toDataURL('image/jpeg', 0.9);
    // We need to remove "data:image/jpeg;base64," from the string;
    const storageUploadString = base64Jpeg.split(',')[1];
    const storage = getStorage();
    const storageRef = ref(storage, `${this.upload_path}/${this.slug6()}.jpeg`);
    const metadata = {
      contentType: 'image/jpeg',
    };

    if (!this.isUploadPathValid(this.upload_path)) {
      console.warn('Invalid upload path');
      return null;
    }

    const fileRef = await uploadString(
      storageRef,
      storageUploadString,
      'base64',
      metadata
    );
    this.fileUpload?.clear();
    return fileRef.ref.fullPath;
  }

  isUploadPathValid = (path: string): boolean => {
    const length = path.length;
    if (length < 2) {
      return false;
    }
    const last_char = path[length - 1];
    return last_char === '/';
  };

  async docUpload(originalFile: File): Promise<string | null> {
    const storage = getStorage();
    const extension = this.getFileExtensionWithDot(originalFile);
    const storageRef = ref(
      storage,
      `${this.upload_path}/${this.slug6()}${extension}`
    );
    const metadata = {
      contentType: originalFile.type,
    };
    try {
      const fileRef = await uploadBytes(storageRef, originalFile, metadata);
      return fileRef.ref.fullPath;
    } catch (error) {
      return null;
    }
  }

  getFileExtensionWithDot(file: { name: string } | File): string {
    const parts = file.name.split('.');
    if (parts.length < 2) {
      return '';
    }
    return `.${parts[parts.length - 1]}`;
  }

  reset(): void {
    this.value = [];
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
